import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button,Stack, Grid, Paper, Typography, TextField } from "@mui/material";
import Table from '@mui/material/Table';              //テーブル用
import TableBody from '@mui/material/TableBody';      //テーブル用
import TableCell from '@mui/material/TableCell';      //テーブル用
import TableRow from '@mui/material/TableRow';        //テーブル用
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import {gridClasses ,
  DataGrid,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';

//タイマー制御関係
// import Clock from './Clock'; // Clockコンポーネントをインポート
import Clock2 from './Clock 2'; // TimerManager コンポーネントをインポート
import TimeContext from './TimeContext';  // TimeContext をインポート

//モーダルウィンドウ関係
import CustomFormTCSH0111 from './CustomFormTCSH0111';    //在庫情報修正画面
import CustomFormTCSH0120 from './CustomFormTCSH0120';    //商品情報
import CustomAlert from './CustomAlert';
// import CustomDialog from './CustomDialog';
import './common.css'
import { onMyFocus,onNextFocus } from './hooks/useOnMyFocus';     // select()処理
import { styled } from '@mui/material/styles';
import { GlobalStyles, GridListTile, ListSubheader } from '@mui/material';
// 祝日対応
import {holiday as myHoliday }from './holiday';

var dialogBtnFlg = false;
let TnkMaster = undefined;
//S SK-0048-001 2024/7/12           TCI)M.K【SKU⇒取扱無しの場合、SKUに戻らない】
// 再レンダリング問題 揮発性変数を使用
let uSKUflg = 0;
let uTRIHBN = '';
//E SK-0048-001

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応


function TCSH0101 () {

  // timerStoppedイベントをリッスンするイベントリスナーを追加
  // useEffect(() => {
  //   const handleTimerStopped = (event) => {
  //     // イベントの詳細メッセージを取得
  //     console.log('xxxx:%o', event.detail.message);
  //     onClick_FooterButton_Clear();
  //     // 他の処理をここに追加
  //   };

  //   window.addEventListener('timerStopped', handleTimerStopped);

  //   return () => {
  //     window.removeEventListener('timerStopped', handleTimerStopped);
  //   };
  // }, []);

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [httkbn, setHttkbn] = useState(state.HTTKBN);
  const [httymd, setHttymd] =  useState(state.HTTYMD.substring(0, 4) + '/' + state.HTTYMD.substring(4, 6) + '/' + state.HTTYMD.substring(6,8));
  const [alert, setAlert] = useState('');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)
  const [bin, setBin] = useState('');
  const [suu, setSuu] = useState('');
  //S SK-0060-011 2024/8/6           TCI)M.K【課題No.84：何もしないでEnterはキャンセル扱い】
  const [getsuu, setGetSuu] = useState('');
  //E SK-0060-011
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [jdhttst, setJdhttst] = useState('-');  // 自動発注状態
  const [jdhttstcd, setJdhttstcd] = useState('');  // 自動発注状態コード
  const [shnrank, setShnrank] = useState('-');  // 商品ランク
  const [kjn_zai, setKjn_zai] = useState('-');  // 基準在庫
  const [lowhttsuu, setLowhttsuu] = useState('-');  // 最低発注数
  const [cal_zai, setCal_zai] = useState('-');  // 計算在庫
  const [jdhttsuu, setJdhttsuu] = useState('-');    //自動発注数
  const [week, setWeek] = useState();         // 曜日（0:日曜始まり）
  const [yday1, setYDay1] = useState();         // 発注予約日
  const [yday2, setYDay2] = useState();         // 発注予約日
  const [yday3, setYDay3] = useState();         // 発注予約日
  const [yday4, setYDay4] = useState();         // 発注予約日
  const [yday5, setYDay5] = useState();         // 発注予約日
  const [yday6, setYDay6] = useState();         // 発注予約日
  const [yday7, setYDay7] = useState();         // 発注予約日

  const [ysHday1, setYSHDay1] = useState();     // 発注予約日 形式：4/1(第一祝日)
  const [ysHday2, setYSHDay2] = useState();     // 発注予約日 形式：4/2(第二祝日)
  const [ysHday3, setYSHDay3] = useState();     // 発注予約日 形式：4/3(第三祝日)
  const [ysHday4, setYSHDay4] = useState();     // 発注予約日 形式：4/4(第四祝日)
  const [ysHday5, setYSHDay5] = useState();     // 発注予約日 形式：4/5(第五祝日)
  const [ysHday6, setYSHDay6] = useState();     // 発注予約日 形式：4/6(第六祝日)
  const [ysHday7, setYSHDay7] = useState();     // 発注予約日 形式：4/7(第七祝日)

  const [ysday1, setYSDay1] = useState(0);      // 発注予約日 形式：4/1(第一祝日)
  const [ysday2, setYSDay2] = useState(0);      // 発注予約日 形式：4/2(第二祝日)
  const [ysday3, setYSDay3] = useState(0);      // 発注予約日 形式：4/3(第三祝日)
  const [ysday4, setYSDay4] = useState(0);      // 発注予約日 形式：4/4(第四祝日)
  const [ysday5, setYSDay5] = useState(0);      // 発注予約日 形式：4/5(第五祝日)
  const [ysday6, setYSDay6] = useState(0);      // 発注予約日 形式：4/6(第六祝日)
  const [ysday7, setYSDay7] = useState(0);      // 発注予約日 形式：4/7(第七祝日)
  
  const [ydayD, setYDayD] = useState();         // 発注予約日 形式：土曜日
  const [ydayN, setYDayN] = useState();         // 発注予約日 形式：日曜日
  const [ydayD_add, setYDayD_add] = useState();         // 発注予約日 形式：土曜日
  const [ydayN_add, setYDayN_add] = useState();         // 発注予約日 形式：日曜日
  const [jbtnname, setJBtnName] = useState('前々週');         // ボタン名（前々週→今週→前週）
  const [jbtnname2, setJBtnName2] = useState('前週');         // ボタン名（前々週→今週→前週）

  const [jday1, setJDay1] = useState();         // 発注実績 形式：4/1(月)
  const [jday2, setJDay2] = useState();         // 発注実績 形式：4/2(火)
  const [jday3, setJDay3] = useState();         // 発注実績 形式：4/3(水)
  const [jday4, setJDay4] = useState();         // 発注実績 形式：4/4(木)
  const [jday5, setJDay5] = useState();         // 発注実績 形式：4/5(金)
  const [jday6, setJDay6] = useState();         // 発注実績 形式：4/6(土)
  const [jday7, setJDay7] = useState();         // 発注実績 形式：4/7(日)
  const [jsHday1, setJSHDay1] = useState();     // 発注実績 形式：4/1(第一祝日)
  const [jsHday2, setJSHDay2] = useState();     // 発注実績 形式：4/2(第二祝日)
  const [jsHday3, setJSHDay3] = useState();     // 発注実績 形式：4/3(第三祝日)
  const [jsHday4, setJSHDay4] = useState();     // 発注実績 形式：4/4(第四祝日)
  const [jsHday5, setJSHDay5] = useState();     // 発注実績 形式：4/5(第五祝日)
  const [jsHday6, setJSHDay6] = useState();     // 発注実績 形式：4/6(第六祝日)
  const [jsHday7, setJSHDay7] = useState();     // 発注実績 形式：4/7(第七祝日)

  const [jsday1, setJSDay1] = useState(0);      // 発注実績 形式：4/1(第一祝日)
  const [jsday2, setJSDay2] = useState(0);      // 発注実績 形式：4/2(第二祝日)
  const [jsday3, setJSDay3] = useState(0);      // 発注実績 形式：4/3(第三祝日)
  const [jsday4, setJSDay4] = useState(0);      // 発注実績 形式：4/4(第四祝日)
  const [jsday5, setJSDay5] = useState(0);      // 発注実績 形式：4/5(第五祝日)
  const [jsday6, setJSDay6] = useState(0);      // 発注実績 形式：4/6(第六祝日)
  const [jsday7, setJSDay7] = useState(0);      // 発注実績 形式：4/7(第七祝日)
  const [jdayD, setJDayD] = useState();         // 発注実績 形式：土曜日
  const [jdayN, setJDayN] = useState();         // 発注実績 形式：日曜日
  const [jdayD_add, setJDayD_add] = useState(); // 発注実績 形式：土曜日
  const [jdayN_add, setJDayN_add] = useState(); // 発注実績 形式：日曜日
  
  const [jyyyymmdd1, setJyyyymmdd1] = useState();   // 発注実績 形式：20240401
  const [jyyyymmdd2, setJyyyymmdd2] = useState();   // 発注実績 形式：20240401
  const [jyyyymmdd3, setJyyyymmdd3] = useState();   // 発注実績 形式：20240401
  const [jyyyymmdd4, setJyyyymmdd4] = useState();   // 発注実績 形式：20240401
  const [jyyyymmdd5, setJyyyymmdd5] = useState();   // 発注実績 形式：20240401
  const [jyyyymmdd6, setJyyyymmdd6] = useState();   // 発注実績 形式：20240401
  const [jyyyymmdd7, setJyyyymmdd7] = useState();   // 発注実績 形式：20240401
  const [neiri, setNeiri] = useState('-');         //商品情報表示で使用
  const [trihbn, setTrihbn] = useState('-');       //商品情報表示で使用
  const [colmei, setColmei] = useState('-');       //商品情報表示で使用
  const [sizmei, setSizmei] = useState('-');       //商品情報表示で使用
  const [sznmn, setSznmn] = useState('-');         //商品情報表示で使用
  const [sjhjancde, setSjhjancde] = useState('-'); //商品情報表示で使用
  const [symnsu, setSymnsu] = useState('-');       //商品情報表示で使用
  const [sclock, setClock] = useState([]);         // 時計用
  const [sShnmei, setsShnmei] = useState('-');         //シノプスで使用

  const inHTTYMD = useRef(null);
  const inBIN = useRef(null);
  const inJAN1 = useRef(null);
  const inJAN2 = useRef(null);
  const inSUU = useRef(null);
  const inSHNMEI = useRef(null);

  //disabled制御用
  const [disJan1, setDisJan1] = useState(false);    // JAN1
  const [disJan2, setDisJan2] = useState(true);     // JAN2(初期OFF)
  const [disBin, setDisBin] = useState(false);      // 便
  const [disSUU, setDisSUU] = useState(false);      // 数
  const [disBtnBack, setBtnBack] = useState(false); // 戻るボタン
  const [disBtnSend, setBtnSend] = useState(true);  // 送信ボタン
  const [disBtnSKU, setBtnSKU] = useState(true);    // SKUボタン(初期OFF)
  const [SKUflg, setSKUFlg] = useState(0);          // SKU処理フラグ

  //項目のバックカラー制御用
  const [inJAN1clr,setJAN1clr] = useState('#FFFFFF');
  const [inJAN2clr,setJAN2clr] = useState('#CCCCCC');
  const [inBINclr,setBINclr] = useState('#CCCCCC');
  const [inSUUclr,setSUUclr] = useState('#ffff00');

  // ロストフォーカス用
  const [chkJAN1,setChkJAN1] = useState('0');
  const [chkJAN2,setChkJAN2] = useState('0');
  const [chkBIN,setChkBIN] = useState('0');
  
  const clock2Ref = useRef();
  // const timex = useContext(TimeContext);  // Context から time を取得
  const {timeRef,time} = useContext(TimeContext);  // Context から time を取得

  // エラーチェック用 0:エラーなし, 1:未チェック, 2:エラー, 9:確認中
  const [chkflg,setChkflg] = useState(0);               // チェックフラグ

  //項目のタイトル変更用
  const [titleHTTKBN,settitleHTTKBN] = useState('');
  
  // 商品情報表示↓↓↓
  const [isShnInfoVisible, setShnInfoVisible] = useState(false);
  // 商品情報表示を表示
  const showShnInfo = () => {
    window.glAlertDialogFlg = true;
    setShnInfoVisible(true);
  };
  // 商品情報表示を閉じる
  const [text, setText] = useState('');
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [text4, setText4] = useState('');
  const [textEos, setTextEos] = useState('');
  const closeShnInfo = () => {
    window.glAlertDialogFlg = false;
    setShnInfoVisible(false);
  };
  const regSinopsInfo = async() => {
    // チェック
    // let W_cal_zai = ''
    // if (Number(text4) >= 0){
    //   W_cal_zai = '+' + ('0000' + text4).slice(-5);
    // }else{
    //   W_cal_zai = '-' + ('0000' + (Number(text4)*-1)).slice(-5);
    // }
    let W_jdhttst = ''
    let W_kjn_zai = ''
    let W_lowhttsuu = ''
    let W_cal_zai = ''
    console.log('1:',text,'2:',text2,'3:',text3,'4:',text4)
    if (text === ''){   // 変更無しの場合
      W_jdhttst = jdhttstcd;
    }else{
      W_jdhttst = text;
    }

    if (text2 === ''){   // 変更無しの場合
      W_kjn_zai = kjn_zai;
    }else{
      W_kjn_zai = text2;
    }

    if (text3 === ''){   // 変更無しの場合
      W_lowhttsuu = lowhttsuu;
    }else{
      W_lowhttsuu = text3;
    }

    if (text4 === ''){   // 変更無しの場合
      W_cal_zai = cal_zai;
    }else{
        W_cal_zai = text4;
    }

    // if (text2 === ''){   // 変更無しの場合
    //   W_kjn_zai = ('0000' + kjn_zai).slice(-5);
    // }else{
    //   W_kjn_zai = ('0000' + text2).slice(-5);
    // }

    // if (text3 === ''){   // 変更無しの場合
    //   W_lowhttsuu = ('0000' + lowhttsuu).slice(-5);
    // }else{
    //   W_lowhttsuu = ('0000' + text3).slice(-5);
    // }

    // if (text4 === ''){   // 変更無しの場合
    //   if (Number(cal_zai) >= 0){
    //     W_cal_zai = '+' + ('0000' + cal_zai).slice(-5);
    //   }else{
    //     W_cal_zai = '-' + ('0000' + (Number(cal_zai)*-1)).slice(-5);
    //   }
    // }else{
    //   if (Number(text4) >= 0){
    //     W_cal_zai = '+' + ('0000' + text4).slice(-5);
    //   }else{
    //     W_cal_zai = '-' + ('0000' + (Number(text4)*-1)).slice(-5);
    //   }
    // }
    // データの更新
    // const response = await fetch('/TCSH0100/UpdJDHDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
    //   body: JSON.stringify({
    //     header:rowItem,
    //     jdhttst:text,
    //     kjn_zai:('0000' + text2).slice(-5),
    //     lowhttsuu:('0000' + text3).slice(-5),
    //     cal_zai:W_cal_zai,
    //     Jancde:text1,
    // })});

    // ２段バーコード対応
    console.log('２段バーコード対応:',text1,'/',textEos);
    let SearchKey = '';
    if (textEos === ''){
      SearchKey = text1;
    }else{
      SearchKey = textEos;
    }

    const response = await fetch('/TCSH0101/UpdJDHDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        // header:rowItem,  // 2024/06/05 間違いの為修正
        header:httItem,
        jdhttst:W_jdhttst,
        kjn_zai:W_kjn_zai,
        lowhttsuu:W_lowhttsuu,
        cal_zai:W_cal_zai,
        Jancde:SearchKey,
        add_prg_id:'TCSH0101',
        JGNCDE:state.JGNCDE,
        DELFLG:'0',
      })});
    if(response.res === '-1') {
      console.log('送信失敗');
    }else{
      switch(text)
      {
        case '01':
          setJdhttst('対象');
          break;
        case '02':
          setJdhttst('対象外');
          break;
        case '03':
          setJdhttst('店舗停止');
          break;
        case '04':
          setJdhttst('本部停止');
          break;
        case '10':
          setJdhttst('POS');
          break;
        case '11':
          setJdhttst('EOS');
          break;
        default:
          break;
      };
      //S SK-0051-011 2024/7/26           TCI)M.K【課題No.77：バグFIX】
      // setKjn_zai(text2);
      // setLowhttsuu(text3);
      // setCal_zai(text4);
      setKjn_zai(Number(W_kjn_zai));
      setLowhttsuu(Number(W_lowhttsuu));
      setCal_zai(Number(W_cal_zai));
      //E SK-0051-011
    }

    // 閉じる
    window.glAlertDialogFlg = false;
    setSinopsInfoVisible(false);
  };

  
  // 商品情報表示ここまで↑↑↑


  // 在庫情報修正情報表示↓↓↓
  const [isSinopsInfoVisible, setSinopsInfoVisible] = useState(false);
  const closeSinopsInfo = () => {
    window.glAlertDialogFlg = false;
    setSinopsInfoVisible(false);
  };
  // シノプス入力画面を表示
  const showSinopsInfo = () => {
    window.glAlertDialogFlg = true;
    setSinopsInfoVisible(true);
  };
  const handleValueChange01 = async(newValue) => {
    console.log(' 親コンポーネントで受け取った値：',newValue)
    setText1(newValue);
  };
  const handleValueChange02 = async(newValue) => {
    // 何もしない
  };
  const handleValueChangeEos = async(newValue) => {
    console.log(' EOS 親コンポーネントで受け取った値：',newValue)
    setTextEos(newValue);
  };
  const handleValueChange04 = async(newValue) => {
    setText(newValue);
  };
  const handleValueChange05 = (newValue) => {
    setText2(newValue);
  };
  const handleValueChange06 = (newValue) => {
    setText3(newValue);
  };
  const handleValueChange07 = (newValue) => {
    setText4(newValue);
  };
  // 在庫情報修正情報表示↑↑↑
  
  // シノプス情報表示↓↓↓
  // const [text, setText] = useState('');
  // const [text2, setText2] = useState('');
  // const [text3, setText3] = useState('');
  // const [text4, setText4] = useState('');
  // // ↑親コンポーネントで使う：textの初期値とtextを更新する関数を宣言
  // // ↓子コンポーネントから受け取った値で親コンポーネントのtextを更新する関数A
  // const handleValueChange04 = async(newValue) => {
  //   console.log(' 親コンポーネントで受け取った値：',newValue)
  //   setText(newValue);
  // };
  // const handleValueChange05 = (newValue) => {
  //   setText2(newValue);
  // };
  // const handleValueChange06 = (newValue) => {
  //   setText3(newValue);
  // };
  // const handleValueChange07 = (newValue) => {
  //   setText4(newValue);
  // };
  // const [isSinopsInfoVisible, setSinopsInfoVisible] = useState(false);
  // // シノプス入力画面を表示
  // const showSinopsInfo = () => {
  //   window.glAlertDialogFlg = true;
  //   setSinopsInfoVisible(true);
  // };
  // // シノプス入力画面を閉じる
  // const closeSinopsInfo = () => {
  //   window.glAlertDialogFlg = false;
  //   setSinopsInfoVisible(false);
  // };
  // // シノプス情報を登録する
  // const regSinopsInfo = async() => {
  //   // チェック
  //   // let W_cal_zai = ''
  //   // if (Number(text4) >= 0){
  //   //   W_cal_zai = '+' + ('0000' + text4).slice(-5);
  //   // }else{
  //   //   W_cal_zai = '-' + ('0000' + (Number(text4)*-1)).slice(-5);
  //   // }
  //   let W_jdhttst = ''
  //   let W_kjn_zai = ''
  //   let W_lowhttsuu = ''
  //   let W_cal_zai = ''

  //   if (text === ''){   // 変更無しの場合
  //     W_jdhttst = jdhttstcd;
  //   }else{
  //     W_jdhttst = text;
  //   }

  //   if (text2 === ''){   // 変更無しの場合
  //     W_kjn_zai = ('0000' + kjn_zai).slice(-5);
  //   }else{
  //     W_kjn_zai = ('0000' + text2).slice(-5);
  //   }

  //   if (text3 === ''){   // 変更無しの場合
  //     W_lowhttsuu = ('0000' + lowhttsuu).slice(-5);
  //   }else{
  //     W_lowhttsuu = ('0000' + text3).slice(-5);
  //   }

  //   if (text4 === ''){   // 変更無しの場合
  //     if (Number(cal_zai) >= 0){
  //       W_cal_zai = '+' + ('0000' + cal_zai).slice(-5);
  //     }else{
  //       W_cal_zai = '-' + ('0000' + (Number(cal_zai)*-1)).slice(-5);
  //     }
  //   }else{
  //     if (Number(text4) >= 0){
  //       W_cal_zai = '+' + ('0000' + text4).slice(-5);
  //     }else{
  //       W_cal_zai = '-' + ('0000' + (Number(text4)*-1)).slice(-5);
  //     }
  //   }
  //   // データの更新
  //   // const response = await fetch('/TCSH0100/UpdJDHDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
  //   //   body: JSON.stringify({
  //   //   header:httItem,
  //   //   jdhttst:text,
  //   //   kjn_zai:('0000' + text2).slice(-5),
  //   //   lowhttsuu:('0000' + text3).slice(-5),
  //   //   cal_zai:W_cal_zai,
  //   // })});
  //   const response = await fetch('/TCSH0101/UpdJDHDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
  //     body: JSON.stringify({
  //     header:httItem,
  //     jdhttst:W_jdhttst,
  //     kjn_zai:W_kjn_zai,
  //     lowhttsuu:W_lowhttsuu,
  //     cal_zai:W_cal_zai,
  //   })});
    
  //   if(response.res === '-1') {
  //     console.log('送信失敗');
  //   }else{
  //     switch(text)
  //     {
  //       case '01':
  //         setJdhttst('対象');
  //         break;
  //       case '02':
  //         setJdhttst('対象外');
  //         break;
  //       case '03':
  //         setJdhttst('店舗停止');
  //         break;
  //       case '04':
  //         setJdhttst('本部停止');
  //         break;
  //       default:
  //         break;
  //     };
  //     // setKjn_zai(text2);
  //     // setLowhttsuu(text3);
  //     // setCal_zai(text4);
  //     setKjn_zai(Number(W_kjn_zai));
  //     setLowhttsuu(Number(W_lowhttsuu));
  //     setCal_zai(Number(W_cal_zai));
  //   }

  //   // 閉じる
  //   window.glAlertDialogFlg = false;
  //   setSinopsInfoVisible(false);
  // };
  // シノプス情報表示ここまで↑↑↑

  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // アラートを表示する関数
  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };
  // アラートを閉じる関数
  const closeAlert = () => {
    setAlertMessage('');
    setAlertVisible(false);
  };

  // // ダイアログ表示↓↓↓
  // const [isDialogOpen, setDialogOpen] = useState(false);
  // const [dialogMessage, setDialogMessage] = useState('');

  // // ダイアログを表示
  // const showDialog = (message) => {
  //   window.glAlertDialogFlg = true;
  //   setDialogMessage(message);   // メッセージ
  //   setDialogOpen(true);
  // };
  
  // // OKボタンがクリックされたときの処理
  // const handleOk = () => {
  //   window.glAlertDialogFlg = true;
  //   setDialogOpen(false);
  // };

  // // キャンセルボタンがクリックされたときの処理
  // const handleCancel = () => {
  //   window.glAlertDialogFlg = false;
  //   setDialogOpen(false);
  // };
  // // ダイアログ表示ここまで↑↑↑

  // 発注データ（DB保存用）
  const httData = {
    KSACDE : state.KSACDES,         // 会社コード
    MISE : state.MISES,             // 店コード
    SHNID : '',                     // 商品ID
    SHNMEIKN : '',                  // 商品名漢字
    JANCDE : '',                    // ＪＡＮコード
    NHNYMD : '',                    // 納品日
    BIN : '',                       // 便
    HTTYMD : state.HTTYMD,          // 発注日
    SIMTIM : '',                    // 締時間
    BMNCDE : '',                    // 部門コード
    ASTCDE : '000',                 // ＡＵ
    LINCDE : '',                    // ライン
    CLSCDE : '',                    // クラス
    HTTTNI : '',                    // 発注単位(C4)
    HTTTNIKNJ : '',                 // 発注単位名
    IRISUU : '',                    // 発注入数
    GNT : '',                       // 原価
    BTN : '',                       // 売価
    HTTSUU : '',                    // 発注数量
    SIRCDE : '',                    // 仕入先コード
    SIRMEIK : '',                   // 仕入先名称（漢字）
    DAT_MKBN : '',                  // 入力済区分
    SND_KNR_KBN : '',               // 送信済区分
    EOSCDE : '',                    // ＥＯＳコード
    HTT_CDE : '',                   // 発注コード
    HTT_KBN : '',                   // 発注区分
    ENT_KBN : '',                   // 入力元区分
    ENT_DAY : '',                   // 入力日
    ENT_TIM : '',                   // 入力時刻
    JGNCDE : state.JGNCDE,          // ユーザーID
    WEEK : '',                      // 曜日
    SND_SUU : '',                   // 最終送信数
    SND_DATE : '',                  // 送信日時
    DATE : '',                      // 日時
    JGNMEIKNJ : state.JGNMEIKNJ,    // ◇ユーザー名
    PRG_ID : 'TCSH0101',            // プログラムID
    TRL_ID:state.MISES,             // 端末ＩＤ★一旦店コード入れる
    DELFLG : '0',                   // 削除フラグ
  }
  const [httItem,setHttItem] = useState(httData);

  // 商品データ（ディスパッチ用）（ＳＫＵ・商品情報表示にて使用）
  // ここにはマスタから取得した情報を格納すること
  // ここでの加工禁止
  const rowData ={
    id : 0,                        // 行№
    KSACDE : state.KSACDES,        // 会社コード
    MISE : state.MISES,            // 店コード
    //S SK-0072-002 2024/9/6           TCI)M.K【内部検証：衣料　ログイン部門対応】
    // BMNCDE : state.BMNCDE,         // 部門コード
    BMNCDE : '',                   // 部門コード
    //E SK-0072-002
    ASTCDE : '000',                // ＡＵ
    JANCDE : '',                   // ＪＡＮコード(スキャンコード)
    JANCDE2 : '',                  // ＪＡＮコード2(スキャンコード)
    SHNMEIKN : '',                 // 商品名
    KIKAKUKN : '',                 // 規格
    SUU : 0,                       // 数量(数字)
    SUU2 : '',                     // ◇数量2(文字列)
    GNT : 0,                       // 原単価(数字)
    GNT2 : '',                     // ◇原単価2(文字列)
    GNKKIN : 0,                    // 原価金額
    BTN : 0,                       // 売単価(数字)
    BTN2 : '',                     // ◇売単価2(文字列)
    BAKKIN : 0,                    // 売価金額
    SIRCDE : '',                   // 仕入先(取引先)コード
    SIRMEIK : '',                  // 仕入先(取引先)名
    EOSCDE : '',                   // ＥＯＳコード
    EOSCDE1 : '',                  // ＥＯＳコード１便
    EOSCDE2 : '',                  // ＥＯＳコード２便
    EOSCDE3 : '',                  // ＥＯＳコード３便
    EOSCDE4 : '',                  // ＥＯＳコード４便
    TAGASTCDE : '',                // タグＡＵ
    TAGLINCDE : '',                // タグライン
    TAGCLSCDE : '',                // タグクラス
    TAGSZN : '',                   // タグシーズン
    TAGSZNNM : '',                 // タグシーズン名カナ
    TAGITM : '',                   // タグアイテム
    TRIHBN : '',                   // 取引先品番
    TAGSIZ : '',                   // サイズコード
    TAGSIZMEI : '',                // サイズ名カナ
    TAGCOLCDE : '',                // カラーコード
    TAGCOLMEI : '',                // カラー名カナ
    IRYO_FLG : '0',                // 衣料フラグ
    IN_CD_KBN : '',                // 入力コード区分
    HTTSTD : '',                   // 発注開始日
    HTTEND : '',                   // 発注終了日
    HTTTNI : '',                   // 発注単位CD 
    HTTTNIKNJ : '',                // 発注単位
    IRISUU : '',                   // 入数
    SYMNSU : '',                   // 賞味日数
    TOK_GNT : '',                  // 特売原価
    TOK_BTN : '',                  // 特売売価
    NKIKBN : '',                   // 納期区分
    TEINOK1 : '',                  // 定番納期１
    TEINOK2 : '',                  // 定番納期２
    TEINOK3 : '',                  // 定番納期３
    TEINOK4 : '',                  // 定番納期４
    TEINOK5 : '',                  // 定番納期５
    TEINOK6 : '',                  // 定番納期６
    TEINOK7 : '',                  // 定番納期７
    TOKNOK1 : '',                  // 特売納期１
    TOKNOK2 : '',                  // 特売納期２
    TOKNOK3 : '',                  // 特売納期３
    TOKNOK4 : '',                  // 特売納期４
    TOKNOK5 : '',                  // 特売納期５
    TOKNOK6 : '',                  // 特売納期６
    TOKNOK7 : '',                  // 特売納期７
    NEIRI : '',                    // 値入率＝（売価-原価）÷売価×100
    NHNPTN: '',                    // 納品パターン 2024/06/03追加
  }
  const [rowItem,setRowItem] = useState(rowData);

  // 発注予約データ格納用（画面チェック・表示などで使用）
  const htjData = {
    NHNYMD : '',	                // 納品日
    JANCDE : '',                  // ＪＡＮコード
    EOSCDE : '',                  // ＥＯＳコード
    BIN : '',                     // 便
    HTTYMD : '',                  // 発注日
    HTTKBN : '',                  // 発注区分
    HTTSUU : '',                  // 発注数量
    SSIKBN : '',                  // 修正区分
    SECYMD : '',                  // 処理日
    SECTIM : '',                  // 処理時刻
  }
  const [htjItem,setHtjItem] = useState(htjData);

  const [selectionModel, setSelectionModel] = useState([]);
  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 10});

  // 表示日付フォーマット
  const formatDate = (dateString) => {
    if(dateString === null){ return dateString; }

    const fmtYear = dateString.substr(0, 4);
    const fmtMonth = dateString.substr(4, 2);
    const fmtDay = dateString.substr(6, 2);

    return `${fmtYear}/${fmtMonth}/${fmtDay}`;
  }

  // 金額表示フォーマット
  const formatCurrency = (amount) => {
    if(amount === null){ return amount; }

    return amount.toLocaleString('ja-JP', {
      style: 'currency',
      currency: 'JPY',
      minimumFractionDigits: 0,
    });
  }

  //S SK-0075-010 2024/9/20           TCI)M.K【課題No.98：URL直接入力は初画面に戻す】
  const handlebeforeunload = (event) => {
    // デフォルトの 'beforeunload' イベントをキャンセル
    event.preventDefault();  // 正しい記述
    event.returnValue = '';  // 必須: これでブラウザに確認ダイアログが表示されます
    console.log('beforeunload イベントが発火しました');
  };
  
  useEffect(() => {
    // 'beforeunload' のイベントリスナーを登録
    window.addEventListener('beforeunload', handlebeforeunload);
  
    // クリーンアップ関数（イベントリスナーを削除）
    return () => {
      window.removeEventListener('beforeunload', handlebeforeunload);
    };
  }, []);  // handlebeforeunload が依存していないため、依存配列は空にして問題ありません 
  //E SK-0075-010

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    // 多重防止
    // if (!keyDisabled) {
    //   setKeyDisabled(true);
    // } else {
    //   return;
    // }

    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      // if (state.MVEMGAMEN==='')
      // {
      //   navigate('/');
      // }else{
      //   navigate('/TCSA0003');
      // }      
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const handleChange = (event, SelectChangeEvent) => {
    setHttkbn(event.target.value);
    console.log('選択発注区分:%o',event.target.value)
    switch(event.target.value)
    {
      case '1':
        break;
      case '2':
        break;
      default:
        break;
    };
    
    // inMISE.current.focus();
    // inMISE.current.select();
  };
  
  // ★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆　タイマー処理　★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆
//   const [servertime, setServertime] = useState('');   // サーバ時間
//   const [timer, setTimer] = useState(false);          // タイマー実行有無
//   const caveatTime = [300,60,0];                      // 警告表示時間（秒　5分=300秒、1分=60秒）
//   const [simkrTime,setSimkrtime] = useState('');      // サーバ時間
        
//   // タイマーID保持
//   var timerId = []; //new Array();

//   // 締切時間チェック実施確認
//   const timerCheck = (simeTime) => {
//     let ndate = new Date();
//     let today = ndate.getFullYear() + ('0'+(ndate.getMonth()+1)).slice(-2) + ('0'+ndate.getDate()).slice(-2)
//     //simkrTime = simeTime
//     setSimkrtime(simeTime);

//     console.log('タイマーID:',timerId.length)
//     // タイマーが起動している場合、停止させる。
//     if (timerId.length >= 1){
//       console.log('タイマー停止:',timerId.length)
//       // setTimer(false);
//       // timerId = [];
//       stopTimer();        // タイマー停止
//     }

//     console.log('タイマー開始:')
//     console.log('today:',today)
//     console.log('state.HTTYMD:',state.HTTYMD)
//     //console.log('締切時間:',httItem.SIMTIM)
//     console.log('締切時刻:',simeTime)
//     // 締切時間が取得できた場合
//     if (simeTime){
//       // 発注日が当日の場合、タイマー起動
//       if (today == state.HTTYMD){
//         console.log('タイマー開始:',timerId.length)
//         setTimer(true);
//       }  
//     }

//   };

//   // 締切時間タイマー停止時
//   const stopTimer = () => {
//     console.log('タイマー停止')
//     setTimer(false);    // タイマー停止
//     timerId = [];       // タイマーID初期化
//   }

//   // ②締切時間　５分前・１分前の注意喚起画面表示
//   const timeUp = () => {
    
//     let timemessage = '';                           // 出力メッセージ
//     let today = new Date(sdate);                    // 年月日取得用
//     let yyyy = today.getFullYear();                 // 年(2024)
//     let mm = ('0'+(today.getMonth()+1)).slice(-2);  // 月(04)
//     let dd = ('0'+today.getDate()).slice(-2);       // 日(05)
//     // let hh = httItem.SIMTIM.slice(0,2);             // 時(15)
//     // let nn = httItem.SIMTIM.slice(-2);              // 分(30)
//     let hh = simkrTime.slice(0,2);                  // 時(15)
//     let nn = simkrTime.slice(-2);                   // 分(30)
//     let ss = '00';                                  // 秒(00)

//     // サーバ時間（残り時間計算用）
//     let date1 = new Date(yyyy + '-' + mm + '-' + dd + ' ' + servertime);
//     // 締時間（残り時間計算用）
//     let date2 = new Date(yyyy + '-' + mm + '-'+ dd + ' ' + hh + ':' + nn + ':' + ss);
//     //テスト用設定時間
//     //let date2 = new Date(yyyy + '-' + mm + '-'+ dd + ' ' + '10:30:00');
//     let diff = date2.getTime() - date1.getTime();   // 残り時間（ミリ秒）
//     let checkTime = diff /1000                      // 残り時間（秒）
//     console.log('締切時刻:',hh + ':' + nn + ':' + ss)
//     console.log('サーバ時間:',servertime)
//     console.log('残り時間:',checkTime)
    
//     // ５分前、１分前、締切り
//     //if(checkTime == 300){     //残り時間300秒の場合
//     if(checkTime == caveatTime[0]){     //残り時間300秒の場合
//       timemessage = '締め切り時間５分前です'
//       console.log(timemessage);
//       showDialog(timemessage);
//       console.log(caveatTime[0]);
//     }
//     //else if(checkTime == 60){ //残り時間300秒の場合
//     else if(checkTime == caveatTime[1]){ //残り時間300秒の場合
//       timemessage = '締め切り時間１分前です'
//       console.log(timemessage);
//       handleOk();
//       showDialog(timemessage);
//     }
//     //else if(checkTime <= 0){  //残り時間0秒以下の場合
//     else if(checkTime <= caveatTime[2]){  //残り時間0秒以下の場合
//       timemessage = '締め切り時間を過ぎました'
//       console.log(timemessage);
//       handleOk();
//       showDialog(timemessage);
//       // setTimer(false);
//       // timerId = [];
//       stopTimer();        // タイマー停止
//     }
//   };

//   const time = useContext(TimeContext);

//   // 締め時間チェックタイマー起動
//   useEffect(() => {
//     console.log('時間チェック:'+time.toLocaleTimeString());
//     if (timer) {
//       timerId.push(setInterval(timeUp(), 1000));
//     }else{
//       clearInterval(timerId.shift());
//     }
//   }, [timer,time,simkrTime]);
// // }, [timer,servertime,simkrTime]);

//   // サーバ時間変数格納用
//   const ClockTime = (childservertime) => {
//     setServertime(childservertime);
//   }

  // ★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆★☆

  // サーバ時刻と締切時刻（strHHMMSS）を比較する
  const CloseTimeCheck = (strHHMMSS) =>{  // 2024/03/22 16:45:00 等
    console.log('締切時刻取得:',strHHMMSS);
    var logdate = new Date();                // 現在日付
    let yyyy = logdate.getFullYear();               //2022
    let mm = ('0'+(logdate.getMonth()+1)).slice(-2);              //2
    let dd = ('0'+logdate.getDate()).slice(-2);                //5    
    let hh = ('0'+logdate.getHours()).slice(-2);
    let nn = ('0'+logdate.getMinutes()).slice(-2);
    let ss = ('0'+logdate.getSeconds()).slice(-2);
    let ymdhms = yyyy+'/'+mm+'/'+dd+' '+hh+':'+nn+':'+ss;    
    var sime_date = new Date(strHHMMSS);  // 締切日付
    let sime_time = sime_date.getTime()
    let svr_time = time.getTime()

    console.log(ymdhms,'WEBサーバ時刻取得開始');
    
    if (sime_time < svr_time ){
      console.log('締め切り時間を過ぎました★後で見直す');
      setChkflg(2);
      window.alert('締時間が過ぎてるため、入力を確定できません。'); 
      onClick_FooterButton_Clear();
      timeRef.current.reTimer();
      return false;
    }
    // var request = new XMLHttpRequest();
    // request.open('HEAD', window.location.href, true);
    // request.send();
    // request.onreadystatechange = function() {
    //   if (this.readyState === 4) {
    //     var serverDate = new Date(request.getResponseHeader('Date'));
    //     console.log(ymdhms,'WEBサーバ時刻取得終了',serverDate);        // 例) => Wed Feb 16 2017 12:00:00 GMT+0900
    //     let srv_time = serverDate.getTime()
        
    //     if (sime_time < srv_time ){
    //       console.log('締め切り時間を過ぎました★後で見直す');
    //       return false;
    //     }else if (sime_time1 < srv_time ){
    //       console.log('締め切り時間１分前です★後で見直す');
    //       return false;
    //     }else if (sime_time5 < srv_time ){
    //       console.log('締め切り時間５分前です★後で見直す');
    //       return false;
    //     }
    //   }
    // }
    return true;
  }

  // 週初の日付を取得（2024,3,25形式）
  const formedDateOfThisWeek = async (minus) =>{
    let today = new Date(state.SDAY);
    let this_year = today.getFullYear();
    let this_month = today.getMonth();
    let date = today.getDate();
    let day_num = today.getDay();
    // let this_monday = date - day_num + 1 - minus;
    let this_monday = date - minus;

    //月曜日の年月日
    let start_date = new Date(this_year, this_month, this_monday);
        start_date = start_date.getFullYear() + "," + (start_date.getMonth() + 1) + "," + start_date.getDate() 
    // console.log('formedDateOfThisWeek:',start_date)
    return start_date;
  }
  const get_week = async() =>{
    // 曜日計算
    let yyyy = String(state.HTTYMD).substring(0,4);
    let mm = String(state.HTTYMD).substring(4,6)-1;
    let dd = String(state.HTTYMD).substring(6,8);
    let dH = new Date(yyyy,mm,dd);
    let ret = 0;
    console.log(dH.getDay());
    if (dH.getDay() === 0){
      ret = 7;
    }else{
      ret = dH.getDay();
    } 
    return ret;
  }

  // 納品日計算
  // 引数１：発注日（YYYYMMDD）
  // 引数２：リードタイム（DD）
  // 戻り値：納品日（YYYYMMDD）※戻り値はhttItemへも直接格納
  const calcNhnymd = async (readtime) =>{
    console.log('納品日計算開始：',readtime)
    // const htt = JSON.parse(JSON.stringify(httItem));   // ここで入らん
    if (readtime === undefined){return '';}               // マスタ無かったらundefined
    let yyyy = String(state.HTTYMD).substring(0,4);
    let mm = String(state.HTTYMD).substring(4,6)-1;
    let dd = String(state.HTTYMD).substring(6,8);
    let httd = new Date(yyyy,mm,dd);
    httd.setDate(httd.getDate() + Number(readtime));
    let this_year = httd.getFullYear();
    let this_month = ('0'+(httd.getMonth() + 1)).slice(-2);
    let this_date = ('0'+httd.getDate()).slice(-2);

    console.log('納品日計算結果：',this_year + this_month + this_date);
    // htt.NHNYMD = this_year + this_month + this_date; // ここで入らん
    // setHttItem(htt);                                 // ここで入らん

    return this_year + this_month + this_date;
  }
  
  // 未送信チェック（チェック後、ボタンの活性・非活性を切り替える）
  // 引数１：なし
  // 戻り値：なし
  const noSendCheck = async () =>{
    const gethttdat = await fetch('/TCSH0101/SH_HTT_DAT2_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE:state.KSACDES
        ,MISE:state.MISES
        ,HTTYMD:state.HTTYMD
        ,HTT_KBN:httkbn
        ,JGNCDE:state.JGNCDE
      })});
    const httdat = await gethttdat.json();
    //S SK-0055-007 2024/7/30           TCI)M.K【送信ボタン活性切替レスポンス対応】
    // if(httdat.CNT !== 0) {
    if(httdat.record_exists !== 0) {
    //E SK-0055-007
      console.log('未送信データ有り：送信ボタン⇒活性、戻るボタン⇒非活性')
      setBtnSend(false);
      setBtnBack(true);
    }else{
      console.log('未送信データ無し：送信ボタン⇒非活性、戻るボタン⇒活性')
      setBtnSend(true);
      setBtnBack(false);
    }
  }

  const jHeaderSet = async (Flagbutton) =>{
    let ThisWeek = '';
    console.log('押したボタン表示名',jbtnname,Flagbutton);

    if (Flagbutton ===0){           //初期値は前週 
      ThisWeek = await formedDateOfThisWeek(7);
    }else{
      if (jbtnname==='前々週'){       
        ThisWeek = await formedDateOfThisWeek(14);
      }else if (jbtnname==='今週'){   
        ThisWeek = await formedDateOfThisWeek(0);
      }else  if(jbtnname === '前週'){ 
        ThisWeek = await formedDateOfThisWeek(7);
      }
    }

    var weekdate = new Date(ThisWeek), // 基準となる日付は使いまわす
    addDate = 1, // 足す日数
    max = 6; // 何回繰り返すか
    var dayOfWeekStr = [ "(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)" ][weekdate.getDay()] ;	// 曜日(日本語表記)
    let wkweekdate = '';
    let yyyymmdd = '';
    
    let yyyymmdd1,yyyymmdd2,yyyymmdd3,yyyymmdd4,yyyymmdd5,yyyymmdd6,yyyymmdd7 = '';
    // wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() +'(' + dayOfWeekStr + ')';
    // setJDay1(wkweekdate);
    // yyyymmdd = weekdate.getFullYear() + ('0'+(weekdate.getMonth()+1)).slice(-2) + ('0'+weekdate.getDate()).slice(-2);
    // yyyymmdd1 =yyyymmdd;
    // console.log(yyyymmdd);

    yyyymmdd = weekdate.getFullYear() + ('0'+(weekdate.getMonth()+1)).slice(-2) + ('0'+weekdate.getDate()).slice(-2);
    yyyymmdd1 =yyyymmdd;
    console.log(yyyymmdd);
    if (myHoliday.includes(yyyymmdd)){
      wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() + '(祝)' ;
      setJSHDay1(wkweekdate);
      setJSDay1(1);
    }else{
      wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() + dayOfWeekStr ;
      setJSHDay1('X');
      setJSDay1(0);
    }
    setJDay1(wkweekdate);
    if (wkweekdate.includes('土'))
      {
        setJDayD(wkweekdate);
        setJDayD_add(1);
      }
    if (wkweekdate.includes('日'))
      {
        setJDayN(wkweekdate);
        setJDayN_add(1);
      }
    
    for (var i = 1; i <= max; i++) {
      weekdate.setDate(weekdate.getDate() + addDate);
      dayOfWeekStr = [ "(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)" ][weekdate.getDay()] ;	// 曜日(日本語表記)
      // console.log(weekdate.getMonth() + 1 + '/' + weekdate.getDate() +'(' + dayOfWeekStr + ')');
      wkweekdate=weekdate.getMonth() + 1 + '/' + weekdate.getDate() +'(' + dayOfWeekStr + ')';
      yyyymmdd = weekdate.getFullYear() + ('0'+(weekdate.getMonth()+1)).slice(-2) + ('0'+weekdate.getDate()).slice(-2);
      console.log(yyyymmdd);
      let sflg = 0;
      if (myHoliday.includes(yyyymmdd)){
        wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() + '(祝)' ;
        sflg = 1;
      }else{
        wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() + dayOfWeekStr ;
      }
      if (i===1) { setJDay2(wkweekdate);
        yyyymmdd2 =yyyymmdd;
        if (sflg === 1){
          setJSHDay2(wkweekdate);
          setJSDay2(2);
        }else{
          setJSHDay2('X');
          setJSDay2(0);
        }
      }
      else if(i===2){setJDay3(wkweekdate);
        yyyymmdd3 =yyyymmdd;
        if (sflg === 1){
          setJSHDay3(wkweekdate);
          setJSDay3(3);
        }else{
          setJSHDay3('X');
          setJSDay3(0);
        }
      }
      else if(i===3){setJDay4(wkweekdate);
        yyyymmdd4 =yyyymmdd;
        if (sflg === 1){
          setJSHDay4(wkweekdate);
          setJSDay4(4);
        }else{
          setJSHDay4('X');
          setJSDay4(0);
        }
      }
      else if(i===4){setJDay5(wkweekdate);
        yyyymmdd5 =yyyymmdd;
        if (sflg === 1){
          setJSHDay5(wkweekdate);
          setJSDay5(5);
        }else{
          setJSHDay5('X');
          setJSDay5(0);
        }
      }
      else if(i===5){setJDay6(wkweekdate);
        yyyymmdd6 =yyyymmdd;
        if (sflg === 1){
          setJSHDay6(wkweekdate);
          setJSDay6(6);
        }else{
          setJSHDay6('X');
          setJSDay6(0);
        }
      }
      else if(i===6){setJDay7(wkweekdate);
        yyyymmdd7 =yyyymmdd;
        if (sflg === 1){
          setJSHDay7(wkweekdate);
          setJSDay7(7);
        }else{
          setJSHDay7('X');
          setJSDay7(0);
        }
      }
      if (wkweekdate.includes('土'))
        {
          setJDayD(wkweekdate);
          setJDayD_add(i+1);
        }
      if (wkweekdate.includes('日'))
        {
          setJDayN(wkweekdate);
          setJDayN_add(i+1);
        }
    }

    // 天気セット
    const{str1:J_t_1,str2:J_k_1,str3:J_o_1} = await Tnk_Set(yyyymmdd1);
    const{str1:J_t_2,str2:J_k_2,str3:J_o_2} = await Tnk_Set(yyyymmdd2);
    const{str1:J_t_3,str2:J_k_3,str3:J_o_3} = await Tnk_Set(yyyymmdd3);
    const{str1:J_t_4,str2:J_k_4,str3:J_o_4} = await Tnk_Set(yyyymmdd4);
    const{str1:J_t_5,str2:J_k_5,str3:J_o_5} = await Tnk_Set(yyyymmdd5);
    const{str1:J_t_6,str2:J_k_6,str3:J_o_6} = await Tnk_Set(yyyymmdd6);
    const{str1:J_t_7,str2:J_k_7,str3:J_o_7} = await Tnk_Set(yyyymmdd7);
    let J_1_1,J_1_2,J_1_3,J_1_4,J_1_5,J_1_6,J_1_7 ='';  // 規格
    let J_2_1,J_2_2,J_2_3,J_2_4,J_2_5,J_2_6,J_2_7 ='';  // 売価
    let J_3_1,J_3_2,J_3_3,J_3_4,J_3_5,J_3_6,J_3_7 ='';  // 入荷
    let J_4_1,J_4_2,J_4_3,J_4_4,J_4_5,J_4_6,J_4_7 ='';  // 正販
    let J_5_1,J_5_2,J_5_3,J_5_4,J_5_5,J_5_6,J_5_7 ='';  // 見切
    let J_6_1,J_6_2,J_6_3,J_6_4,J_6_5,J_6_6,J_6_7 ='';  // 廃棄
    //S SK-0008-052 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
    // 初期化
    J_2_1='-';
    J_2_2='-';
    J_2_3='-';
    J_2_4='-';
    J_2_5='-';
    J_2_6='-';
    J_2_7='-';
    //S SK-0024-003 2024/6/27           TCI)M.K【課題No.37：過去実績の入荷から廃棄は「０」表示】
    // J_3_1 ='-';
    // J_3_2 ='-';
    // J_3_3 ='-';
    // J_3_4 ='-';
    // J_3_5 ='-';
    // J_3_6 ='-';
    // J_3_7 ='-';
    // J_4_1 ='-';
    // J_4_2 ='-';
    // J_4_3 ='-';
    // J_4_4 ='-';
    // J_4_5 ='-';
    // J_4_6 ='-';
    // J_4_7 ='-';
    // J_5_1 ='-';
    // J_5_2 ='-';
    // J_5_3 ='-';
    // J_5_4 ='-';
    // J_5_5 ='-';
    // J_5_6 ='-';
    // J_5_7 ='-';
    // J_6_1 ='-';
    // J_6_2 ='-';
    // J_6_3 ='-';
    // J_6_4 ='-';
    // J_6_5 ='-';
    // J_6_6 ='-';
    // J_6_7 ='-';    
    // //E SK-0008-052
    J_3_1 ='0';
    J_3_2 ='0';
    J_3_3 ='0';
    J_3_4 ='0';
    J_3_5 ='0';
    J_3_6 ='0';
    J_3_7 ='0';
    J_4_1 ='0';
    J_4_2 ='0';
    J_4_3 ='0';
    J_4_4 ='0';
    J_4_5 ='0';
    J_4_6 ='0';
    J_4_7 ='0';
    J_5_1 ='0';
    J_5_2 ='0';
    J_5_3 ='0';
    J_5_4 ='0';
    J_5_5 ='0';
    J_5_6 ='0';
    J_5_7 ='0';
    J_6_1 ='0';
    J_6_2 ='0';
    J_6_3 ='0';
    J_6_4 ='0';
    J_6_5 ='0';
    J_6_6 ='0';
    J_6_7 ='0';
    //E SK-0024-003

    let jancde = '';
    if (httItem.HTT_CDE === '1')
    {
      jancde = httItem.JANCDE
    }else{
      jancde = httItem.EOSCDE
    }

    if (httItem.HTT_CDE !== '')
    {
      const getkkjdat = await fetch('/TCSH0101/SH_KKJ_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      //S SK-0054-010 2024/7/30           TCI)M.K【過去実績レスポンス対応】
      // body: JSON.stringify({htt:httItem,NHNYMD1:yyyymmdd1,NHNYMD2:yyyymmdd7,JANCDE:jancde})});
      body: JSON.stringify({
        htt:httItem,
        NHNYMD1:yyyymmdd1,
        NHNYMD2:yyyymmdd7,
        JANCDE:jancde,
        SHNID:rowItem.SHNID,
      })});
      //E SK-0054-010
      const kkjdat = await getkkjdat.json();
      if(kkjdat.CNT !== 0) {
        console.log('過去実績データget:%o',kkjdat);
        for(const key in kkjdat.ret) {
          if(kkjdat.ret[key].NHNYMD===yyyymmdd1){
            J_1_1=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
            kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
            kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
            kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
            kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
            kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
            //S SK-0008-022 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
            // J_2_1=formatCurrency(Number(kkjdat.ret[key].BTN));
            J_2_1=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
            //E SK-0008-022
            J_3_1=kkjdat.ret[key].NYUKA;
            J_4_1=kkjdat.ret[key].SEIHAN;
            J_5_1=kkjdat.ret[key].MIKIRI;
            J_6_1=kkjdat.ret[key].HAIKI;
          }
          else if(kkjdat.ret[key].NHNYMD===yyyymmdd2){
            J_1_2=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
            kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
            kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
            kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
            kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
            kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
            //S SK-0008-023 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
            // J_2_2=formatCurrency(Number(kkjdat.ret[key].BTN));
            J_2_2=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
            //E SK-0008-023
            J_3_2=kkjdat.ret[key].NYUKA;
            J_4_2=kkjdat.ret[key].SEIHAN;
            J_5_2=kkjdat.ret[key].MIKIRI;
            J_6_2=kkjdat.ret[key].HAIKI;
          }
          else if(kkjdat.ret[key].NHNYMD===yyyymmdd3){
            J_1_3=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
            kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
            kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
            kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
            kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
            kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
            //S SK-0008-024 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
            // J_2_3=formatCurrency(Number(kkjdat.ret[key].BTN));
            J_2_3=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
            //E SK-0008-024
            J_3_3=kkjdat.ret[key].NYUKA;
            J_4_3=kkjdat.ret[key].SEIHAN;
            J_5_3=kkjdat.ret[key].MIKIRI;
            J_6_3=kkjdat.ret[key].HAIKI;
          }
          else if(kkjdat.ret[key].NHNYMD===yyyymmdd4){
            J_1_4=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
            kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
            kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
            kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
            kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
            kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
            //S SK-0008-025 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
            // J_2_4=formatCurrency(Number(kkjdat.ret[key].BTN));
            J_2_4=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
            //E SK-0008-025
            J_3_4=kkjdat.ret[key].NYUKA;
            J_4_4=kkjdat.ret[key].SEIHAN;
            J_5_4=kkjdat.ret[key].MIKIRI;
            J_6_4=kkjdat.ret[key].HAIKI;
          }
          else if(kkjdat.ret[key].NHNYMD===yyyymmdd5){
            J_1_5=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
            kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
            kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
            kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
            kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
            kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
            //S SK-0008-026 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
            // J_2_5=formatCurrency(Number(kkjdat.ret[key].BTN));
            J_2_5=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
            //E SK-0008-026
            J_3_5=kkjdat.ret[key].NYUKA;
            J_4_5=kkjdat.ret[key].SEIHAN;
            J_5_5=kkjdat.ret[key].MIKIRI;
            J_6_5=kkjdat.ret[key].HAIKI;
          }
          else if(kkjdat.ret[key].NHNYMD===yyyymmdd6){
            J_1_6=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
            kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
            kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
            kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
            kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
            kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
            //S SK-0008-027 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
            // J_2_6=formatCurrency(Number(kkjdat.ret[key].BTN));
            J_2_6=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
            //E SK-0008-027
            J_3_6=kkjdat.ret[key].NYUKA;
            J_4_6=kkjdat.ret[key].SEIHAN;
            J_5_6=kkjdat.ret[key].MIKIRI;
            J_6_6=kkjdat.ret[key].HAIKI;
          }
          else if(kkjdat.ret[key].NHNYMD===yyyymmdd7){
            J_1_7=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
            kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
            kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
            kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
            kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
            kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
            //S SK-0008-028 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
            // J_2_7=formatCurrency(Number(kkjdat.ret[key].BTN));
            J_2_7=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
            //E SK-0008-028
            J_3_7=kkjdat.ret[key].NYUKA;
            J_4_7=kkjdat.ret[key].SEIHAN;
            J_5_7=kkjdat.ret[key].MIKIRI;
            J_6_7=kkjdat.ret[key].HAIKI;
          }
        }
      }
    }
    const newRows2 = [];
    newRows2.push(
      {id: '1', field0: '天候', field1: J_t_1, field2: J_t_2, field3: J_t_3, field4: J_t_4, field5: J_t_5,field6: J_t_6,field7: J_t_7,}
      ,{id: '2', field0: '体感', field1: J_k_1, field2: J_k_2, field3: J_k_3, field4: J_k_4, field5: J_k_5,field6: J_k_6,field7: J_k_7,}
      ,{id: '3', field0: '温度', field1: J_o_1, field2: J_o_2, field3: J_o_3, field4: J_o_4, field5: J_o_5,field6: J_o_6,field7: J_o_7,}
      ,{id: '4', field0: '企画', field1:J_1_1, field2:J_1_2, field3:J_1_3, field4:J_1_4, field5:J_1_5,field6:J_1_6,field7:J_1_7,}
      ,{id: '5', field0: '売価', field1:J_2_1, field2:J_2_2, field3:J_2_3, field4:J_2_4, field5:J_2_5,field6:J_2_6,field7:J_2_7,}
      ,{id: '6', field0: '入荷', field1:J_3_1, field2:J_3_2, field3:J_3_3, field4:J_3_4, field5:J_3_5,field6:J_3_6,field7:J_3_7,}
      ,{id: '7', field0: '正販', field1:J_4_1, field2:J_4_2, field3:J_4_3, field4:J_4_4, field5:J_4_5,field6:J_4_6,field7:J_4_7,}
      ,{id: '8', field0: '見切', field1:J_5_1, field2:J_5_2, field3:J_5_3, field4:J_5_4, field5:J_5_5,field6:J_5_6,field7:J_5_7,}
      ,{id: '9', field0: '廃棄', field1:J_6_1, field2:J_6_2, field3:J_6_3, field4:J_6_4, field5:J_6_5,field6:J_6_6,field7:J_6_7,}
      );
    setRows2(newRows2);

    if (Flagbutton ===1){
      if(jbtnname === '前々週'){
        setJBtnName('今週');
        setJBtnName2('前々週');
      }else if(jbtnname === '今週'){
        setJBtnName('前週');
        setJBtnName2('今週');
      }else if(jbtnname === '前週'){
        setJBtnName('前々週');
        setJBtnName2('前週');
      }
    }
    setChkflg(0);
  }

  
  useEffect (()=>{

    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('スリープから復帰しました');
        timeRef.current.reTimer();
        // ここにスリープ復帰時の処理を記述
      }
    };

    let outflg = 0;
    const handleTimerStopped = (event) => {
      // イベントの詳細メッセージを取得
      console.log('xxxx:%o', event.detail.message);
      onClick_FooterButton_Clear();
      outflg = 1;
      // 他の処理をここに追加
    };

    window.addEventListener('timerStopped', handleTimerStopped);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // システム日付移送
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    // 発注区分タイトル変更
    if(httkbn==='1')     //定番
    {
      settitleHTTKBN('定番');
    }else if(httkbn==='2'){     //特売
      settitleHTTKBN('特売');
    }

    // // 曜日計算
    let yyyy = String(state.HTTYMD).substring(0,4);
    let mm = String(state.HTTYMD).substring(4,6)-1;
    let dd = String(state.HTTYMD).substring(6,8);
    // // console.log(yyyy,mm+1,dd);
    let dH = new Date(yyyy,mm,dd);
    // console.log(dH.getDay());
    // 20240522都度取得するように変更
    // if (dH.getDay() === 0){
    //   setWeek(7);
    // }else{
    //   setWeek(dH.getDay());
    // } 

    //発注日から１週間分の日付を取得する
    var dayOfWeekStr = [ "(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)" ][dH.getDay()] ;	// 曜日(日本語表記)
    var weekdate = new Date(yyyy,mm,dd), // 基準となる日付は使いまわす
    addDate = 1, // 足す日数
    max = 6; // 何回繰り返すか
    let wkweekdate = '';
    console.log(weekdate.getMonth() + 1 + '/' + weekdate.getDate() +'(' + dayOfWeekStr + ')');
    // wkweekdate=weekdate.getMonth() + 1 + '/' + weekdate.getDate() +'(' + dayOfWeekStr + ')';
    // wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() +'(' + dayOfWeekStr + ')';
    // setJDay1(wkweekdate);
    // yyyymmdd = weekdate.getFullYear() + ('0'+(weekdate.getMonth()+1)).slice(-2) + ('0'+weekdate.getDate()).slice(-2);
    // yyyymmdd1 =yyyymmdd;
    // console.log(yyyymmdd);

    let yyyymmdd = weekdate.getFullYear() + ('0'+(weekdate.getMonth()+1)).slice(-2) + ('0'+weekdate.getDate()).slice(-2);
    console.log(yyyymmdd);
    if (myHoliday.includes(yyyymmdd)){
      wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() + '(祝)' ;
      setYSHDay1(wkweekdate);
      setYSDay1(1);
    }else{
      wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() + dayOfWeekStr ;
      setYSHDay1('X');
      setYSDay1(0);
    }
    setYDay1(wkweekdate);
    if (wkweekdate.includes('土'))
      {
        setYDayD(wkweekdate);
        setYDayD_add(1);
      }
    if (wkweekdate.includes('日'))
      {
        setYDayN(wkweekdate);
        setYDayN_add(1);
      }
      
    for (var i = 1; i <= max; i++) {
      weekdate.setDate(weekdate.getDate() + addDate);
      dayOfWeekStr = [ "(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)" ][weekdate.getDay()] ;	// 曜日(日本語表記)
      // console.log(weekdate.getMonth() + 1 + '/' + weekdate.getDate() +'(' + dayOfWeekStr + ')');
      wkweekdate=weekdate.getMonth() + 1 + '/' + weekdate.getDate() +'(' + dayOfWeekStr + ')';
      yyyymmdd = weekdate.getFullYear() + ('0'+(weekdate.getMonth()+1)).slice(-2) + ('0'+weekdate.getDate()).slice(-2);
      console.log(yyyymmdd);
      let sflg = 0;
      if (myHoliday.includes(yyyymmdd)){
        wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() + '(祝)' ;
        sflg = 1;
      }else{
        wkweekdate = weekdate.getMonth() + 1 + '/' + weekdate.getDate() + dayOfWeekStr ;
      }
      
      if (i===1) {
        setYDay2(wkweekdate);
        if (sflg === 1){
          setYSHDay2(wkweekdate);
          setYSDay2(2);
        }else{
          setYSHDay2('X');
          setYSDay2(0);
        }
      }
      else if(i===2){
        setYDay3(wkweekdate);
        if (sflg === 1){
          setYSHDay3(wkweekdate);
          setYSDay3(3);
        }else{
          setYSHDay3('X');
          setYSDay3(0);
        }
      }
      else if(i===3){
        setYDay4(wkweekdate);
        if (sflg === 1){
          setYSHDay4(wkweekdate);
          setYSDay4(4);
        }else{
          setYSHDay4('X');
          setYSDay4(0);
        }
      }
      else if(i===4){
        setYDay5(wkweekdate);
        if (sflg === 1){
          setYSHDay5(wkweekdate);
          setYSDay5(5);
        }else{
          setYSHDay5('X');
          setYSDay5(0);
        }
      }
      else if(i===5){
        setYDay6(wkweekdate);
        if (sflg === 1){
          setYSHDay6(wkweekdate);
          setYSDay6(6);
        }else{
          setYSHDay6('X');
          setYSDay6(0);
        }
      }
      else if(i===6){
        setYDay7(wkweekdate);
        if (sflg === 1){
          setYSHDay7(wkweekdate);
          setYSDay7(7);
        }else{
          setYSHDay7('X');
          setYSDay7(0);
        }
      }
      if (wkweekdate.includes('土'))
        {
          setYDayD(wkweekdate);
          setYDayD_add(i+1);
        }
      if (wkweekdate.includes('日'))
        {
          setYDayN(wkweekdate);
          setYDayN_add(i+1);
        }
    }

    // 天気情報を取得する
    const uTnk_Get = async () => {
      console.log('天気情報を取得する');
      await Tnk_Get();
      // システム日付から過去１週間分の日付を取得する
      console.log('システム日付から過去１週間分の日付を取得する');
      await jHeaderSet(0);
    };
    uTnk_Get();

    // buttonの活性・非活性を切り替える
    noSendCheck();

    const uJAN1 = async () => {
      await onKeyDown_JAN1(inJAN1, inSUU);
    };
    const uJAN2 = async () => {
      await onKeyDown_JAN2(inJAN2, inSUU);
    };

    // 全画面モード
    // console.log('全画面 モード:%o',document.fullscreenElement);
    // if (!document.fullscreenElement){
    //   console.log('全画面 モード:on');
    //   document.documentElement.webkitRequestFullScreen();
    // }

    // SKUからの戻り処理
    if (outflg === 0)
    {
      if (state.MVEMGAMEN === 'TCSH0151')
        {
          if (state.JANCDE === '' && state.EOSCDE !== '')
          {
            rowItem.JANCDE = state.EOSCDE;
            rowItem.EOSCDE = state.EOSCDE;
            inJAN1.current.value = state.EOSCDE;
            rowItem.IN_CD_KBN = state.IN_CD_KBN;
            uJAN1();
          }else{
            rowItem.JANCDE = state.JANCDE;
            if (state.IN_CD_KBN === '1')
              {
                inJAN1.current.value = state.JANCDE
              }else{
                inJAN1.current.value = state.EOSCDE
              }
            rowItem.IN_CD_KBN = state.IN_CD_KBN;
            uJAN1();
            if (state.JANCDE2.trim() !== '')
            {
              rowItem.JANCDE2 = state.JANCDE2;
              inJAN2.current.value = state.JANCDE2
              uJAN2();
            }
          }
          setSKUFlg(1);
          dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
            KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
            MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
            SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, MVEMGAMEN:'',
            BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:state.HTTYMD,HTTKBN:state.HTTKBN,
            IN_CD_KBN:'',JANCDE:'',JANCDE2:'',EOSCDE:'', TRIHBN: ''
          });
        }else{
          //フォーカス
          inJAN1.current.focus();
        }      
    }

    return () => {
      window.removeEventListener('timerStopped', handleTimerStopped);
    };
  },[]);

  const [StartTime,setStartTime] = useState(0); //開始時間
  var EndTime;          //終了時間
  const [Scanflg,setScanflg] = useState(0);     //スキャン判定 0:入力,1:スキャナー
  const [Over8,setOver8] = useState(0);         //8桁以上入力判定(スキャン判定時) 0:判定不要,1:入力チェック

  useEffect (()=>{
    if(Over8 === 1){
      setTimeout(() => {
        console.log(`８桁タイマー処理:`+Over8+'/'+inJAN1.current.value.length);
        if (Over8 === 1 && inJAN1.current.value.length === 8)
        {
          console.log(`８桁処理開始`);
          onKeyDown_JAN1(inJAN1,inSUU);
        }
      },200);
    }
  }, [Over8]);

  //S SK-0048-002 2024/7/12           TCI)M.K【SKU⇒取扱無しの場合、SKUに戻らない】
  useEffect(() => {
    console.log(`!!!!!SKUflg変更を検知`,SKUflg);
    uSKUflg = SKUflg;
  }, [SKUflg]);  
  //E SK-0048-002

  useEffect(() => {
    if (nextActive === 'JAN1') {
      inJAN1.current.focus();
      setNextActive('');
    }
  }, [disJan1]);

  useEffect(() => {
    if (nextActive === 'JAN2') {
      inJAN2.current.focus();
      setNextActive('');
    }
  }, [disJan2]);

  // 天気情報取得処理
  const Tnk_Get = async() => {
    let errflg = 0;
    console.log('天気情報取得開始')
    if (errflg === 0 )
    {
      const getTnkMaster = await fetch('/TCSH0000/get_TNK_DAT',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({KSACDE:state.KSACDES, MISE:state.MISES })});
      TnkMaster = await getTnkMaster.json();
      console.log('TnkMaster:%o',TnkMaster);
  
      if(TnkMaster.ret.length !== 0) {
        
      }
    }
  }

  // 天気情報set処理
  const Tnk_Set = async(yyyymmdd) => {
    let errflg = 0;
    let str1 = '';
    let str2 = '';
    let str3 = '';
    // console.log('天気情報セット開始:',yyyymmdd)
    // 初期化（先に暫定をセット）
    str1 = `/tenki_icon/noti.gif`;
    if (errflg === 0 )
    {
      for(const key in TnkMaster.ret) {
        // console.log('key',key)
        // console.log('TNK',TnkMaster.ret[key].TENKI)
        //S SK-0013-008 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
        // if(TnkMaster.ret[key].YMD===yyyymmdd){
        //   if(TnkMaster.ret[key].TENKI==='100'){
        //     str1 = `/tenki_icon/hare.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='112'){
        //     //WTR=112 晴れのち曇り
        //     str1 = `/tenki_icon/hare.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='113'){
        //     //WTR=113 晴れのち雨
        //     str1 = `/tenki_icon/hare.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='114'){
        //     //WTR=114 晴れのち雪
        //     str1 = `/tenki_icon/hare.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='123'){
        //     //WTR=123 晴れ一時雨
        //     str1 = `/tenki_icon/hare.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='124'){
        //     //WTR=124 晴れ一時雪
        //     str1 = `/tenki_icon/hare.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='132'){
        //     //WTR=132 晴れ時々曇り
        //     str1 = `/tenki_icon/hare.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='133'){
        //     //WTR=133 晴れ時々雨
        //     str1 = `/tenki_icon/hare.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='134'){
        //     //WTR=134 晴れ時々雪
        //     str1 = `/tenki_icon/hare.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='200'){
        //     //WTR=200 曇り
        //     str1 = `/tenki_icon/kumori.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='211'){
        //     //WTR=211 曇りのち晴れ
        //     str1 = `/tenki_icon/kumori.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='213'){
        //     //WTR=213 曇りのち雨
        //     str1 = `/tenki_icon/kumori.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='214'){
        //     //WTR=214 曇りのち雪
        //     str1 = `/tenki_icon/kumori.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='223'){
        //     //WTR=223 曇り一時雨
        //     str1 = `/tenki_icon/kumori.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='224'){
        //     //WTR=224 曇り一時雪
        //     str1 = `/tenki_icon/kumori.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='231'){
        //     //WTR=231 曇り時々晴れ
        //     str1 = `/tenki_icon/kumori.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='233'){
        //     //WTR=233 曇り時々雨
        //     str1 = `/tenki_icon/kumori.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='234'){
        //     //WTR=234 曇り時々雪
        //     str1 = `/tenki_icon/kumori.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='300'){
        //     //WTR=300 雨
        //     str1 = `/tenki_icon/ame.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='311'){
        //     //WTR=311 雨のち晴れ 
        //     str1 = `/tenki_icon/ame.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='312'){
        //     //WTR=312 雨のち曇り
        //     str1 = `/tenki_icon/ame.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='314'){
        //     //WTR=314 雨のち雪
        //     str1 = `/tenki_icon/ame.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='324'){
        //     //WTR=324 雨一時雪
        //     str1 = `/tenki_icon/ame.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='331'){
        //     //WTR=331 雨時々晴れ
        //     str1 = `/tenki_icon/ame.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='332'){
        //     //WTR=332 雨時々止む
        //     str1 = `/tenki_icon/ame.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='334'){
        //     //WTR=334 雨時々雪
        //     str1 = `/tenki_icon/ame.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='400'){
        //     //WTR=400 雪
        //     str1 = `/tenki_icon/yuki.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='411'){
        //     //WTR=411 雪のち晴れ
        //     str1 = `/tenki_icon/yuki.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='412'){
        //     //WTR=412 雪のち曇り
        //     str1 = `/tenki_icon/yuki.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='413'){
        //     //WTR=413 雪のち雨
        //     str1 = `/tenki_icon/yuki.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='423'){
        //     //WTR=423 雪一時雨
        //     str1 = `/tenki_icon/yuki.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='431'){
        //     //WTR=431 雪時々晴れ
        //     str1 = `/tenki_icon/yuki.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='432'){
        //     //WTR=432 雪時々止む
        //     str1 = `/tenki_icon/yuki.gif`;
        //   }else if(TnkMaster.ret[key].TENKI==='433'){
        //     //WTR=433 雪時々雨
        //     str1 = `/tenki_icon/yuki.gif`;
        //   }else{
        //     // それ以外は暫定でこれセットする
        //     str1 = `/tenki_icon/noti.gif`;
        //   }
        //   str2 = TnkMaster.ret[key].TAIKAN
        //   str3 = (TnkMaster.ret[key].HITEMP/10)+'℃';
        // }
        if(TnkMaster.ret[key].YMD===yyyymmdd){
          if(TnkMaster.ret[key].TENKI==='100'){
            str1 = `/tenki_icon/hare.gif`;
          }else if(TnkMaster.ret[key].TENKI==='112'){
            //WTR=112 晴れのち曇り
            str1 = `/tenki_icon/hare.gif,/tenki_icon/noti.gif,/tenki_icon/kumori.gif`;
          }else if(TnkMaster.ret[key].TENKI==='113'){
            //WTR=113 晴れのち雨
            str1 = `/tenki_icon/hare.gif,/tenki_icon/noti.gif,/tenki_icon/ame.gif`;
          }else if(TnkMaster.ret[key].TENKI==='114'){
            //WTR=114 晴れのち雪
            str1 = `/tenki_icon/hare.gif,/tenki_icon/noti.gif,/tenki_icon/yuki.gif`;
          }else if(TnkMaster.ret[key].TENKI==='123'){
            //WTR=123 晴れ一時雨
            str1 = `/tenki_icon/hare.gif,/tenki_icon/tokidoki.gif,/tenki_icon/ame2.gif`;
          }else if(TnkMaster.ret[key].TENKI==='124'){
            //WTR=124 晴れ一時雪
            str1 = `/tenki_icon/hare.gif,/tenki_icon/tokidoki.gif,/tenki_icon/yuki2.gif`;
          }else if(TnkMaster.ret[key].TENKI==='132'){
            //WTR=132 晴れ時々曇り
            str1 = `/tenki_icon/hare.gif,/tenki_icon/tokidoki.gif,/tenki_icon/kumori.gif`;
          }else if(TnkMaster.ret[key].TENKI==='133'){
            //WTR=133 晴れ時々雨
            str1 = `/tenki_icon/hare.gif,/tenki_icon/tokidoki.gif,/tenki_icon/ame.gif`;
          }else if(TnkMaster.ret[key].TENKI==='134'){
            //WTR=134 晴れ時々雪
            str1 = `/tenki_icon/hare.gif,/tenki_icon/tokidoki.gif,/tenki_icon/yuki.gif`;
          }else if(TnkMaster.ret[key].TENKI==='200'){
            //WTR=200 曇り
            str1 = `/tenki_icon/kumori.gif`;
          }else if(TnkMaster.ret[key].TENKI==='211'){
            //WTR=211 曇りのち晴れ
            str1 = `/tenki_icon/kumori.gif,/tenki_icon/noti.gif,/tenki_icon/hare.gif`;
          }else if(TnkMaster.ret[key].TENKI==='213'){
            //WTR=213 曇りのち雨
            str1 = `/tenki_icon/kumori.gif,/tenki_icon/noti.gif,/tenki_icon/ame.gif`;
          }else if(TnkMaster.ret[key].TENKI==='214'){
            //WTR=214 曇りのち雪
            str1 = `/tenki_icon/kumori.gif,/tenki_icon/noti.gif,/tenki_icon/yuki.gif`;
          }else if(TnkMaster.ret[key].TENKI==='223'){
            //WTR=223 曇り一時雨
            str1 = `/tenki_icon/kumori.gif,/tenki_icon/tokidoki.gif,/tenki_icon/ame2.gif`;
          }else if(TnkMaster.ret[key].TENKI==='224'){
            //WTR=224 曇り一時雪
            str1 = `/tenki_icon/kumori.gif,/tenki_icon/tokidoki.gif,/tenki_icon/yuki2.gif`;
          }else if(TnkMaster.ret[key].TENKI==='231'){
            //WTR=231 曇り時々晴れ
            str1 = `/tenki_icon/kumori.gif,/tenki_icon/tokidoki.gif,/tenki_icon/hare.gif`;
          }else if(TnkMaster.ret[key].TENKI==='233'){
            //WTR=233 曇り時々雨
            str1 = `/tenki_icon/kumori.gif,/tenki_icon/tokidoki.gif,/tenki_icon/ame.gif`;
          }else if(TnkMaster.ret[key].TENKI==='234'){
            //WTR=234 曇り時々雪
            str1 = `/tenki_icon/kumori.gif,/tenki_icon/tokidoki.gif,/tenki_icon/yuki.gif`;
          }else if(TnkMaster.ret[key].TENKI==='300'){
            //WTR=300 雨
            str1 = `/tenki_icon/ame.gif`;
          }else if(TnkMaster.ret[key].TENKI==='311'){
            //WTR=311 雨のち晴れ 
            str1 = `/tenki_icon/ame.gif,/tenki_icon/noti.gif,/tenki_icon/hare.gif`;
          }else if(TnkMaster.ret[key].TENKI==='312'){
            //WTR=312 雨のち曇り
            str1 = `/tenki_icon/ame.gif,/tenki_icon/noti.gif,/tenki_icon/kumori.gif`;
          }else if(TnkMaster.ret[key].TENKI==='314'){
            //WTR=314 雨のち雪
            str1 = `/tenki_icon/ame.gif,/tenki_icon/noti.gif,/tenki_icon/yuki.gif`;
          }else if(TnkMaster.ret[key].TENKI==='324'){
            //WTR=324 雨一時雪
            str1 = `/tenki_icon/ame.gif,/tenki_icon/tokidoki.gif,/tenki_icon/yuki2.gif`;
          }else if(TnkMaster.ret[key].TENKI==='331'){
            //WTR=331 雨時々晴れ
            str1 = `/tenki_icon/ame.gif,/tenki_icon/tokidoki.gif,/tenki_icon/hare.gif`;
          }else if(TnkMaster.ret[key].TENKI==='332'){
            //WTR=332 雨時々止む
            str1 = `/tenki_icon/ame.gif,/tenki_icon/tokidoki.gif,/tenki_icon/kumori.gif`;
          }else if(TnkMaster.ret[key].TENKI==='334'){
            //WTR=334 雨時々雪
            str1 = `/tenki_icon/ame.gif,/tenki_icon/tokidoki.gif,/tenki_icon/yuki.gif`;
          }else if(TnkMaster.ret[key].TENKI==='400'){
            //WTR=400 雪
            str1 = `/tenki_icon/yuki.gif`;
          }else if(TnkMaster.ret[key].TENKI==='411'){
            //WTR=411 雪のち晴れ
            str1 = `/tenki_icon/yuki.gif,/tenki_icon/noti.gif,/tenki_icon/hare.gif`;
          }else if(TnkMaster.ret[key].TENKI==='412'){
            //WTR=412 雪のち曇り
            str1 = `/tenki_icon/yuki.gif,/tenki_icon/noti.gif,/tenki_icon/kumori.gif`;
          }else if(TnkMaster.ret[key].TENKI==='413'){
            //WTR=413 雪のち雨
            str1 = `/tenki_icon/yuki.gif,/tenki_icon/noti.gif,/tenki_icon/ame.gif`;
          }else if(TnkMaster.ret[key].TENKI==='423'){
            //WTR=423 雪一時雨
            str1 = `/tenki_icon/yuki.gif,/tenki_icon/tokidoki.gif,/tenki_icon/ame2.gif`;
          }else if(TnkMaster.ret[key].TENKI==='431'){
            //WTR=431 雪時々晴れ
            str1 = `/tenki_icon/yuki.gif,/tenki_icon/tokidoki.gif,/tenki_icon/hare.gif`;
          }else if(TnkMaster.ret[key].TENKI==='432'){
            //WTR=432 雪時々止む
            str1 = `/tenki_icon/yuki.gif,/tenki_icon/tokidoki.gif,/tenki_icon/kumori.gif`;
          }else if(TnkMaster.ret[key].TENKI==='433'){
            //WTR=433 雪時々雨
            str1 = `/tenki_icon/yuki.gif,/tenki_icon/tokidoki.gif,/tenki_icon/ame.gif`;
          }else{
            // それ以外は暫定でこれセットする
            str1 = `/tenki_icon/noti.gif`;
          }
          str2 = TnkMaster.ret[key].TAIKAN;
          // str3 = (TnkMaster.ret[key].HITEMP/10)+'℃'; 2024/06/27 H.I
          str3 = (TnkMaster.ret[key].LOWTEMP/10)+'/'+(TnkMaster.ret[key].HITEMP/10);  //2024/06/27 H.I
        }        
        //E SK-0013-008
      }
      // console.log('天気情報セット終了:',str1,str2)
      return {str1,str2,str3};
    }
    }

    // 次へボタン押下時の処理
    const onClick_FooterButton_Next = async() => {
    let errflg = 0;
    //S SK-0059-003 2024/8/6           TCI)M.K【課題No.83：送信確認メッセージ対応】
    if (window.confirm('送信しますか？')) { 
    }else{
      return;
    }
    //E SK-0059-003
    console.log('送信ボタン押下:')
    if (errflg === 0 )
    {
      const updhttdat = await fetch('/TCSH0101/UpdHttDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES
          ,MISE:state.MISES
          ,header:httItem
          ,JGNCDE:state.JGNCDE
          ,SND_KNR_KBN:'1'
          ,add_prg_id:'TCSH0101'
        })});
      const updhtt = await updhttdat.json();
      if(updhtt.res === '-1') {
        console.log('送信失敗');
      }else{
      }
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:state.HTTYMD,HTTKBN:httkbn
      });

      // ログ出力
      const responseUA1 = await fetch('/TCSH0000/SH_LOG_DAT_INS',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
        ksacde : state.KSACDES      //会社コード
        ,mise :  state.MISES        //店コード
        ,biko : state.BMNCDE + ',送信処理,' + httymd + ',' + httkbn   //備考
        ,add_jgncde : state.JGNCDE  //ユーザーID
        ,add_prg_id : 'TCSH0101'    //プログラムID
        ,delflg :'0'                //削除フラグ
      })});
      const dataUA1 = await responseUA1.json();
      console.log('ログインデータ:%o',dataUA1.res);

      // 伝票ヘッダ 登録エラー
      if (dataUA1.res == -1) {
        setAlert('ログインデータ登録エラー');
          return
      }

      clock2Ref.current.stopTimer();            // タイマー停止
      navigate('/TCSH0170');
    }
  }

  // 区分変更ボタン押下時の処理
  const onClick_FooterButton_KbnChange = async() => {
    let errflg = 0;
    console.log('区分変更ボタン押下:')
    if (errflg === 0 )
    {
      // 発注区分タイトル変更
      if(httkbn==='1')
      {
        setHttkbn("2");
        settitleHTTKBN('特売');
      }else if(httkbn==='2'){
        setHttkbn("2");
        settitleHTTKBN('定番');
      }
      inSUU.current.focus();   //次の項目に遷移
    }
  }

  // 次週ボタン押下時の処理
  const onClick_FooterButton_NextWeek = async() => {
    let errflg = 0;
    console.log('次週ボタン押下:')
    if (errflg === 0 )
    {
      await jHeaderSet(1);
    }
  }

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
    clock2Ref.current.stopTimer();            // タイマー停止
    navigate('/TCSH0040');
  }

  // 再入力ボタン　押下
  const onClick_FooterButton_Clear = async () => {
    console.log("再入力ボタン　押下");
    // タイマー停止
    clock2Ref.current.stopTimer();
    // メモリクリア
    rowItem.JANCDE = '';

    setRowItem(rowData);             // 商品データ
    setHttItem(httData);             // 発注データ
    setRows([]);                // 予定データ
    setRows2([]);               // 実績データ
    setBtnSKU(true);
    setDisJan2(true);
    clear_sinops();

    // 画面クリア
    setBin('');
    setSuu('');
    //S SK-0060-012 2024/8/6           TCI)M.K【課題No.84：何もしないでEnterはキャンセル扱い】
    setGetSuu('');
    //E SK-0060-012
    setHttkbn(state.HTTKBN);
    setSKUFlg(0);               // SKU処理フラグ解除

    // 発注区分タイトル変更
    if(httkbn==='1')     //定番
    {
      settitleHTTKBN('定番');
    }else if(httkbn==='2'){     //特売
      settitleHTTKBN('特売');
    }

    // 色クリア
    setJAN1clr('#FFFFFF');
    setJAN2clr('#CCCCCC');
    setBINclr('#CCCCCC');
    setSUUclr('#ffff00');
  
    // フォーカス
    inJAN1.current.focus();   // 初期項目に遷移
    console.log('RowItem:',rowItem)
    console.log('RowItem:',rowItem)

    console.log('天気情報を取得する');
    await Tnk_Get();
    // システム日付から過去１週間分の日付を取得する
    console.log('システム日付から過去１週間分の日付を取得する');
    await jHeaderSet(0);

    // buttonの活性・非活性を切り替える
    noSendCheck();
  }

  // シノプスボタン押下時の処理
  const onClick_FooterButton_sinops = async() => {
    if (rowItem.IN_CD_KBN !== ''){
      // showSinopsInfo();
      // 発注日（システム日付）が発注終了日以降に該当の場合、エラー（2024/06/03追加）
      if (rowItem.HTTEND !== ''){
        let ndate = new Date(state.SDAY);
        let today = ndate.getFullYear() + ('0'+(ndate.getMonth()+1)).slice(-2) + ('0'+ndate.getDate()).slice(-2)
        if (rowItem.HTTEND < today){
          window.alert('この商品の発注終了日は'+formatDate(rowItem.HTTEND)+'までです。\n入力された日付は発注対象期間外です。'); 
          timeRef.current.reTimer();
        }else{
          showSinopsInfo();
        }
      }
    }else{
      window.alert('商品コードが入力されていません'); 
      timeRef.current.reTimer();
    }
  }

  // 商品情報表示ボタン押下時の処理
  const onClick_FooterButton_shn = async() => {
    if (rowItem.IN_CD_KBN !== ''){
      // // 商品情報表示用に変数格納処理
      // setSjhjancde(rowItem.JANCDE);  // 場所間違い
      if (rowItem.BMNCDE.substring(0, 1)!=='1'){
        setNeiri(rowItem.NEIRI);
      }
      if(state.IRYOFLG==='1'){
        setTrihbn(rowItem.TRIHBN);
        setColmei(rowItem.TAGCOLMEI);
        setSizmei(rowItem.TAGSIZMEI);
        setSznmn(rowItem.TAGSZNNM);
        setSymnsu(rowItem.SYMNSU);
      }

      showShnInfo();
    }else{
      window.alert('入力されたコードに誤りが有ります'); 
      timeRef.current.reTimer();
    }
  }

  // ＳＫＵボタン押下時の処理
  const onClick_FooterButton_SKU = async() => {
    let errflg = 0;
    console.log('ＳＫＵボタン押下:');
    if (errflg == 0 )
    {
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, MVEMGAMEN:'TCSH0101',
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:state.HTTYMD,HTTKBN:state.HTTKBN,
        IN_CD_KBN:rowItem.IN_CD_KBN,JANCDE:rowItem.JANCDE,JANCDE2:rowItem.JANCDE2,EOSCDE:rowItem.EOSCDE
        //S SK-0048-003 2024/7/12           TCI)M.K【SKU⇒取扱無しの場合、SKUに戻らない】
        // , TRIHBN: rowItem.TRIHBN
        , TRIHBN: rowItem.TRIHBN === '' ? uTRIHBN : rowItem.TRIHBN
        //E SK-0048-003
      });
      navigate('/TCSH0151');  //仮★後で見直す
    }
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        switch (inputRef.current.id) {
          case 'JAN1':    // ＪＡＮ１
            await onKeyDown_JAN1(inputRef, inputNxRef);
            // CloseTimeCheck('2024/03/22 16:45:00');
            break;
          case 'JAN2':    // ＪＡＮ２
            await onKeyDown_JAN2(inputRef, inputNxRef);
            // CloseTimeCheck('2024/03/22 16:45:00');
            break;
          case 'BIN':
            await onKeyDown_BIN(inputRef, inputNxRef);
            break;
          case 'SUU':
            // await onKeyDown_BIN(inputRef, inputNxRef);
            await onKeyDown_SUU(inputRef, inputNxRef);
            break;
          default:
            break;
        }
        // inputNxRef.current.focus();   //次の項目に遷移
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  }; 

  // シノプス情報初期化　　　　　　　　　　　　　　←ここから
  const clear_sinops = () => {
    setJdhttst('-');  // 自動発注状態
    setJdhttstcd('');  // 自動発注状態コード
    setShnrank('-');  // 商品ランク
    setKjn_zai('-');  // 基準在庫
    setLowhttsuu('-');  // 最低発注数
    setCal_zai('-');  // 計算在庫
    setJdhttsuu('-');  // 自動発注数
  }//　　　　　　　　　　　　　　　　　　　　　　　←ここまで

  // データセット（2段 JAN1時）
  function setRow_Two_JAN1 (row, jan1cd) {
    row.IRYO_FLG = '1';                        // 衣料フラグ
    row.JANCDE = inJAN1.current.value;         // ＪＡＮコード
    //row.EOSCDE = jancde;         // ＥＯＳコード
    if(jan1cd === '22') {
      row.IN_CD_KBN = '3'                      // 入力コード区分
    } else {
      row.IN_CD_KBN = '4'                      // 入力コード区分
      row.EOSCDE = inJAN1.current.value;         // ＥＯＳコード
    }
    setRowItem(row);
  }

  // JAN1入力後、数量が全選択状態にならないため、
  // 数量の描画完了後にフォーカス移動させる必要がある
  const [nextActive, setNextActive] = useState(null);
  useEffect(() => {
    if (nextActive === 'SUU') {
      inSUU.current.focus();
      //setTimeout(() => {inSUU.current.select();},100);
      onNextFocus(inSUU);
      setNextActive('');
    }
  }, [rowItem.SUU]);

  const onChangeJAN1 = async (e) => {
    const row = JSON.parse(JSON.stringify(rowItem));
    row.JANCDE = e.target.value;
    row.IN_CD_KBN = '';
    setRowItem(row);
    if (e.target.value.length === 0 || e.target.value.length === 1)
    {
      setStartTime(performance.now()); //詳細なミリ秒数
      console.log(`計測スタート:${e.target.value.length} / ${StartTime}`);
      setChkJAN1('0');
      await setOver8(0);
      setScanflg(0);
    }else
    {
      EndTime = performance.now(); //詳細なミリ秒数
      console.log('data:'+e.target.value+'|'+e.target.defaultValue);
      let elapsedTime = (EndTime - StartTime) * 1000; // マイクロ秒単位に変換
      if (isNaN(elapsedTime))
      {
        console.log(`NaN理由: ${StartTime} / ${Math.floor(EndTime)} マイクロ秒`);
      }else{
        console.log(`経過時間: ${e.target.value.length} / ${Math.floor(elapsedTime)} マイクロ秒`);
      }
      //6桁時スキャン使用判定する(400ミリ秒で判定)
      if (e.target.value.length === 5)
      {
        if (elapsedTime<400000)
        {
          console.log(`５桁スキャン判定`);
          setScanflg(1);
        }
      }
      
      if (e.target.value.length === 8 && Scanflg ===1)
      {
        setOver8(1);
        console.log(`８桁処理`);
      }
      if (e.target.value.length === 9 && Scanflg ===1)
      {
        console.log(`８桁解除`);
        setOver8(0);
      }
      if (e.target.value.length === 13)
      {
        setOver8(0);
        setScanflg(0);
        console.log(`１３桁処理開始: ${Math.floor(elapsedTime)} マイクロ秒`);
        // if (elapsedTime<1000000 && e.target.value !== e.target.defaultValue)
        // {
          onKeyDown_JAN1(inJAN1,inSUU);
        // }
      }
    }
  }

  const onChangeJAN2 = async (e) => {
    const row = JSON.parse(JSON.stringify(rowItem));
    row.JANCDE2 = e.target.value;
    // row.IN_CD_KBN = '';
    setRowItem(row);
    if (e.target.value.length === 0 || e.target.value.length === 1)
    {
      setStartTime(performance.now()); //詳細なミリ秒数
      console.log(`計測スタート:${e.target.value.length} / ${StartTime}`);
      setChkJAN2('0');
      await setOver8(0);
      setScanflg(0);
    }else{
      EndTime = performance.now(); //詳細なミリ秒数
      let elapsedTime = (EndTime - StartTime) * 1000; // マイクロ秒単位に変換
      if (isNaN(elapsedTime))
      {
        console.log(`NaN理由: ${StartTime} / ${Math.floor(EndTime)} マイクロ秒`);
      }else{
        console.log(`経過時間: ${e.target.value.length} / ${Math.floor(elapsedTime)} マイクロ秒`);
      }
      //6桁時スキャン使用判定する(400ミリ秒で判定)
      if (e.target.value.length === 5)
      {
        if (elapsedTime<400000)
        {
          console.log(`５桁スキャン判定`);
          setScanflg(1);
        }
      }
      
      if (e.target.value.length === 8 && Scanflg ===1)
      {
        await setOver8(1);
        console.log(`８桁処理2`);
      }
      if (e.target.value.length === 9 && Scanflg ===1)
      {
        console.log(`８桁解除2`);
        await setOver8(0);
      }
      if (e.target.value.length === 13)
      {
        setOver8(0);
        setScanflg(0);
        console.log(`１３桁処理開始2: ${Math.floor(elapsedTime)} マイクロ秒`);
        // if (elapsedTime<1000000)
        // {
          await onKeyDown_JAN2(inJAN1,inSUU);
        // }
      }
    }
  }

  // ＪＡＮ１入力
  const onKeyDown_JAN1 = async(inputRef, inputNxRef) => {
    let errflg = 0;
    setChkJAN1('1');
    //S SK-0053-004 2024/7/29           TCI)M.K【衣料バグFIX】
    // クリア
    setJAN1clr('#FFFFFF');
    //E SK-0053-004
    clear_sinops();
    //S SK-0073-002 2024/9/20           TCI)M.K【内部検証：バグFIX２】
    // 実績のカレンダーヘッダ部を初期化
    jHeaderSet(0);
    setJBtnName('前々週');
    setJBtnName2('前週');
    //E SK-0073-002
    const row = await JSON.parse(JSON.stringify(rowItem));
    const htt = await JSON.parse(JSON.stringify(httItem));
    const htj = await JSON.parse(JSON.stringify(htjItem));
    let Jancde = '';
    // ＪＡＮ１入力値の前0詰め
    // Jancde = row.JANCDE;
    Jancde = inJAN1.current.value
    if(Jancde.length === 7){
      row.JANCDE = Jancde.padStart(8, '0');
      Jancde = Jancde.padStart(8, '0');
    }
    if(9 <= Jancde.length && Jancde.length <= 12){
      row.Jancde = Jancde.padStart(13, '0');
      Jancde = Jancde.padStart(13, '0');
    }
    console.log('スキャンコード'+Jancde);

    // 2段判定
    //const jan1cd = inputRef.current.value.substring(0, 2);
    const jan1cd = Jancde.substring(0, 2);

    if (Jancde.length == 13 && (
          (jan1cd === '22' ||jan1cd === '23')
        ||((jan1cd === '21' ||jan1cd === '22') && state.KSACDES ==='005')
    )){
      await setRow_Two_JAN1 (row,jan1cd);

      //次の項目に遷移
      if (disJan2 === false) {
        // 活性時の場合はuseEffectは発生しない
        setJAN1clr('#FFFFFF');
        inJAN2.current.focus();
        inJAN2.current.select();
      } else {
        // 非活性時は、活性化の描画完了後（useEffect）にフォーカス移動させる必要がある
        setNextActive(inJAN2.current.id);
        setDisJan2(false);
        setJAN2clr('#FFFFFF');
      }
      return;
    }
    if(!(jan1cd === '21' || jan1cd === '22' || jan1cd === '23')){
      setDisJan2(true);
      setJAN2clr('#CCCCCC');
      row.JANCDE2 = '';
    }

    //コード体系チェック
    if (Jancde.length == 13 && (
          ((jan1cd === '28' ||jan1cd === '29') && state.KSACDES ==='001')
        ||((jan1cd === '26' ||jan1cd === '27') && state.KSACDES ==='005')
    )){
      setJAN1clr('#f59d9d');
      inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('入力されたコードに誤りが有ります'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return;
    }

    // 衣料品事業部配下分類かどうかを確認
    // 衣料品フラグ = １ の場合
    if(state.IRYOFLG === '1') {
      row.IRYO_FLG = '1';
    } else {
      row.IRYO_FLG = '0';
    }

    let shohinMaster = undefined;
    // 8桁の場合、商品マスタ（基本）にEOSとしてデータが存在するかチェック。
    if(Jancde.length === 8){
      console.log('８桁検索開始:%o',row);
      const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({row:rowItem, EOSCDE:Jancde })});
      shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '2';
        rowItem.IN_CD_KBN = '2';
        htt.HTT_CDE = '2';
      }
    };

    // 8、12、13 桁の場合、商品マスタ（基本）にJANとしてデータが存在するかチェック。
    if(row.IN_CD_KBN !== '2' &&
      (Jancde.length === 8 || Jancde.length === 12 || Jancde.length === 13)) {
        console.log('８桁以外検索:%o',row);
        console.log('jancde:'+Jancde);
        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_JanCode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:rowItem, JANCDEX:Jancde })});
        shohinMaster = await getShohinMaster.json();
        console.log('shohinMaster:%o',shohinMaster);

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '1';
        rowItem.IN_CD_KBN = '1';
        htt.HTT_CDE = '1';
      }
    }

    if(!shohinMaster) {
      setJAN1clr('#f59d9d');
      inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('入力されたコードに誤りが有ります'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return;
    }

    // 仕入先名称取得
    if (shohinMaster.ret.length !== 0) {
      row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
      const getSirmeik = await fetch('/TCSH0101/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
      const sirmeik = await getSirmeik.json();
      if(sirmeik !== null) {
        row.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
        htt.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
      }
    }

    // ＪＡＮ１チェック
    // (1)
    if(shohinMaster.ret.length === 0) {
      setJAN1clr('#f59d9d');
      inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('入力されたコードに誤りが有ります'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return;
    }

    // (7)事業部コードチェック
    if(state.BMNCDE  === '3699'){
      // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
      // 部門名取得
      const getItemName = await fetch('/TCSH0101/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
      const j_ItemName = await getItemName.json();
      console.log('(1)3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
      if(state.GRPCDE !== j_ItemName.GRPCDE){
        errflg = -1
      }
    }else{
      console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[0].BMNCDE);
      if(state.BMNCDE  !== shohinMaster.ret[0].BMNCDE) {
        errflg = -1
      }  
    }
    console.log('errflg%o',errflg);
    if (errflg !== 0)
    {
      setJAN1clr('#f59d9d');
      inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('入力されたＪＡＮ１は部門が異なります'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return;
    }else{
      setJAN1clr('#FFFFFF');
    }
    
    // ３）便データ初期値チェック
    // ①住居部門 (グループコード35）は便が無い為、"1"を移送し、初期値セット処理を抜ける。
    if (state.GRPCDE==='35'){
      setBin('1')               //デフォルト1を移送
      setDisBin(true)           //便をロックする
      onKeyDown_BIN(inBIN,inBIN);
      inSUU.current.focus();    //発注入力欄をフォーカスする
    }
 
    // ②発注データを検索し、先日付での発注予約が無いか確認する。
    // ③「便」欄への初期値のセット
    const gethttdat = await fetch('/TCSH0101/SH_HTT_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE:state.KSACDES
        ,MISE:state.MISES
        ,SHNID:shohinMaster.ret[0].SHNID
        ,HTTYMD:state.HTTYMD
        ,HTT_KBN:httkbn
      })});
    const httdat = await gethttdat.json();
    if(httdat.CNT !== 0) {
      // console.log('発注したデータゲット:',httdat.ret[0].BIN)
      switch(httdat.ret[0].BIN)
      {
        case '1':
          if (shohinMaster.ret[0].EOSCDE2 !== ''){setBin('2');}
          else if (shohinMaster.ret[0].EOSCDE3 !== ''){setBin('3');}
          else if (shohinMaster.ret[0].EOSCDE4 !== ''){setBin('4');}
          else{setBin('1');}
          break; 
        case '2':
          if (shohinMaster.ret[0].EOSCDE3 !== ''){setBin('3');}
          else if (shohinMaster.ret[0].EOSCDE4 !== ''){setBin('4');}
          else{setBin('2');}
          break;
        case '3':
          if (shohinMaster.ret[0].EOSCDE4 !== ''){setBin('4');}
          else{setBin('3');}
          break;
        case '4':
          setBin('4');  //一旦そのまま
          break;
        default:
          break;
      };
      setSuu(httdat.ret[0].HTTSUU);     // 発注数移送
      //S SK-0060-013 2024/8/6           TCI)M.K【課題No.84：何もしないでEnterはキャンセル扱い】
      setGetSuu(httdat.ret[0].HTTSUU);
      //E SK-0060-013
    }else{
      if (shohinMaster.ret[0].EOSCDE1 !== ''){setBin('1');}
      else if (shohinMaster.ret[0].EOSCDE2 !== ''){setBin('2');}
      else if (shohinMaster.ret[0].EOSCDE3 !== ''){setBin('3');}
      else if (shohinMaster.ret[0].EOSCDE4 !== ''){setBin('4');}
    }

    // データセット
    await setRow_One(row,shohinMaster.ret[0],Jancde);
    await setRow_Two(htt,shohinMaster.ret[0],Jancde);
    //await setRowItem(row);

    // データセット（2）
    const getJdhdat = await fetch('/TCSH0101/getJdhdat',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE:state.KSACDES
        ,MISE:state.MISES
        ,BMNCDE:shohinMaster.ret[0].BMNCDE
        ,SHNID:shohinMaster.ret[0].SHNID
      })});
    const Jdhdat = await getJdhdat.json();
    if(Jdhdat.CNT !== 0) {
      console.log(Jdhdat);
      setShnrank(Jdhdat.ret[0].SHNRANK);                  // 商品ランク
      setJdhttsuu(Number(Jdhdat.ret[0].JDHTTSUU));        // 自動発注数
      if(Number(Jdhdat.ret[0].HENYMD) === 0) {
        console.log('変更日=0:'+Jdhdat.ret[0].HENYMD);
        setJdhttstcd(Jdhdat.ret[0].JDHTTST);                // 自動発注状態
        switch(Jdhdat.ret[0].JDHTTST)     // 自動発注状態01:対象　02:対象外 03:店舗停止 04:本部停止 　
        {
          case '01':
            setJdhttst('対象');
            break; 
          case '02':
            setJdhttst('対象外');
            break;
          case '03':
            setJdhttst('店舗停止');
            break;
          case '04':
            setJdhttst('本部停止');
            break;
          case '10':
            setJdhttst('POS');
            break;
          case '11':
            setJdhttst('EOS');
            break;
          default:
            break;
        };
        setKjn_zai(Number(Jdhdat.ret[0].KJN_ZAI));          // 基準在庫
        setLowhttsuu(Number(Jdhdat.ret[0].LOWHTTSUU));      // 最低発注数
        setCal_zai(Number(Jdhdat.ret[0].CAL_ZAI));          // 店舗在庫
      }else{
        console.log('変更日≠0:'+Jdhdat.ret[0].HENYMD);
        setJdhttstcd(Jdhdat.ret[0].HEN_JDHTTST);                // 自動発注状態
        switch(Jdhdat.ret[0].HEN_JDHTTST)     // 自動発注状態01:対象　02:対象外 03:店舗停止 04:本部停止 　
        {
          case '01':
            setJdhttst('対象');
            break; 
          case '02':
            setJdhttst('対象外');
            break;
          case '03':
            setJdhttst('店舗停止');
            break;
          case '04':
            setJdhttst('本部停止');
            break;
          case '10':
            setJdhttst('POS');
            break;
          case '11':
            setJdhttst('EOS');
            break;
          default:
            break;
        };
        setKjn_zai(Number(Jdhdat.ret[0].HEN_KJN_ZAI));          // 基準在庫
        setLowhttsuu(Number(Jdhdat.ret[0].HEN_LOWHTTSUU));      // 最低発注数
        //S SK-0051-007 2024/7/25           TCI)M.K【課題No.77：在庫以外の修正は在庫をリアルタイム更新】
        // setCal_zai(Number(Jdhdat.ret[0].HEN_CAL_ZAI));          // 店舗在庫
        if(Jdhdat.ret[0].HEN_CAL_ZAI !== ''){
          if(Jdhdat.ret[0].CAL_ZAI_ENTTIM !== ''){
            setCal_zai(Number(Jdhdat.ret[0].HEN_CAL_ZAI));          // 店舗在庫
          }else{
            setCal_zai(Number(Jdhdat.ret[0].CAL_ZAI));          // 店舗在庫
          }
        }
        //E SK-0051-007
      }
    }
    
    // グリッド表示（値を埋め込む）★後で調整
    let strYYYMMDD1 =state.HTTYMD;
    let strYYYMMDD2 ='';
    let strYYYMMDD3 ='';
    let strYYYMMDD4 ='';
    let strYYYMMDD5 ='';
    let strYYYMMDD6 ='';
    let strYYYMMDD7 ='';
    let Y_1_1,Y_1_2,Y_1_3,Y_1_4,Y_1_5,Y_1_6,Y_1_7 ='';  // １便
    let Y_2_1,Y_2_2,Y_2_3,Y_2_4,Y_2_5,Y_2_6,Y_2_7 ='';  // ２便
    let Y_3_1,Y_3_2,Y_3_3,Y_3_4,Y_3_5,Y_3_6,Y_3_7 ='';  // ３便
    let Y_4_1,Y_4_2,Y_4_3,Y_4_4,Y_4_5,Y_4_6,Y_4_7 ='';  // ４便
    
    // 日付分解
    let yyyy = String(state.HTTYMD).substring(0,4);
    let mm = String(state.HTTYMD).substring(4,6)-1;
    let dd = String(state.HTTYMD).substring(6,8);
    let ndate = new Date(yyyy,mm,dd), // 基準となる日付は使いまわす
    addDate = 1, // 足す日数
    max = 6; // 何回繰り返すか
    
    for (var i = 1; i <= max; i++) {
      ndate.setDate(ndate.getDate() + addDate);
      let nyyyy = ndate.getFullYear();                       //2022
      let nmm = ('0'+(ndate.getMonth()+1)).slice(-2);        //02
      let ndd = ('0'+ndate.getDate()).slice(-2);             //05
      // console.log(nyyyy+nmm+ndd)
      if (i===1) { strYYYMMDD2 =nyyyy+nmm+ndd;}
      else if(i===2){strYYYMMDD3 =nyyyy+nmm+ndd;}
      else if(i===3){strYYYMMDD4 =nyyyy+nmm+ndd;}
      else if(i===4){strYYYMMDD5 =nyyyy+nmm+ndd;}
      else if(i===5){strYYYMMDD6 =nyyyy+nmm+ndd;}
      else if(i===6){strYYYMMDD7 =nyyyy+nmm+ndd;}
    }      

    // 天気セット
    // Y_t_1 = `/tenki_icon/hare.gif`;
    const{str1:Y_t_1,str2:Y_k_1,str3:Y_o_1} = await Tnk_Set(strYYYMMDD1);
    const{str1:Y_t_2,str2:Y_k_2,str3:Y_o_2} = await Tnk_Set(strYYYMMDD2);
    const{str1:Y_t_3,str2:Y_k_3,str3:Y_o_3} = await Tnk_Set(strYYYMMDD3);
    const{str1:Y_t_4,str2:Y_k_4,str3:Y_o_4} = await Tnk_Set(strYYYMMDD4);
    const{str1:Y_t_5,str2:Y_k_5,str3:Y_o_5} = await Tnk_Set(strYYYMMDD5);
    const{str1:Y_t_6,str2:Y_k_6,str3:Y_o_6} = await Tnk_Set(strYYYMMDD6);
    const{str1:Y_t_7,str2:Y_k_7,str3:Y_o_7} = await Tnk_Set(strYYYMMDD7);

    let sJAN = '';
    if (htt.HTT_CDE === '2')
    {
      sJAN = htt.EOSCDE;
    }else{
      sJAN = htt.JANCDE;
    }

    const gethtjdat = await fetch('/TCSH0101/SH_HTJ_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({htt:httItem ,NHNYMD2:strYYYMMDD7,JANCDE:sJAN,httkbn:httkbn})});
    const htjdat = await gethtjdat.json();
    if(htjdat.CNT !== 0) {
      console.log('発注予約データget:%o',htjdat);
      for(const key in htjdat.ret) {
        if(htjdat.ret[key].BIN==='1'){
          if(htjdat.ret[key].NHNYMD===strYYYMMDD1){Y_1_1=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD2){Y_1_2=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD3){Y_1_3=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD4){Y_1_4=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD5){Y_1_5=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD6){Y_1_6=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD7){Y_1_7=htjdat.ret[key].HTTSUU;}
        }else if(htjdat.ret[key].BIN==='2'){
          if(htjdat.ret[key].NHNYMD===strYYYMMDD1){Y_2_1=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD2){Y_2_2=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD3){Y_2_3=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD4){Y_2_4=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD5){Y_2_5=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD6){Y_2_6=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD7){Y_2_7=htjdat.ret[key].HTTSUU;}
        }else if(htjdat.ret[key].BIN==='3'){
          if(htjdat.ret[key].NHNYMD===strYYYMMDD1){Y_3_1=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD2){Y_3_2=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD3){Y_3_3=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD4){Y_3_4=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD5){Y_3_5=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD6){Y_3_6=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD7){Y_3_7=htjdat.ret[key].HTTSUU;}
        }else if(htjdat.ret[key].BIN==='4'){
          if(htjdat.ret[key].NHNYMD===strYYYMMDD1){Y_4_1=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD2){Y_4_2=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD3){Y_4_3=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD4){Y_4_4=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD5){Y_4_5=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD6){Y_4_6=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD7){Y_4_7=htjdat.ret[key].HTTSUU;}
        }
        // 発注予約メモリに保存する ※この時点で便が確定していない為使えないので一旦コメント
        // htj.NHNYMD = htjdat.ret[key].NHNYMD;
        // htj.JANCDE = htjdat.ret[key].JANCDE;
        // htj.EOSCDE = htjdat.ret[key].EOSCDE;
        // htj.BIN = htjdat.ret[key].BIN;
        // htj.HTTYMD = htjdat.ret[key].HTTYMD;
        // htj.HTTKBN = htjdat.ret[key].HTTKBN;
        // htj.HTTSUU = htjdat.ret[key].HTTSUU;
        // htj.SSIKBN = htjdat.ret[key].SSIKBN;
        // htj.SECYMD = htjdat.ret[key].SECYMD;
        // htj.SECTIM = htjdat.ret[key].SECTIM;
        // setHtjItem(htj)
      }
      // console.log('発注予約データ格納:',htj)
    }

    const newRows = [];
    newRows.push(
      {id: '1', field0: '天候', field1: Y_t_1, field2: Y_t_2, field3: Y_t_3, field4: Y_t_4, field5: Y_t_5,field6: Y_t_6,field7: Y_t_7,}
      ,{id: '2', field0: '体感', field1: Y_k_1, field2: Y_k_2, field3: Y_k_3, field4: Y_k_4, field5: Y_k_5,field6: Y_k_6,field7: Y_k_7,}
      ,{id: '3', field0: '温度', field1: Y_o_1, field2: Y_o_2, field3: Y_o_3, field4: Y_o_4, field5: Y_o_5,field6: Y_o_6,field7: Y_o_7,}
      ,{id: '4', field0: '１便', field1: Y_1_1, field2: Y_1_2, field3: Y_1_3, field4: Y_1_4, field5: Y_1_5,field6: Y_1_6,field7: Y_1_7,}
      ,{id: '5', field0: '２便', field1: Y_2_1, field2: Y_2_2, field3: Y_2_3, field4: Y_2_4, field5: Y_2_5,field6: Y_2_6,field7: Y_2_7,}
      ,{id: '6', field0: '３便', field1: Y_3_1, field2: Y_3_2, field3: Y_3_3, field4: Y_3_4, field5: Y_3_5,field6: Y_3_6,field7: Y_3_7,}
      ,{id: '7', field0: '４便', field1: Y_4_1, field2: Y_4_2, field3: Y_4_3, field4: Y_4_4, field5: Y_4_5,field6: Y_4_6,field7: Y_4_7,}
      );
    setRows(newRows);
    // console.log(rows)
    
    // ここから実績表示
    let J_1_1,J_1_2,J_1_3,J_1_4,J_1_5,J_1_6,J_1_7 ='';  // 規格
    let J_2_1,J_2_2,J_2_3,J_2_4,J_2_5,J_2_6,J_2_7 ='';  // 売価
    let J_3_1,J_3_2,J_3_3,J_3_4,J_3_5,J_3_6,J_3_7 ='';  // 入荷
    let J_4_1,J_4_2,J_4_3,J_4_4,J_4_5,J_4_6,J_4_7 ='';  // 正販
    let J_5_1,J_5_2,J_5_3,J_5_4,J_5_5,J_5_6,J_5_7 ='';  // 見切
    let J_6_1,J_6_2,J_6_3,J_6_4,J_6_5,J_6_6,J_6_7 ='';  // 廃棄
    //S SK-0008-053 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
    // 初期化
    J_2_1='-';
    J_2_2='-';
    J_2_3='-';
    J_2_4='-';
    J_2_5='-';
    J_2_6='-';
    J_2_7='-';
    //S SK-0024-005 2024/6/27           TCI)M.K【課題No.37：過去実績の入荷から廃棄は「０」表示】
    // J_3_1 ='-';
    // J_3_2 ='-';
    // J_3_3 ='-';
    // J_3_4 ='-';
    // J_3_5 ='-';
    // J_3_6 ='-';
    // J_3_7 ='-';
    // J_4_1 ='-';
    // J_4_2 ='-';
    // J_4_3 ='-';
    // J_4_4 ='-';
    // J_4_5 ='-';
    // J_4_6 ='-';
    // J_4_7 ='-';
    // J_5_1 ='-';
    // J_5_2 ='-';
    // J_5_3 ='-';
    // J_5_4 ='-';
    // J_5_5 ='-';
    // J_5_6 ='-';
    // J_5_7 ='-';
    // J_6_1 ='-';
    // J_6_2 ='-';
    // J_6_3 ='-';
    // J_6_4 ='-';
    // J_6_5 ='-';
    // J_6_6 ='-';
    // J_6_7 ='-';    
    // //E SK-0008-053
    J_3_1 ='0';
    J_3_2 ='0';
    J_3_3 ='0';
    J_3_4 ='0';
    J_3_5 ='0';
    J_3_6 ='0';
    J_3_7 ='0';
    J_4_1 ='0';
    J_4_2 ='0';
    J_4_3 ='0';
    J_4_4 ='0';
    J_4_5 ='0';
    J_4_6 ='0';
    J_4_7 ='0';
    J_5_1 ='0';
    J_5_2 ='0';
    J_5_3 ='0';
    J_5_4 ='0';
    J_5_5 ='0';
    J_5_6 ='0';
    J_5_7 ='0';
    J_6_1 ='0';
    J_6_2 ='0';
    J_6_3 ='0';
    J_6_4 ='0';
    J_6_5 ='0';
    J_6_6 ='0';
    J_6_7 ='0';
    //E SK-0024-005
    // 日付分解
    let sWeekMonday =await formedDateOfThisWeek(7);
    let ThisWeekMonday = new Date(sWeekMonday);
    console.log('実績日付分解開始:',ThisWeekMonday)
    // 日付計算がおかしい為作り直し↓↓ここから↓↓
    // yyyy = ThisWeekMonday.getFullYear();               //2022
    // mm = ('0'+(ThisWeekMonday.getMonth()+1)).slice(-2);              //2
    // dd = ('0'+ThisWeekMonday.getDate()).slice(-2);                //5    
    // let jdate = new Date(yyyy,mm,dd), 
    // jaddDate = 1, // 足す日数
    // jmax = 6; // 何回繰り返すか
    // console.log(jdate);
    // strYYYMMDD1=yyyy+mm+dd;
    // for (i = 1; i <= jmax; i++) {
    //   jdate.setDate(jdate.getDate() + jaddDate);
    //   let yyyy = jdate.getFullYear();                       //2022
    //   let mm = ('0'+(jdate.getMonth())).slice(-2);        //02
    //   let dd = ('0'+jdate.getDate()).slice(-2);             //05
    //   console.log(jdate);
    //   console.log(yyyy+mm+dd);
    //   if (i===1) { strYYYMMDD2 =yyyy+mm+dd;}
    //   else if(i===2){strYYYMMDD3 =yyyy+mm+dd;}
    //   else if(i===3){strYYYMMDD4 =yyyy+mm+dd;}
    //   else if(i===4){strYYYMMDD5 =yyyy+mm+dd;}
    //   else if(i===5){strYYYMMDD6 =yyyy+mm+dd;}
    //   else if(i===6){strYYYMMDD7 =yyyy+mm+dd;}
    // }     
    var jdate = new Date(ThisWeekMonday), // 基準となる日付は使いまわす
    jaddDate = 1, // 足す日数
    jmax = 6; // 何回繰り返すか
    let wkweekdate = '';
    let yyyymmdd = '';
    wkweekdate = jdate.getMonth() + 1 + '/' + jdate.getDate() +'(' + jdate + ')';
    // setJDay1(wkweekdate);
    yyyymmdd = jdate.getFullYear() + ('0'+(jdate.getMonth()+1)).slice(-2) + ('0'+jdate.getDate()).slice(-2);
    strYYYMMDD1 =yyyymmdd;
    // console.log(yyyymmdd);
    for (i = 1; i <= jmax; i++) {
      jdate.setDate(jdate.getDate() + jaddDate);
      yyyymmdd = jdate.getFullYear() + ('0'+(jdate.getMonth()+1)).slice(-2) + ('0'+jdate.getDate()).slice(-2);
      // console.log(jdate);
      // console.log(yyyymmdd);
      if (i===1) { strYYYMMDD2 =yyyymmdd;}
      else if(i===2){strYYYMMDD3 =yyyymmdd;}
      else if(i===3){strYYYMMDD4 =yyyymmdd;}
      else if(i===4){strYYYMMDD5 =yyyymmdd;}
      else if(i===5){strYYYMMDD6 =yyyymmdd;}
      else if(i===6){strYYYMMDD7 =yyyymmdd;}
    }   
    // 日付計算がおかしい為作り直し↑↑ここまで↑↑

    // 天気セット
    // Y_t_1 = `/tenki_icon/hare.gif`;
    const{str1:J_t_1,str2:J_k_1,str3:J_o_1} = await Tnk_Set(strYYYMMDD1);
    const{str1:J_t_2,str2:J_k_2,str3:J_o_2} = await Tnk_Set(strYYYMMDD2);
    const{str1:J_t_3,str2:J_k_3,str3:J_o_3} = await Tnk_Set(strYYYMMDD3);
    const{str1:J_t_4,str2:J_k_4,str3:J_o_4} = await Tnk_Set(strYYYMMDD4);
    const{str1:J_t_5,str2:J_k_5,str3:J_o_5} = await Tnk_Set(strYYYMMDD5);
    const{str1:J_t_6,str2:J_k_6,str3:J_o_6} = await Tnk_Set(strYYYMMDD6);
    const{str1:J_t_7,str2:J_k_7,str3:J_o_7} = await Tnk_Set(strYYYMMDD7);

    const getkkjdat = await fetch('/TCSH0101/SH_KKJ_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    //S SK-0054-008 2024/7/30           TCI)M.K【過去実績レスポンス対応】
    // body: JSON.stringify({htt:httItem,NHNYMD1:strYYYMMDD1,NHNYMD2:strYYYMMDD7,JANCDE:sJAN})});
    body: JSON.stringify({
      htt:httItem,
      NHNYMD1:strYYYMMDD1,
      NHNYMD2:strYYYMMDD7,
      JANCDE:sJAN,
      SHNID:shohinMaster.ret[0].SHNID,
    })});
    //E SK-0054-008
    const kkjdat = await getkkjdat.json();
    if(kkjdat.CNT !== 0) {
      console.log('過去実績データget:%o',kkjdat);
      for(const key in kkjdat.ret) {
        if(kkjdat.ret[key].NHNYMD===strYYYMMDD1){
          J_1_1=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-029 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_1=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_1=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-029
          J_3_1=kkjdat.ret[key].NYUKA;
          J_4_1=kkjdat.ret[key].SEIHAN;
          J_5_1=kkjdat.ret[key].MIKIRI;
          J_6_1=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD2){
          J_1_2=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-030 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_2=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_2=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-030
          J_3_2=kkjdat.ret[key].NYUKA;
          J_4_2=kkjdat.ret[key].SEIHAN;
          J_5_2=kkjdat.ret[key].MIKIRI;
          J_6_2=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD3){
          J_1_3=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-031 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_3=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_3=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-031
          J_3_3=kkjdat.ret[key].NYUKA;
          J_4_3=kkjdat.ret[key].SEIHAN;
          J_5_3=kkjdat.ret[key].MIKIRI;
          J_6_3=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD4){
          J_1_4=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-032 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_4=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_4=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-032
          J_3_4=kkjdat.ret[key].NYUKA;
          J_4_4=kkjdat.ret[key].SEIHAN;
          J_5_4=kkjdat.ret[key].MIKIRI;
          J_6_4=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD5){
          J_1_5=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-033 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_5=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_5=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-033
          J_3_5=kkjdat.ret[key].NYUKA;
          J_4_5=kkjdat.ret[key].SEIHAN;
          J_5_5=kkjdat.ret[key].MIKIRI;
          J_6_5=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD6){
          J_1_6=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-034 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_6=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_6=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-034
          J_3_6=kkjdat.ret[key].NYUKA;
          J_4_6=kkjdat.ret[key].SEIHAN;
          J_5_6=kkjdat.ret[key].MIKIRI;
          J_6_6=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD7){
          J_1_7=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-035 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_7=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_7=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-035
          J_3_7=kkjdat.ret[key].NYUKA;
          J_4_7=kkjdat.ret[key].SEIHAN;
          J_5_7=kkjdat.ret[key].MIKIRI;
          J_6_7=kkjdat.ret[key].HAIKI;
        }
      }
    }

    const newRows2 = [];
    newRows2.push(
      {id: '1', field0: '天候', field1: J_t_1, field2: J_t_2, field3: J_t_3, field4: J_t_4, field5: J_t_5,field6: J_t_6,field7: J_t_7,}
      ,{id: '2', field0: '体感', field1: J_k_1, field2: J_k_2, field3: J_k_3, field4: J_k_4, field5: J_k_5,field6: J_k_6,field7: J_k_7,}
      ,{id: '3', field0: '温度', field1: J_o_1, field2: J_o_2, field3: J_o_3, field4: J_o_4, field5: J_o_5,field6: J_o_6,field7: J_o_7,}
      ,{id: '4', field0: '企画', field1:J_1_1, field2:J_1_2, field3:J_1_3, field4:J_1_4, field5:J_1_5,field6:J_1_6,field7:J_1_7,}
      ,{id: '5', field0: '売価', field1:J_2_1, field2:J_2_2, field3:J_2_3, field4:J_2_4, field5:J_2_5,field6:J_2_6,field7:J_2_7,}
      ,{id: '6', field0: '入荷', field1:J_3_1, field2:J_3_2, field3:J_3_3, field4:J_3_4, field5:J_3_5,field6:J_3_6,field7:J_3_7,}
      ,{id: '7', field0: '正販', field1:J_4_1, field2:J_4_2, field3:J_4_3, field4:J_4_4, field5:J_4_5,field6:J_4_6,field7:J_4_7,}
      ,{id: '8', field0: '見切', field1:J_5_1, field2:J_5_2, field3:J_5_3, field4:J_5_4, field5:J_5_5,field6:J_5_6,field7:J_5_7,}
      ,{id: '9', field0: '廃棄', field1:J_6_1, field2:J_6_2, field3:J_6_3, field4:J_6_4, field5:J_6_5,field6:J_6_6,field7:J_6_7,}
      );
    setRows2(newRows2);
    // console.log(rows2)

    // 2023/10/11
    // setDisRow(false);
    //次の項目に遷移
    //inputNxRef.current.focus();
    // if (inputNxRef.current.value === '1.0') {
    //   // 表示が変わらない場合はuseEffectは発生しない
    //   inputNxRef.current.focus();
    // } else {
    //   if (inputNxRef.current.id === 'SUU') {
    //     // 表示が変わるときは、数量の描画完了後（useEffect）にフォーカス移動させる必要がある
    //     setNextActive(inputNxRef.current.id);
    //   } else {
    //     // 数量以外へのフォーカス移動は問題なし
    //     inputNxRef.current.focus();
    //   }
    // }

    // 終了処理
    setChkflg(0);
    if (shohinMaster.ret[0].TRIHBN.trim() !== '')
    {
      setBtnSKU(false);
    }

    const ret = await onKeyDown_BIN2(htt,row);
    if (ret)
    {
      setBin('1');
      inputNxRef.current.focus();
      inputNxRef.current.select();
    }
    else{
      setChkflg(2);
      inJAN1.current.focus();
      inJAN1.current.select();
    }
  };

  // ヘッダ部２入力　ＪＡＮ２（活性時）
  const onKeyDown_JAN2 = async (inputRef, inputNxRef) => {
    setChkJAN2('1');
    let row = JSON.parse(JSON.stringify(rowItem));

    // ＪＡＮ2入力値の前0詰め
    let Jan2cde = inJAN2.current.value;
    if(Jan2cde.length === 7){
      Jan2cde = Jan2cde.padStart(8, '0');
    }
    if(9 <= Jan2cde.length && Jan2cde.length <= 12){
      Jan2cde =Jan2cde.padStart(13, '0');
    }
    row.JANCDE2 = Jan2cde;

    // const jan2cdH = inJAN2.current.value.substring(0, 2);
    const jan2cdH = Jan2cde.substring(0, 2);
    // state.KSACDES = '001'の場合は、前２桁が「28」、「29」以外はエラー
    if(state.KSACDES === '001' && !(jan2cdH === '28' ||jan2cdH === '29')){
      setJAN2clr('#f59d9d');
      window.alert('入力されたコードに誤りが有ります'); //TCGE00XX：
      timeRef.current.reTimer();
      setChkflg(2);
      inputRef.current.select();
      return 1;
    }

    // state.KSACDES = '005'の場合は、前２桁が「26」、「27」以外はエラー
    if(state.KSACDES === '005' && !(jan2cdH === '26' ||jan2cdH === '27')){
      setJAN2clr('#f59d9d');
      window.alert('入力されたコードに誤りが有ります'); //TCGE00XX：
      timeRef.current.reTimer();
      setChkflg(2);
      inputRef.current.select();
      return 1;
    }else
    {
      setJAN2clr('#FFFFFF');
    }

    const htt = await JSON.parse(JSON.stringify(httItem));
    const jan1cd = inJAN1.current.value.substring(0, 2);
    let tagMaster = undefined;
    let shohinMaster = undefined;
    //１）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外もしくは、「２３」の場合
    if((jan1cd ==='22' && state.KSACDES !== '005')|| jan1cd ==='23') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外の場合
      if(jan1cd ==='22' && state.KSACDES !== '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        // const getTagMaster = await fetch('/TCSH0101/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({
        //   KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        // })});
        // tagMaster = await getTagMaster.json();

        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_TagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,BMNCDE:state.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM,TAGSIZCDE:TAGSIZCDE,TAGCOLCDE:TAGCOLCDE
        })});
        shohinMaster = await getShohinMaster.json();
        
        if (shohinMaster.ret.length === 0){
          //S SK-0053-005 2024/7/30           TCI)M.K【衣料バグFIX】
          setJAN1clr('#f59d9d');
          //E SK-0053-005
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          timeRef.current.reTimer();
          setChkflg(2);
          inputRef.current.select();
          return 1;
        }
        // let line = -1;
        // for(const key in shohinMaster.ret) {

        //   if(state.BMNCDE === shohinMaster.ret[key].BMNCDE) {
        //     line = key
        //   }
        // }
        // if (line === -1){
        let errflg = 0;
        // (7)事業部コードチェック
        if(state.BMNCDE  === '3699'){
          // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
          // 部門名取得
          const getItemName = await fetch('/TCSH0101/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
          const j_ItemName = await getItemName.json();
          console.log('(1)3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
          if(state.GRPCDE !== j_ItemName.GRPCDE){
            errflg = 1
          }
        }else{
          console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[0].BMNCDE);
          if(state.BMNCDE  !== shohinMaster.ret[0].BMNCDE) {
            errflg = 1
          }  
        }
        console.log('errflg%o',errflg);
        if (errflg !== 0)
        {
          setJAN2clr('#f59d9d');
          window.alert('入力されたＪＡＮ１は部門が異なります'); 
          timeRef.current.reTimer();
          setChkflg(2);
          inputRef.current.select();
          return errflg;
        }else{
          setJAN2clr('#FFFFFF');
        }
        // setJAN2clr('#f59d9d');
        // window.alert('入力された商品は部門が異なります。'); //TCGE0047：
        // setChkflg(2);
        // return 1;
        // }else{
        row.TAGSIZCDE = shohinMaster.ret[0].TAGSIZCDE;  // サイズコード
        row.TAGSIZMEI = shohinMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        row.TAGCOLCDE = shohinMaster.ret[0].TAGCOLCDE;  // カラーコード
        row.TAGCOLMEI = shohinMaster.ret[0].TAGCOLMEI;  // カラー名カナ
        row.TAGSZNNM = shohinMaster.ret[0].TAGSZNNM;    // タグシーズン名
        // }

        // 仕入先名称取得
        // if (line !== -1) {
        row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
        const getSirmeik = await fetch('/TCSH0101/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
        const sirmeik = await getSirmeik.json();
        if(sirmeik !== null) {
          row.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
          htt.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
        }
        // }
        await setRow_One(row, shohinMaster.ret[0],inJAN1.current.value);
        await setRow_Two(htt, shohinMaster.ret[0],inJAN1.current.value);

        if (shohinMaster.ret[0].TRIHBN.trim() !== '')
        {
          setBtnSKU(false);
        }
      }

      //(2) ＪＡＮ１の前２桁が「２３」の場合
      if(jan1cd ==='23') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('入力されたコードに誤りが有ります'); //TCGE0047：
          timeRef.current.reTimer();
          setChkflg(2);
          inputRef.current.select();
          return 1;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(2, 10);
        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0){
          //S SK-0053-006 2024/7/30           TCI)M.K【衣料バグFIX】
          setJAN1clr('#f59d9d');
          //E SK-0053-006
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          timeRef.current.reTimer();
          setChkflg(2);
          inputRef.current.select();
          return 1;
        }

        row.EOSCDE = EosCode;
        setBtnSKU(false);
        // setRowGnt(row, shohinMaster.ret[0].GNT);          // 原単価
        // setRowBtn(row, shohinMaster.ret[0].BTN);          // 売単価
        //② 返品不可判定
        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCSH0101/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
            htt.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
          }
        }

        let errflg = 0;
        // (7)事業部コードチェック
        if(state.BMNCDE  === '3699'){
          // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
          // 部門名取得
          const getItemName = await fetch('/TCSH0101/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
          const j_ItemName = await getItemName.json();
          console.log('(1)3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
          if(state.GRPCDE !== j_ItemName.GRPCDE){
            errflg = 1
          }
        }else{
          console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[0].BMNCDE);
          if(state.BMNCDE  !== shohinMaster.ret[0].BMNCDE) {
            errflg = 1
          }  
        }
        console.log('errflg%o',errflg);
        if (errflg !== 0)
        {
          setJAN2clr('#f59d9d');
          window.alert('入力されたＪＡＮ１は部門が異なります'); 
          timeRef.current.reTimer();
          setChkflg(2);
          inputRef.current.select();
          return errflg;
        }else{
          setJAN2clr('#FFFFFF');
        }
        row.TAGSIZCDE = shohinMaster.ret[0].TAGSIZCDE;  // サイズコード
        row.TAGSIZMEI = shohinMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        row.TAGCOLCDE = shohinMaster.ret[0].TAGCOLCDE;  // カラーコード
        row.TAGCOLMEI = shohinMaster.ret[0].TAGCOLMEI;  // カラー名カナ
        row.TAGSZNNM = shohinMaster.ret[0].TAGSZNNM;    // タグシーズン名
        // 仕入先名称取得
        // if (line !== -1) {
        row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
        const getSirmeik = await fetch('/TCSH0101/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
        const sirmeik = await getSirmeik.json();
        if(sirmeik !== null) {
          row.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
          htt.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
        }
        // }

        // データセット
        await setRow_One(row,shohinMaster.ret[0],inJAN1.current.value);
        await setRow_Two(htt,shohinMaster.ret[0],EosCode);
        await setRow_Two2(row, shohinMaster.ret[0]);

      }

      // //(3) 計算
      // const taxcde = Number(Jan2cde.substring(5, 6));
      // const jankng = Number(Jan2cde.substring(6, 12));
      // if(jankng !== 0){
      //   //①２段目バーコードの6桁目(税区分)＝0(総額)の場合
      //   if(taxcde === 0){
      //     let tax = (jankng * iryoZei) / (100 + iryoZei)
      //     setRowBtn(row, (jankng - tax));     // 売単価
      //   }

      //   //②２段目バーコードの6桁目(税区分)≠0(総額)の場合
      //   if(taxcde !== 0){
      //     setRowBtn(row, jankng);             // 売単価
      //   }
      // }
      //③２段目バーコードの金額が０の場合。
      //設定済

    }

    //２）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005もしくは、「２１」の場合
    if((jan1cd === '22' && state.KSACDES === '005') || jan1cd ==='21') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005の場合
      if(jan1cd === '22' && state.KSACDES === '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_TagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,BMNCDE:state.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM,TAGSIZCDE:TAGSIZCDE,TAGCOLCDE:TAGCOLCDE
        })});
        shohinMaster = await getShohinMaster.json();
        
        if (shohinMaster.ret.length === 0){
          //S SK-0053-007 2024/7/30           TCI)M.K【衣料バグFIX】
          setJAN1clr('#f59d9d');
          //E SK-0053-007
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          timeRef.current.reTimer();
          setChkflg(2);
          inputRef.current.select();
          return 1;
        }
        // let line = -1;
        // for(const key in shohinMaster.ret) {

        //   if(state.BMNCDE === shohinMaster.ret[key].BMNCDE) {
        //     line = key
        //   }
        // }
        // if (line === -1){
        let errflg = 0;
        // (7)事業部コードチェック
        if(state.BMNCDE  === '3699'){
          // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
          // 部門名取得
          const getItemName = await fetch('/TCSH0101/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
          const j_ItemName = await getItemName.json();
          console.log('(1)3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
          if(state.GRPCDE !== j_ItemName.GRPCDE){
            errflg = 1
          }
        }else{
          console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[0].BMNCDE);
          if(state.BMNCDE  !== shohinMaster.ret[0].BMNCDE) {
            errflg = 1
          }  
        }
        console.log('errflg%o',errflg);
        if (errflg !== 0)
        {
          setJAN2clr('#f59d9d');
          inJAN2.current.focus();   // 2024/06/14 focus対応漏れ
          window.alert('入力されたＪＡＮ１は部門が異なります'); 
          timeRef.current.reTimer();
          
          setChkflg(2);
          inputRef.current.select();
          return errflg;
        }else{
          setJAN2clr('#FFFFFF');
        }
        row.TAGSIZCDE = shohinMaster.ret[0].TAGSIZCDE;  // サイズコード
        row.TAGSIZMEI = shohinMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        row.TAGCOLCDE = shohinMaster.ret[0].TAGCOLCDE;  // カラーコード
        row.TAGCOLMEI = shohinMaster.ret[0].TAGCOLMEI;  // カラー名カナ
        row.TAGSZNNM = shohinMaster.ret[0].TAGSZNNM;    // タグシーズン名
        // 仕入先名称取得
        // if (line !== -1) {
        row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
        const getSirmeik = await fetch('/TCSH0101/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
        const sirmeik = await getSirmeik.json();
        if(sirmeik !== null) {
          row.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
          htt.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
        }
        // }

        setBtnSKU(false);
        await setRow_One(row, shohinMaster.ret[0],inJAN1.current.value);
        await setRow_Two(htt, shohinMaster.ret[0],inJAN1.current.value);

      }
      //(2) ＪＡＮ１の前２桁が「２１」の場合
      if (jan1cd === '21') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('入力されたコードに誤りが有ります'); //TCGE0047：
          timeRef.current.reTimer();
          setChkflg(2);
          inputRef.current.select();
          return 1;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(4, 12);
        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0) {
          //S SK-0053-008 2024/7/30           TCI)M.K【衣料バグFIX】
          setJAN1clr('#f59d9d');
          //E SK-0053-008
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          timeRef.current.reTimer();
          setChkflg(2);
          inputRef.current.select();
          return 1;
        }

        row.EOSCDE = EosCode;
        // setRowGnt(row, shohinMaster.ret[0].GNT);           // 原単価
        // setRowBtn(row, shohinMaster.ret[0].BTN);           // 売単価

        //② 返品不可判定
        if (shohinMaster.ret[0].HPN_FKA_KBN === '1') {
          if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
            setJAN2clr('#f59d9d');
            inJAN1.current.focus();
            setRowItem(row);
            setChkflg(2);
            inJAN1.current.select();
            return 1;
          }
        }

        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCSH0101/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
            htt.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
          }
        }

        setBtnSKU(false);
        await setRow_One(row,shohinMaster.ret[0],inJAN1.current.value);
        await setRow_Two(htt, shohinMaster.ret[0],inJAN1.current.value);
        await setRow_Two2(row, shohinMaster.ret[0]);
      }

      //(3) 計算
      const jankng = Number(Jan2cde.substring(6, 12));
      // setRowBtn(row, jankng);           // 売単価
    }

    let jdh_BMNCDE = '';
    let jdh_SHNID = '';

    if (tagMaster === undefined){
      jdh_BMNCDE = shohinMaster.ret[0].BMNCDE;
      jdh_SHNID = shohinMaster.ret[0].SHNID;
    }else{
    // 別途タグマスター系の処理が必要

    }

    const gethttdat = await fetch('/TCSH0101/SH_HTT_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE:state.KSACDES
        ,MISE:state.MISES
        ,SHNID:shohinMaster.ret[0].SHNID
        ,HTTYMD:state.HTTYMD
        ,HTT_KBN:httkbn
      })});
    const httdat = await gethttdat.json();
    if(httdat.CNT !== 0) {
      // console.log('発注したデータゲット:',httdat.ret[0].BIN)
      switch(httdat.ret[0].BIN)
      {
        case '1':
          if (shohinMaster.ret[0].EOSCDE2 !== ''){setBin('2');}
          else if (shohinMaster.ret[0].EOSCDE3 !== ''){setBin('3');}
          else if (shohinMaster.ret[0].EOSCDE4 !== ''){setBin('4');}
          else{setBin('1');}
          break; 
        case '2':
          if (shohinMaster.ret[0].EOSCDE3 !== ''){setBin('3');}
          else if (shohinMaster.ret[0].EOSCDE4 !== ''){setBin('4');}
          else{setBin('2');}
          break;
        case '3':
          if (shohinMaster.ret[0].EOSCDE4 !== ''){setBin('4');}
          else{setBin('3');}
          break;
        case '4':
          setBin('4');  //一旦そのまま
          break;
        default:
          break;
      };
      setSuu(httdat.ret[0].HTTSUU);     // 発注数移送
      //S SK-0060-014 2024/8/6           TCI)M.K【課題No.84：何もしないでEnterはキャンセル扱い】
      setGetSuu(httdat.ret[0].HTTSUU);
      //E SK-0060-014
    }else{
      if (shohinMaster.ret[0].EOSCDE1 !== ''){setBin('1');}
      else if (shohinMaster.ret[0].EOSCDE2 !== ''){setBin('2');}
      else if (shohinMaster.ret[0].EOSCDE3 !== ''){setBin('3');}
      else if (shohinMaster.ret[0].EOSCDE4 !== ''){setBin('4');}
    }
    
    const getJdhdat = await fetch('/TCSH0101/getJdhdat',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE:state.KSACDES
        ,MISE:state.MISES
        ,BMNCDE:jdh_BMNCDE
        ,SHNID:jdh_SHNID
      })});
    const Jdhdat = await getJdhdat.json();
    if(Jdhdat.CNT !== 0) {
      console.log(Jdhdat);
      setShnrank(Jdhdat.ret[0].SHNRANK);                  // 商品ランク
      setJdhttsuu(Number(Jdhdat.ret[0].JDHTTSUU));        // 自動発注数
      if(Number(Jdhdat.ret[0].HENYMD) === 0) {
        console.log('変更日=0:'+Jdhdat.ret[0].HENYMD);
        setJdhttstcd(Jdhdat.ret[0].JDHTTST);                // 自動発注状態
        switch(Jdhdat.ret[0].JDHTTST)     // 自動発注状態01:対象　02:対象外 03:店舗停止 04:本部停止 　
        {
          case '01':
            setJdhttst('対象');
            break; 
          case '02':
            setJdhttst('対象外');
            break;
          case '03':
            setJdhttst('店舗停止');
            break;
          case '04':
            setJdhttst('本部停止');
            break;
          case '10':
            setJdhttst('POS');
            break;
          case '11':
            setJdhttst('EOS');
            break;
          default:
            break;
        };
        setKjn_zai(Number(Jdhdat.ret[0].KJN_ZAI));          // 基準在庫
        setLowhttsuu(Number(Jdhdat.ret[0].LOWHTTSUU));      // 最低発注数
        setCal_zai(Number(Jdhdat.ret[0].CAL_ZAI));          // 店舗在庫
      }else{
        console.log('変更日≠0:'+Jdhdat.ret[0].HENYMD);
        setJdhttstcd(Jdhdat.ret[0].HEN_JDHTTST);                // 自動発注状態
        switch(Jdhdat.ret[0].HEN_JDHTTST)     // 自動発注状態01:対象　02:対象外 03:店舗停止 04:本部停止 　
        {
          case '01':
            setJdhttst('対象');
            break; 
          case '02':
            setJdhttst('対象外');
            break;
          case '03':
            setJdhttst('店舗停止');
            break;
          case '04':
            setJdhttst('本部停止');
            break;
          case '10':
            setJdhttst('POS');
            break;
          case '11':
            setJdhttst('EOS');
            break;
          default:
            break;
        };
        setKjn_zai(Number(Jdhdat.ret[0].HEN_KJN_ZAI));          // 基準在庫
        setLowhttsuu(Number(Jdhdat.ret[0].HEN_LOWHTTSUU));      // 最低発注数
        //S SK-0051-008 2024/7/25           TCI)M.K【課題No.77：在庫以外の修正は在庫をリアルタイム更新】
        // setCal_zai(Number(Jdhdat.ret[0].HEN_CAL_ZAI));          // 店舗在庫
        if(Jdhdat.ret[0].HEN_CAL_ZAI !== ''){
          if(Jdhdat.ret[0].CAL_ZAI_ENTTIM !== ''){
            setCal_zai(Number(Jdhdat.ret[0].HEN_CAL_ZAI));          // 店舗在庫
          }else{
            setCal_zai(Number(Jdhdat.ret[0].CAL_ZAI));          // 店舗在庫
          }
        }
        //E SK-0051-008
      }
    }

    const ret = await onKeyDown_BIN2(htt,row);
    setRowItem(row);
    //ＪＡＮ入力後チェック
    // onKeyDown_JANCheck(row, inputNxRef);
    // setBtnSKU(false);
    if (ret)
    {
      setBin('1');
      inputNxRef.current.focus();
      inputNxRef.current.select();
    }else{
      setChkflg(2);
      inJAN1.current.select();
      return 1;
    }
    // グリッド表示（値を埋め込む）★後で調整
    let strYYYMMDD1 =state.HTTYMD;
    let strYYYMMDD2 ='';
    let strYYYMMDD3 ='';
    let strYYYMMDD4 ='';
    let strYYYMMDD5 ='';
    let strYYYMMDD6 ='';
    let strYYYMMDD7 ='';
    let Y_1_1,Y_1_2,Y_1_3,Y_1_4,Y_1_5,Y_1_6,Y_1_7 ='';  // １便
    let Y_2_1,Y_2_2,Y_2_3,Y_2_4,Y_2_5,Y_2_6,Y_2_7 ='';  // ２便
    let Y_3_1,Y_3_2,Y_3_3,Y_3_4,Y_3_5,Y_3_6,Y_3_7 ='';  // ３便
    let Y_4_1,Y_4_2,Y_4_3,Y_4_4,Y_4_5,Y_4_6,Y_4_7 ='';  // ４便
    
    // 日付分解
    let yyyy = String(state.HTTYMD).substring(0,4);
    let mm = String(state.HTTYMD).substring(4,6)-1;
    let dd = String(state.HTTYMD).substring(6,8);
    let ndate = new Date(yyyy,mm,dd), // 基準となる日付は使いまわす
    addDate = 1, // 足す日数
    max = 6; // 何回繰り返すか
    
    for (var i = 1; i <= max; i++) {
      ndate.setDate(ndate.getDate() + addDate);
      let nyyyy = ndate.getFullYear();                       //2022
      let nmm = ('0'+(ndate.getMonth()+1)).slice(-2);        //02
      let ndd = ('0'+ndate.getDate()).slice(-2);             //05
      // console.log(nyyyy+nmm+ndd)
      if (i===1) { strYYYMMDD2 =nyyyy+nmm+ndd;}
      else if(i===2){strYYYMMDD3 =nyyyy+nmm+ndd;}
      else if(i===3){strYYYMMDD4 =nyyyy+nmm+ndd;}
      else if(i===4){strYYYMMDD5 =nyyyy+nmm+ndd;}
      else if(i===5){strYYYMMDD6 =nyyyy+nmm+ndd;}
      else if(i===6){strYYYMMDD7 =nyyyy+nmm+ndd;}
    }      

    // 天気セット
    // Y_t_1 = `/tenki_icon/hare.gif`;
    const{str1:Y_t_1,str2:Y_k_1,str3:Y_o_1} = await Tnk_Set(strYYYMMDD1);
    const{str1:Y_t_2,str2:Y_k_2,str3:Y_o_2} = await Tnk_Set(strYYYMMDD2);
    const{str1:Y_t_3,str2:Y_k_3,str3:Y_o_3} = await Tnk_Set(strYYYMMDD3);
    const{str1:Y_t_4,str2:Y_k_4,str3:Y_o_4} = await Tnk_Set(strYYYMMDD4);
    const{str1:Y_t_5,str2:Y_k_5,str3:Y_o_5} = await Tnk_Set(strYYYMMDD5);
    const{str1:Y_t_6,str2:Y_k_6,str3:Y_o_6} = await Tnk_Set(strYYYMMDD6);
    const{str1:Y_t_7,str2:Y_k_7,str3:Y_o_7} = await Tnk_Set(strYYYMMDD7);

    let sJAN = '';
    if (htt.HTT_CDE === '2')
      {
        sJAN = htt.EOSCDE;
      }else{
        sJAN = htt.JANCDE;
      }

    const gethtjdat = await fetch('/TCSH0101/SH_HTJ_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({htt:httItem ,NHNYMD2:strYYYMMDD7,JANCDE:sJAN,httkbn:httkbn})});
    const htjdat = await gethtjdat.json();
    if(htjdat.CNT !== 0) {
      console.log('発注予約データget:%o',htjdat);
      for(const key in htjdat.ret) {
        if(htjdat.ret[key].BIN==='1'){
          if(htjdat.ret[key].NHNYMD===strYYYMMDD1){Y_1_1=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD2){Y_1_2=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD3){Y_1_3=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD4){Y_1_4=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD5){Y_1_5=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD6){Y_1_6=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD7){Y_1_7=htjdat.ret[key].HTTSUU;}
        }else if(htjdat.ret[key].BIN==='2'){
          if(htjdat.ret[key].NHNYMD===strYYYMMDD1){Y_2_1=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD2){Y_2_2=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD3){Y_2_3=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD4){Y_2_4=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD5){Y_2_5=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD6){Y_2_6=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD7){Y_2_7=htjdat.ret[key].HTTSUU;}
        }else if(htjdat.ret[key].BIN==='3'){
          if(htjdat.ret[key].NHNYMD===strYYYMMDD1){Y_3_1=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD2){Y_3_2=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD3){Y_3_3=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD4){Y_3_4=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD5){Y_3_5=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD6){Y_3_6=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD7){Y_3_7=htjdat.ret[key].HTTSUU;}
        }else if(htjdat.ret[key].BIN==='4'){
          if(htjdat.ret[key].NHNYMD===strYYYMMDD1){Y_4_1=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD2){Y_4_2=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD3){Y_4_3=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD4){Y_4_4=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD5){Y_4_5=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD6){Y_4_6=htjdat.ret[key].HTTSUU;}
          else if(htjdat.ret[key].NHNYMD===strYYYMMDD7){Y_4_7=htjdat.ret[key].HTTSUU;}
        }
        // 発注予約メモリに保存する ※この時点で便が確定していない為使えないので一旦コメント
        // htj.NHNYMD = htjdat.ret[key].NHNYMD;
        // htj.JANCDE = htjdat.ret[key].JANCDE;
        // htj.EOSCDE = htjdat.ret[key].EOSCDE;
        // htj.BIN = htjdat.ret[key].BIN;
        // htj.HTTYMD = htjdat.ret[key].HTTYMD;
        // htj.HTTKBN = htjdat.ret[key].HTTKBN;
        // htj.HTTSUU = htjdat.ret[key].HTTSUU;
        // htj.SSIKBN = htjdat.ret[key].SSIKBN;
        // htj.SECYMD = htjdat.ret[key].SECYMD;
        // htj.SECTIM = htjdat.ret[key].SECTIM;
        // setHtjItem(htj)
      }
      // console.log('発注予約データ格納:',htj)
    }

    const newRows = [];
    newRows.push(
      {id: '1', field0: '天候', field1: Y_t_1, field2: Y_t_2, field3: Y_t_3, field4: Y_t_4, field5: Y_t_5,field6: Y_t_6,field7: Y_t_7,}
      ,{id: '2', field0: '体感', field1: Y_k_1, field2: Y_k_2, field3: Y_k_3, field4: Y_k_4, field5: Y_k_5,field6: Y_k_6,field7: Y_k_7,}
      ,{id: '3', field0: '温度', field1: Y_o_1, field2: Y_o_2, field3: Y_o_3, field4: Y_o_4, field5: Y_o_5,field6: Y_o_6,field7: Y_o_7,}
      ,{id: '4', field0: '１便', field1: Y_1_1, field2: Y_1_2, field3: Y_1_3, field4: Y_1_4, field5: Y_1_5,field6: Y_1_6,field7: Y_1_7,}
      ,{id: '5', field0: '２便', field1: Y_2_1, field2: Y_2_2, field3: Y_2_3, field4: Y_2_4, field5: Y_2_5,field6: Y_2_6,field7: Y_2_7,}
      ,{id: '6', field0: '３便', field1: Y_3_1, field2: Y_3_2, field3: Y_3_3, field4: Y_3_4, field5: Y_3_5,field6: Y_3_6,field7: Y_3_7,}
      ,{id: '7', field0: '４便', field1: Y_4_1, field2: Y_4_2, field3: Y_4_3, field4: Y_4_4, field5: Y_4_5,field6: Y_4_6,field7: Y_4_7,}
      );
    setRows(newRows);
    // console.log(rows)
    
    // ここから実績表示
    let J_1_1,J_1_2,J_1_3,J_1_4,J_1_5,J_1_6,J_1_7 ='';  // 規格
    let J_2_1,J_2_2,J_2_3,J_2_4,J_2_5,J_2_6,J_2_7 ='';  // 売価
    let J_3_1,J_3_2,J_3_3,J_3_4,J_3_5,J_3_6,J_3_7 ='';  // 入荷
    let J_4_1,J_4_2,J_4_3,J_4_4,J_4_5,J_4_6,J_4_7 ='';  // 正販
    let J_5_1,J_5_2,J_5_3,J_5_4,J_5_5,J_5_6,J_5_7 ='';  // 見切
    let J_6_1,J_6_2,J_6_3,J_6_4,J_6_5,J_6_6,J_6_7 ='';  // 廃棄
    //S SK-0008-054 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
    // 初期化
    J_2_1='-';
    J_2_2='-';
    J_2_3='-';
    J_2_4='-';
    J_2_5='-';
    J_2_6='-';
    J_2_7='-';
    //S SK-0024-006 2024/6/27           TCI)M.K【課題No.37：過去実績の入荷から廃棄は「０」表示】
    // J_3_1 ='-';
    // J_3_2 ='-';
    // J_3_3 ='-';
    // J_3_4 ='-';
    // J_3_5 ='-';
    // J_3_6 ='-';
    // J_3_7 ='-';
    // J_4_1 ='-';
    // J_4_2 ='-';
    // J_4_3 ='-';
    // J_4_4 ='-';
    // J_4_5 ='-';
    // J_4_6 ='-';
    // J_4_7 ='-';
    // J_5_1 ='-';
    // J_5_2 ='-';
    // J_5_3 ='-';
    // J_5_4 ='-';
    // J_5_5 ='-';
    // J_5_6 ='-';
    // J_5_7 ='-';
    // J_6_1 ='-';
    // J_6_2 ='-';
    // J_6_3 ='-';
    // J_6_4 ='-';
    // J_6_5 ='-';
    // J_6_6 ='-';
    // J_6_7 ='-';    
    // //E SK-0008-054
    J_3_1 ='0';
    J_3_2 ='0';
    J_3_3 ='0';
    J_3_4 ='0';
    J_3_5 ='0';
    J_3_6 ='0';
    J_3_7 ='0';
    J_4_1 ='0';
    J_4_2 ='0';
    J_4_3 ='0';
    J_4_4 ='0';
    J_4_5 ='0';
    J_4_6 ='0';
    J_4_7 ='0';
    J_5_1 ='0';
    J_5_2 ='0';
    J_5_3 ='0';
    J_5_4 ='0';
    J_5_5 ='0';
    J_5_6 ='0';
    J_5_7 ='0';
    J_6_1 ='0';
    J_6_2 ='0';
    J_6_3 ='0';
    J_6_4 ='0';
    J_6_5 ='0';
    J_6_6 ='0';
    J_6_7 ='0';
    //E SK-0024-006
    // 日付分解
    let sWeekMonday =await formedDateOfThisWeek(7);
    let ThisWeekMonday = new Date(sWeekMonday);
    console.log('実績日付分解開始:',ThisWeekMonday)
    // 日付計算がおかしい為作り直し↓↓ここから↓↓
    // yyyy = ThisWeekMonday.getFullYear();               //2022
    // mm = ('0'+(ThisWeekMonday.getMonth()+1)).slice(-2);              //2
    // dd = ('0'+ThisWeekMonday.getDate()).slice(-2);                //5    
    // let jdate = new Date(yyyy,mm,dd), 
    // jaddDate = 1, // 足す日数
    // jmax = 6; // 何回繰り返すか
    // console.log(jdate);
    // strYYYMMDD1=yyyy+mm+dd;
    // for (i = 1; i <= jmax; i++) {
    //   jdate.setDate(jdate.getDate() + jaddDate);
    //   let yyyy = jdate.getFullYear();                       //2022
    //   let mm = ('0'+(jdate.getMonth())).slice(-2);        //02
    //   let dd = ('0'+jdate.getDate()).slice(-2);             //05
    //   console.log(jdate);
    //   console.log(yyyy+mm+dd);
    //   if (i===1) { strYYYMMDD2 =yyyy+mm+dd;}
    //   else if(i===2){strYYYMMDD3 =yyyy+mm+dd;}
    //   else if(i===3){strYYYMMDD4 =yyyy+mm+dd;}
    //   else if(i===4){strYYYMMDD5 =yyyy+mm+dd;}
    //   else if(i===5){strYYYMMDD6 =yyyy+mm+dd;}
    //   else if(i===6){strYYYMMDD7 =yyyy+mm+dd;}
    // }     
    var jdate = new Date(ThisWeekMonday), // 基準となる日付は使いまわす
    jaddDate = 1, // 足す日数
    jmax = 6; // 何回繰り返すか
    let wkweekdate = '';
    let yyyymmdd = '';
    wkweekdate = jdate.getMonth() + 1 + '/' + jdate.getDate() +'(' + jdate + ')';
    // setJDay1(wkweekdate);
    yyyymmdd = jdate.getFullYear() + ('0'+(jdate.getMonth()+1)).slice(-2) + ('0'+jdate.getDate()).slice(-2);
    strYYYMMDD1 =yyyymmdd;
    // console.log(yyyymmdd);
    for (i = 1; i <= jmax; i++) {
      jdate.setDate(jdate.getDate() + jaddDate);
      yyyymmdd = jdate.getFullYear() + ('0'+(jdate.getMonth()+1)).slice(-2) + ('0'+jdate.getDate()).slice(-2);
      // console.log(jdate);
      // console.log(yyyymmdd);
      if (i===1) { strYYYMMDD2 =yyyymmdd;}
      else if(i===2){strYYYMMDD3 =yyyymmdd;}
      else if(i===3){strYYYMMDD4 =yyyymmdd;}
      else if(i===4){strYYYMMDD5 =yyyymmdd;}
      else if(i===5){strYYYMMDD6 =yyyymmdd;}
      else if(i===6){strYYYMMDD7 =yyyymmdd;}
    }   
    // 日付計算がおかしい為作り直し↑↑ここまで↑↑

    // 天気セット
    // Y_t_1 = `/tenki_icon/hare.gif`;
    const{str1:J_t_1,str2:J_k_1,str3:J_o_1} = await Tnk_Set(strYYYMMDD1);
    const{str1:J_t_2,str2:J_k_2,str3:J_o_2} = await Tnk_Set(strYYYMMDD2);
    const{str1:J_t_3,str2:J_k_3,str3:J_o_3} = await Tnk_Set(strYYYMMDD3);
    const{str1:J_t_4,str2:J_k_4,str3:J_o_4} = await Tnk_Set(strYYYMMDD4);
    const{str1:J_t_5,str2:J_k_5,str3:J_o_5} = await Tnk_Set(strYYYMMDD5);
    const{str1:J_t_6,str2:J_k_6,str3:J_o_6} = await Tnk_Set(strYYYMMDD6);
    const{str1:J_t_7,str2:J_k_7,str3:J_o_7} = await Tnk_Set(strYYYMMDD7);

    // body: JSON.stringify({htt:httItem,NHNYMD1:strYYYMMDD1,NHNYMD2:strYYYMMDD7,JANCDE:inJAN1.current.value})});
    const getkkjdat = await fetch('/TCSH0101/SH_KKJ_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    //S SK-0054-009 2024/7/30           TCI)M.K【過去実績レスポンス対応】
    // body: JSON.stringify({htt:httItem,NHNYMD1:strYYYMMDD1,NHNYMD2:strYYYMMDD7,JANCDE:sJAN})});
    body: JSON.stringify({
      htt:httItem,
      NHNYMD1:strYYYMMDD1,
      NHNYMD2:strYYYMMDD7,
      JANCDE:sJAN,
      SHNID:shohinMaster.ret[0].SHNID,
    })});
    //E SK-0054-009
    const kkjdat = await getkkjdat.json();
    if(kkjdat.CNT !== 0) {
      console.log('過去実績データget:%o',kkjdat);
      for(const key in kkjdat.ret) {
        if(kkjdat.ret[key].NHNYMD===strYYYMMDD1){
          J_1_1=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-036 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_1=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_1=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-036
          J_3_1=kkjdat.ret[key].NYUKA;
          J_4_1=kkjdat.ret[key].SEIHAN;
          J_5_1=kkjdat.ret[key].MIKIRI;
          J_6_1=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD2){
          J_1_2=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-037 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_2=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_2=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-037
          J_3_2=kkjdat.ret[key].NYUKA;
          J_4_2=kkjdat.ret[key].SEIHAN;
          J_5_2=kkjdat.ret[key].MIKIRI;
          J_6_2=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD3){
          J_1_3=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-038 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_3=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_3=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-038
          J_3_3=kkjdat.ret[key].NYUKA;
          J_4_3=kkjdat.ret[key].SEIHAN;
          J_5_3=kkjdat.ret[key].MIKIRI;
          J_6_3=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD4){
          J_1_4=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-039 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_4=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_4=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-039
          J_3_4=kkjdat.ret[key].NYUKA;
          J_4_4=kkjdat.ret[key].SEIHAN;
          J_5_4=kkjdat.ret[key].MIKIRI;
          J_6_4=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD5){
          J_1_5=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-040 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_5=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_5=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-040
          J_3_5=kkjdat.ret[key].NYUKA;
          J_4_5=kkjdat.ret[key].SEIHAN;
          J_5_5=kkjdat.ret[key].MIKIRI;
          J_6_5=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD6){
          J_1_6=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-041 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_6=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_6=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-041
          J_3_6=kkjdat.ret[key].NYUKA;
          J_4_6=kkjdat.ret[key].SEIHAN;
          J_5_6=kkjdat.ret[key].MIKIRI;
          J_6_6=kkjdat.ret[key].HAIKI;
        }
        else if(kkjdat.ret[key].NHNYMD===strYYYMMDD7){
          J_1_7=kkjdat.ret[key].KIKAKU === '1' ? 'ﾁﾗｼ' :
          kkjdat.ret[key].KIKAKU === '2' ? 'ｲﾝﾌﾟﾛ' :
          kkjdat.ret[key].KIKAKU === '3' ? '得だ値' :
          kkjdat.ret[key].KIKAKU === '4' ? '得SP' :
          kkjdat.ret[key].KIKAKU === '5' ? '日替' : //SK-0024-005 【日替り→日替】
          kkjdat.ret[key].KIKAKU === '6' ? '特売' : '';
          //S SK-0008-042 2024/6/17           TCI)M.K【課題No.37：過去実績の売価表示変更】
          // J_2_7=formatCurrency(Number(kkjdat.ret[key].BTN));
          J_2_7=kkjdat.ret[key].BTN === '0' ? '-' : formatCurrency(Number(kkjdat.ret[key].BTN));
          //E SK-0008-042
          J_3_7=kkjdat.ret[key].NYUKA;
          J_4_7=kkjdat.ret[key].SEIHAN;
          J_5_7=kkjdat.ret[key].MIKIRI;
          J_6_7=kkjdat.ret[key].HAIKI;
        }
      }
    }

    const newRows2 = [];
    newRows2.push(
      {id: '1', field0: '天候', field1: J_t_1, field2: J_t_2, field3: J_t_3, field4: J_t_4, field5: J_t_5,field6: J_t_6,field7: J_t_7,}
      ,{id: '2', field0: '体感', field1: J_k_1, field2: J_k_2, field3: J_k_3, field4: J_k_4, field5: J_k_5,field6: J_k_6,field7: J_k_7,}
      ,{id: '3', field0: '温度', field1: J_o_1, field2: J_o_2, field3: J_o_3, field4: J_o_4, field5: J_o_5,field6: J_o_6,field7: J_o_7,}
      ,{id: '4', field0: '企画', field1:J_1_1, field2:J_1_2, field3:J_1_3, field4:J_1_4, field5:J_1_5,field6:J_1_6,field7:J_1_7,}
      ,{id: '5', field0: '売価', field1:J_2_1, field2:J_2_2, field3:J_2_3, field4:J_2_4, field5:J_2_5,field6:J_2_6,field7:J_2_7,}
      ,{id: '6', field0: '入荷', field1:J_3_1, field2:J_3_2, field3:J_3_3, field4:J_3_4, field5:J_3_5,field6:J_3_6,field7:J_3_7,}
      ,{id: '7', field0: '正販', field1:J_4_1, field2:J_4_2, field3:J_4_3, field4:J_4_4, field5:J_4_5,field6:J_4_6,field7:J_4_7,}
      ,{id: '8', field0: '見切', field1:J_5_1, field2:J_5_2, field3:J_5_3, field4:J_5_4, field5:J_5_5,field6:J_5_6,field7:J_5_7,}
      ,{id: '9', field0: '廃棄', field1:J_6_1, field2:J_6_2, field3:J_6_3, field4:J_6_4, field5:J_6_5,field6:J_6_6,field7:J_6_7,}
      );
    setRows2(newRows2);

  }

  // データセット
  function setRow_One(row, shohinMaster,jancde){
      if (shohinMaster.SHNID === undefined)        //タグマスタ判定
      {
        row.SHNID = '99999999999';            // 商品ID
      }
      else
      {
        row.SHNID = shohinMaster.SHNID;            // 商品ID
      }
      row.SHNMEIKN = shohinMaster.SHNMEIKN;      // 商品名
      row.KIKAKUKN = shohinMaster.KIKAKUKN;      // 規格
      //S SK-0072-003 2024/9/6           TCI)M.K【内部検証：衣料　ログイン部門対応】
      row.BMNCDE = shohinMaster.BMNCDE;          // 部門コード
      //E SK-0072-003
      // row.JANCDE = shohinMaster.JANCDE1;         // ＪＡＮコード（row.JANCDEはスキャンコードを格納）
      setSjhjancde(shohinMaster.JANCDE1);        // 商品情報表示用に変数格納処理
      row.TRIHBN = shohinMaster.TRIHBN;          // 取引先品番
      //S SK-0048-004 2024/7/12           TCI)M.K【SKU⇒取扱無しの場合、SKUに戻らない】
      uTRIHBN = shohinMaster.TRIHBN;          // 取引先品番
      //E SK-0048-004
      if (shohinMaster.GNT === undefined)        //タグマスタ判定
      {
        row.GNT = shohinMaster.GNK;                // 原価
      }else
      {
        row.GNT = shohinMaster.GNT;                // 原価
      }
      if (shohinMaster.BTN === undefined)        //タグマスタ判定
      {
        row.BTN = shohinMaster.BAK;                // 売価
      }else
      {
        row.BTN = shohinMaster.BTN;                // 売価
      }
      if (shohinMaster.JANCDE1 === undefined)
      {
        row.JANCDE = shohinMaster.jancde;          // ＪＡＮコード
      }
      else
      {
        row.JANCDE = jancde;          // ＪＡＮコード
      }
      row.ASTCDE = shohinMaster.ASTCDE;          // ＡＵ
      row.EOSCDE = shohinMaster.EOSCDE;          // ＥＯＳコード
      row.EOSCDE1 = shohinMaster.EOSCDE1;        // ＥＯＳコード１便
      row.EOSCDE2 = shohinMaster.EOSCDE2;        // ＥＯＳコード２便
      row.EOSCDE3 = shohinMaster.EOSCDE3;        // ＥＯＳコード３便
      row.EOSCDE4 = shohinMaster.EOSCDE4;        // ＥＯＳコード４便
      row.TAGLINCDE = shohinMaster.LINCDE;       // ラインコード
      row.TAGCLSCDE = shohinMaster.CLSCDE;       // タグクラス
      row.TAGSZN = shohinMaster.TAGSZN;          // タグシーズン
      if (shohinMaster.TAGSZNNM !== undefined)
      {
        row.TAGSZNNM = shohinMaster.TAGSZNNM;      // タグシーズン名カナ
      }
      if (shohinMaster.TAGSIZCDE !== undefined)
      {
        row.TAGSIZ = shohinMaster.TAGSIZCDE;       // サイズコード
      }
      if (shohinMaster.TAGSIZMEI !== undefined)
      {
        row.TAGSIZMEI = shohinMaster.TAGSIZMEI;    // サイズ名カナ
      }
      if (shohinMaster.TAGCOLCDE !== undefined)
      {
        row.TAGCOLCDE = shohinMaster.TAGCOLCDE;    // カラーコード
      }
      if (shohinMaster.TAGCOLMEI !== undefined)
      {
        row.TAGCOLMEI = shohinMaster.TAGCOLMEI;    // カラー名カナ
      }
      row.HTTSTD = shohinMaster.HTTSTD;          // 発注開始日
      row.HTTEND = shohinMaster.HTTEND;          // 発注終了日
      row.HTTTNI = shohinMaster.HTTTNI;          // 発注単位CD 
      row.HTTTNIKNJ = shohinMaster.HTTTNIKNJ;    // 発注単位
      row.IRISUU = shohinMaster.IRISUU;          // 入数
      row.SYMNSU = shohinMaster.SYMNSU;          // 賞味日数
      row.TOK_GNT = shohinMaster.TOK_GNT;        // 特売原価
      row.TOK_BTN = shohinMaster.TOK_BTN;        // 特売売価
      row.NKIKBN  = shohinMaster.NKIKBN ;        // 納期区分
      row.TEINOK1 = shohinMaster.TEINOK1;        // 定番納期１
      row.TEINOK2 = shohinMaster.TEINOK2;        // 定番納期２
      row.TEINOK3 = shohinMaster.TEINOK3;        // 定番納期３
      row.TEINOK4 = shohinMaster.TEINOK4;        // 定番納期４
      row.TEINOK5 = shohinMaster.TEINOK5;        // 定番納期５
      row.TEINOK6 = shohinMaster.TEINOK6;        // 定番納期６
      row.TEINOK7 = shohinMaster.TEINOK7;        // 定番納期７
      row.TOKNOK1 = shohinMaster.TOKNOK1;        // 特売納期１
      row.TOKNOK2 = shohinMaster.TOKNOK2;        // 特売納期２
      row.TOKNOK3 = shohinMaster.TOKNOK3;        // 特売納期３
      row.TOKNOK4 = shohinMaster.TOKNOK4;        // 特売納期４
      row.TOKNOK5 = shohinMaster.TOKNOK5;        // 特売納期５
      row.TOKNOK6 = shohinMaster.TOKNOK6;        // 特売納期６
      row.TOKNOK7 = shohinMaster.TOKNOK7;        // 特売納期７
      // 値入率＝（売価-原価）÷売価×100  
      if (httkbn === '1'){
        row.NEIRI = (
          (Number(shohinMaster.BTN)-Number(shohinMaster.GNT))/Number(shohinMaster.BTN)*100
          ).toLocaleString(undefined, {maximumSignificantDigits: 3})+'%' 
      } else {
        row.NEIRI = (
          (Number(shohinMaster.TOK_BTN)-Number(shohinMaster.TOK_GNT))/Number(shohinMaster.TOK_BTN)*100
          ).toLocaleString(undefined, {maximumSignificantDigits: 3})+'%' 
      }
      row.NHNPTN = shohinMaster.NHNPTN;         // 納品パターン 2024/06/03追加
      console.log('データセット:%o',row);
      setRowItem(row);
    };
  
  // データセット（ここで設定したらそのままテーブルに保存します）
  async function setRow_Two(htt, shohinMaster,jancde){
    htt.SHNID = shohinMaster.SHNID;            // 商品ID
    htt.SHNMEIKN = shohinMaster.SHNMEIKN;      // 商品名
    if (shohinMaster.JANCDE1 === undefined)    //タグマスターから情報を取得するとJANコードは存在しない
    {
      htt.JANCDE = jancde;                     // ＪＡＮコード
    }else{
      htt.JANCDE = shohinMaster.JANCDE1;       // ＪＡＮコード
    }
    htt.EOSCDE = shohinMaster.EOSCDE;
    if (shohinMaster.EOSCDE == '')
    {
      htt.HTT_CDE = '1';
    }else{
      htt.HTT_CDE = '2';
    }

    htt.BIN = bin                              // 便
    //締時間
    htt.BMNCDE = shohinMaster.BMNCDE;          // 部門コード(2024/05/14 280-005対応)
    htt.ASTCDE = shohinMaster.ASTCDE;          // ＡＵ
    htt.LINCDE = shohinMaster.LINCDE;          // ライン
    htt.CLSCDE = shohinMaster.CLSCDE;          // クラス
    htt.TRIHBN = shohinMaster.TRIHBN;          // 取引先品番
    htt.HTTSTD = shohinMaster.HTTSTD;          // 発注開始日
    htt.HTTEND = shohinMaster.HTTEND;          // 発注終了日

    htt.HTTTNI = shohinMaster.HTTTNI;          //発注単位(C4)
    htt.HTTTNIKNJ = shohinMaster.HTTTNIKNJ;    //発注単位名
    htt.IRISUU = shohinMaster.IRISUU;          //発注入数
    
    if (httkbn==='1'){
      if (shohinMaster.ZKGNK === undefined)
      {
        htt.GNT = shohinMaster.GNT;              // 原価
        htt.BTN = shohinMaster.BTN;              // 売価
      }else
      {
        htt.GNT = shohinMaster.ZKGNK;              // 原価
        htt.BTN = shohinMaster.ZKBAK;              // 売価
      }
    }else if (httkbn==='2'){
      htt.GNT = shohinMaster.TOK_GNT;            // 特売原価
      htt.BTN = shohinMaster.TOK_BTN;            // 特売売価
    }
    htt.SIRCDE = shohinMaster.SIRCDE;            // 仕入先コード
    // htt.SIRMEIK = rowItem.SIRMEIK;            // 仕入先名称（漢字）//ここだとrowItem取れない場合有り
                                                 //ＥＯＳコード
    if (bin === '1'){htt.EOSCDE = shohinMaster.EOSCDE;}
    // else if (bin === '2'){htt.EOSCDE = shohinMaster.EOSCDE2;}
    // else if (bin === '3'){htt.EOSCDE = shohinMaster.EOSCDE3;}
    // else if (bin === '4'){htt.EOSCDE = shohinMaster.EOSCDE4;}
    // else{htt.EOSCDE = shohinMaster.EOSCDE;}
    // htt.HTT_CDE = rowItem.IN_CD_KBN;           // 発注コード//ここだとrowItem取れない場合有り
    htt.HTT_KBN = httkbn;                         // 発注区分
    // htt.WEEK = week;                           // 発注曜日
    htt.WEEK = await get_week();                        // 発注曜日
    
    setHttItem(htt);
    console.log('データセットhtt:%o',htt);
  };

  // データセット（2段）2
  function setRow_Two2(row, shohinMaster) {
    row.SHNMEIKN = shohinMaster.SHNMEIKN.trim();    // 商品名
    row.KIKAKUKN = shohinMaster.KIKAKUKN.trim();    // 規格
    // setRowSuu(row, '1');                         // 数量
    row.GNT = shohinMaster.GNT;                  // 原価金額
    row.BTN = shohinMaster.BTN;                  // 売価金額
    //row.JANCDE2 = inJAN2.current.value;      // ＪＡＮコード２

    row.TAGASTCDE = shohinMaster.ASTCDE;     // タグＡＵ
    row.TAGLINCDE = shohinMaster.LINCDE;     // タグライン
    row.TAGCLSCDE = shohinMaster.CLSCDE;     // タグクラス
    row.TAGSZN = shohinMaster.TAGSZN;        // タグシーズン
    row.TRIHBN = shohinMaster.TRIHBN;        // 取引先品番
    //S SK-0048-005 2024/7/12           TCI)M.K【SKU⇒取扱無しの場合、SKUに戻らない】
    uTRIHBN = shohinMaster.TRIHBN;          // 取引先品番
    //E SK-0048-005
    row.SIZCDE = shohinMaster.TAGSIZCDE;     // サイズコード
    row.TAGSIZMEI = shohinMaster.TAGSIZMEI;  // サイズ名カナ
    row.TAGCOLCDE = shohinMaster.TAGCOLCDE;  // カラーコード
    row.TAGCOLMEI = shohinMaster.TAGCOLMEI;  // カラー名カナ
    row.TAGSZNNM = shohinMaster.TAGSZNNM;    // タグシーズン名
    row.HTTSTD = shohinMaster.HTTSTD;        // 発注開始日
    row.HTTEND = shohinMaster.HTTEND;        // 発注終了日
  }


  // 便入力(onKey用基本使わない、衣料品は１固定の為)
  const onKeyDown_BIN = async(inputRef, inputNxRef) => {
    setChkBIN('1');
    const htt = await JSON.parse(JSON.stringify(httItem));
    let errflg = 0;
    console.log('htt:%o',htt.SHNID);

    if (htt.SHNID === ''||bin === '') {
      window.alert('登録するデータが存在しません。'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return;
    }

    // ３-１　商品マスタ内容チェック処理
    // (1)商品マスタ内容チェック処理
    //  ①発注区分が1(定番)でかつ商品マスタ（店別）の定番納期＝ｵｰﾙ0もしくは99の場合、エラー
    console.log('A)	商品マスタよりチェック処理 開始')
    if (httkbn === '1'){
      if ((rowItem.TEINOK1 === '00' || rowItem.TEINOK1 === '99') 
        && (rowItem.TEINOK2 === '00' || rowItem.TEINOK2 === '99') 
        && (rowItem.TEINOK3 === '00' || rowItem.TEINOK3 === '99') 
        && (rowItem.TEINOK4 === '00' || rowItem.TEINOK4 === '99') 
        && (rowItem.TEINOK5 === '00' || rowItem.TEINOK5 === '99') 
        && (rowItem.TEINOK6 === '00' || rowItem.TEINOK6 === '99') 
        && (rowItem.TEINOK7 === '00' || rowItem.TEINOK7 === '99') 
      ){
        setJAN1clr('#f59d9d');
        inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
        window.alert('この商品は取扱無しに設定されているため、定番発注は行えません。'); 
        timeRef.current.reTimer();
        setChkflg(2);
        return;
      }else{
        setJAN1clr('#FFFFFF');
      }
    }

    // ②発注区分が2(特売)でかつ商品マスタ（店別）の特売納期＝ｵｰﾙ0もしくは99の場合、エラー
    if (httkbn === '2'){
      if ((rowItem.TOKNOK1 === '00' || rowItem.TOKNOK1 === '99') 
        && (rowItem.TOKNOK2 === '00' || rowItem.TOKNOK2 === '99') 
        && (rowItem.TOKNOK3 === '00' || rowItem.TOKNOK3 === '99') 
        && (rowItem.TOKNOK4 === '00' || rowItem.TOKNOK4 === '99') 
        && (rowItem.TOKNOK5 === '00' || rowItem.TOKNOK5 === '99') 
        && (rowItem.TOKNOK6 === '00' || rowItem.TOKNOK6 === '99') 
        && (rowItem.TOKNOK7 === '00' || rowItem.TOKNOK7 === '99') 
      ){
        setJAN1clr('#f59d9d');
        inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
        window.alert('この商品は特売発注行えません。'); 
        timeRef.current.reTimer();
        setChkflg(2);
        return;
      }else{
        setJAN1clr('#FFFFFF');
      }
    }

    // ③発注停止データを検索し、１件以上該当の場合エラー
    console.log('(3)発注停止データ検索開始');
    const getstop = await fetch('/TCSH0101/SH_HTT_STOP_DAT_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ 
        ksacde:state.KSACDE
        , mise:state.MISES
        , astcde:rowItem.ASTCDE
        , sircde:rowItem.SIRCDE
        , httkbn:httkbn
        , httymd:state.HTTYMD
        , bin:bin
        , eoscde:rowItem.EOSCDE
        , jancde:rowItem.JANCDE
        , nhnptn:rowItem.NHNPTN // 納品パターン 2024/06/03追加
      })});
    const j_getstop = await getstop.json();
    console.log('(3)発注停止データ検索結果:%o',j_getstop.record_exists);
    if (j_getstop.record_exists!==0){
      inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('バイヤー指示による発注停止商品です。'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return;
    }
    
    // ④発注日が発注開始日以前に該当の場合、エラー
    if (rowItem.HTTSTD !== '')
    {
      if (rowItem.HTTSTD > state.HTTYMD){
        setJAN1clr('#f59d9d');
        inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
        window.alert('この商品の発注開始日は'+formatDate(rowItem.HTTSTD)+'からです。\n入力された日付は発注対象期間外です。'); 
        timeRef.current.reTimer();
        setChkflg(2);
        return;
      }
    }

    // ⑤発注日が発注終了日以降に該当の場合、エラー
    if (rowItem.HTTEND !== '')
    {
      if (rowItem.HTTEND < state.HTTYMD){
        setJAN1clr('#f59d9d');
        inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
        window.alert('この商品の発注終了日は'+formatDate(rowItem.HTTEND)+'までです。\n入力された日付は発注対象期間外です。'); 
        timeRef.current.reTimer();
        setChkflg(2);
        return;
      }
    }

    // ２）リードタイムのチェック処理
    // ①発注区分が1(定番)でかつ引数.発注曜日にあたる商品マスタの定番納期曜日が99
    // ②発注区分が2(特売)でかつ引数.発注曜日にあたる商品マスタの特売納期曜日が99
    console.log('B)	リードタイムのチェック処理 開始')
    if (rowItem.NKIKBN === '1'){        // "1":曜日別納期を使用
      if (httkbn === '1'){
        // switch(week)     
        const ret = await get_week();
        switch(ret)
        {
          case 1:
            if (rowItem.TEINOK1 === '00' || rowItem.TEINOK1 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TEINOK1));}
            break; 
          case 2:
            if (rowItem.TEINOK2 === '00' || rowItem.TEINOK2 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TEINOK2));}
            break;
          case 3:
            if (rowItem.TEINOK3 === '00' || rowItem.TEINOK3 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TEINOK3));}
            break;
          case 4:
            if (rowItem.TEINOK4 === '00' || rowItem.TEINOK4 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TEINOK4));}
            break;
          case 5:
            if (rowItem.TEINOK5 === '00' || rowItem.TEINOK5 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TEINOK5));}
            break; 
          case 6:
            if (rowItem.TEINOK6 === '00' || rowItem.TEINOK6 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TEINOK6));}
            break;
          case 7:
            if (rowItem.TEINOK7 === '00' || rowItem.TEINOK7 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TEINOK7));}
            break;
          default:
            break;
        };
        // htt.NHNYMD = await calcNhnymd(2);  // testで2を入れる　後で消す
      }else{
        // switch(week)     
        const ret = await get_week();
        switch(ret)
        {
          case 1:
            if (rowItem.TOKNOK1 === '00' || rowItem.TOKNOK1 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TOKNOK1));}
            break; 
          case 2:
            if (rowItem.TOKNOK2 === '00' || rowItem.TOKNOK2 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TOKNOK2));}
            break;
          case 3:
            if (rowItem.TOKNOK3 === '00' || rowItem.TOKNOK3 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TOKNOK3));}
            break;
          case 4:
            if (rowItem.TOKNOK4 === '00' || rowItem.TOKNOK4 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TOKNOK4));}
            break;
          case 5:
            if (rowItem.TOKNOK5 === '00' || rowItem.TOKNOK5 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TOKNOK5));}
            break; 
          case 6:
            if (rowItem.TOKNOK6 === '00' || rowItem.TOKNOK6 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TOKNOK6));}
            break;
          case 7:
            if (rowItem.TOKNOK7 === '00' || rowItem.TOKNOK7 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.TOKNOK7));}
            break;
          default:
            break;
        };
      }
    }else if (rowItem.NKIKBN === '2'){        // "2":便別納期を使用
      switch(bin)
      {
        case 1:
          if (rowItem.BINNKI1 === '00' || rowItem.BINNKI1 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.BINNKI1));}
          break; 
        case 2:
          if (rowItem.BINNKI2 === '00' || rowItem.BINNKI2 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.BINNKI2));}
          break;
        case 3:
          if (rowItem.BINNKI3 === '00' || rowItem.BINNKI3 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.BINNKI3));}
          break;
        case 4:
          if (rowItem.BINNKI4 === '00' || rowItem.BINNKI4 === '99'){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(rowItem.BINNKI4));}
          break;
        default:
          break;
      };
    }

    if (errflg !== 0){
      setJAN1clr('#f59d9d');
      inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('入力された発注日での発注は行えません。'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return;
    }

    // １）「便」欄の入力チェック
    console.log('bin:%o',bin);
    switch(bin)     // 自動発注状態01:対象　02:対象外 03:店舗停止 04:本部停止 　
    {
      case '1':
        if (rowItem.EOSCDE === ''){errflg = -1;}  // 便指定無い商品はEOSCDE1も無い為、EOSCDEをチェックする
        break; 
      case '2':
        if (rowItem.EOSCDE2 === ''){errflg = -1;}
        break;
      case '3':
        if (rowItem.EOSCDE3 === ''){errflg = -1;}
        break;
      case '4':
        if (rowItem.EOSCDE4 === ''){errflg = -1;}
        break;
      default:
        break;
    };
    if (errflg !== 0){
      setBINclr('#CCCCCC');
      inBIN.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('入力された便の発注は出来ません。'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return;
    }

    // ２）締め時間のチェック
    console.log('C)	締め時間のチェック 開始')
    //  ①部門、部門と商品マスタAU、スキャンコード、発注区分で絞め時間マスタ参照し
    //  指定されてる締め時間を過ぎてる場合、エラー
    //  ※発注日がシステム日付当日の場合のみチェック
    const gethttdat3 = await fetch('/TCSH0101/SH_SIM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({htt:httItem, SIMKBN:'3' })});
    const simdat_3 = await gethttdat3.json();
    if(simdat_3.CNT !== 0) {
      console.log('simdat_3.CNT:%o',simdat_3.CNT);
      htt.SIMTIM = simdat_3.ret[0].SIMTIM       // 締時間               
      setHttItem(htt);
      console.log('データセットhtt SIMKBN3:%o',htt);
    }else{
      const gethttdat2 = await fetch('/TCSH0101/SH_SIM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({htt:httItem, SIMKBN:'2' })});
      const simdat_2 = await gethttdat2.json();
      if(simdat_2.CNT !== 0){
        console.log('simdat_2.CNT:%o',simdat_3.CNT);
        htt.SIMTIM = simdat_2.ret[0].SIMTIM       // 締時間               
        setHttItem(htt);
        console.log('データセットhtt SIMKBN2:%o',htt);
      }else{
        const gethttdat1 = await fetch('/TCSH0101/SH_SIM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({htt:httItem, SIMKBN:'1' })});
        const simdat_1 = await gethttdat1.json();
        if(simdat_1.CNT !== 0){
          console.log('simdat_1.CNT:%o',simdat_3.CNT);
          htt.SIMTIM = simdat_1.ret[0].SIMTIM       // 締時間               
          setHttItem(htt);
          console.log('データセットhtt SIMKBN1:%o',htt);
        }        
      }
    }
    //S SK-0039-001 2024/7/8           TCI)M.K【先日付発注の場合でも締切エラーが発生】
    // //  ②締切時間　５分前・１分前の注意喚起画面表示
    // const ndate = new Date();
    // let yyyy = ndate.getFullYear();                   //2022
    // let mm = ('0'+(ndate.getMonth()+1)).slice(-2);    //02
    // let dd = ('0'+ndate.getDate()).slice(-2);         //05    
    // let hh = htt.SIMTIM.slice(0,2);
    // let nn = htt.SIMTIM.slice(-2);
    // let ss = '00';
    // let ymdhms = yyyy+'/'+mm+'/'+dd+' '+hh+':'+nn+':'+ss;
    // CloseTimeCheck(ymdhms)
    //E SK-0039-001

    // // ３）発注区分違い発注済みチェック
    // console.log('D)	発注区分違い発注済みチェック 開始')
    // let l_httkbn = '';
    // let l_httkbnnm = '';
    // if (httkbn === '1'){
    //   l_httkbn = '2';
    //   l_httkbnnm = '特売';
    // } else if (httkbn === '2'){
    //   l_httkbn = '1';
    //   l_httkbnnm = '定番';
    // }

    let sJAN = '';
    if (htt.HTT_CDE === '2')
      {
        sJAN = htt.EOSCDE;
      }else{
        sJAN = htt.JANCDE;
      }

    // const gethtjdat = await fetch('/TCSH0101/SH_HTJ_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    // body: JSON.stringify({htt:httItem ,NHNYMD2:httItem.NHNYMD,JANCDE:sJAN,httkbn:l_httkbn})});
    // const htjdat = await gethtjdat.json();
    // if(htjdat.CNT !== 0) {
    //   console.log('発注予約データget:%o',htjdat);
    //   if(!window.confirm('すでに' + l_httkbnnm + '発注済み。発注を続けますか？')){
    //     onClick_FooterButton_Clear();
    //     return;
    //   } else {
    //     inputNxRef.current.focus();
    //   }
    // }

    // ４）発注区分同一、バイヤー店舗発注不可チェック
    console.log('F)	発注区分同一、バイヤー店舗発注不可チェック 開始')
    const gethtjdat2 = await fetch('/TCSH0101/SH_HTJ_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({htt:httItem ,NHNYMD2:httItem.NHNYMD,JANCDE:sJAN,httkbn:httkbn})});
    const htjdat2 = await gethtjdat2.json();
    for(const key in htjdat2.ret) {
      if(htjdat2.ret[key].SSIKBN==='2'){
        inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
        window.alert('バイヤー発注済み。店舗発注修正不可商品です。'); 
        timeRef.current.reTimer();
      }
    }

    // ５）チェック問題無しの場合、既存発注の呼び出し

    // 終了処理
    setBINclr('#CCCCCC');
    setChkflg(0);
    clock2Ref.current.timerCheck(htt.SIMTIM); // タイマー処理
    inputNxRef.current.focus();
  };
  // 便入力(衣料品専用)
  const onKeyDown_BIN2 = async(htt, row) => {
    setChkBIN('1');
    // const htt = await JSON.parse(JSON.stringify(httItem));
    let errflg = 0;
    console.log('htt:%o',htt.SHNID);

    if (htt.SHNID === '') {
      window.alert('登録するデータが存在しません。'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return false;
    }

    // ３-１　商品マスタ内容チェック処理
    // (1)商品マスタ内容チェック処理
    //  ①発注区分が1(定番)でかつ商品マスタ（店別）の定番納期＝ｵｰﾙ0もしくは99の場合、エラー
    console.log('A)	商品マスタよりチェック処理 開始')
    if (httkbn === '1'){
      if ((row.TEINOK1 === '00' || row.TEINOK1 === '99' || row.TEINOK1 === null) 
        && (row.TEINOK2 === '00' || row.TEINOK2 === '99' || row.TEINOK2 === null) 
        && (row.TEINOK3 === '00' || row.TEINOK3 === '99' || row.TEINOK3 === null) 
        && (row.TEINOK4 === '00' || row.TEINOK4 === '99' || row.TEINOK4 === null) 
        && (row.TEINOK5 === '00' || row.TEINOK5 === '99' || row.TEINOK5 === null) 
        && (row.TEINOK6 === '00' || row.TEINOK6 === '99' || row.TEINOK6 === null) 
        && (row.TEINOK7 === '00' || row.TEINOK7 === '99' || row.TEINOK7 === null) 
      ){
        setJAN1clr('#f59d9d');
        window.alert('この商品は取扱無しに設定されているため、定番発注は行えません。'); 
        timeRef.current.reTimer();
        
        //S SK-0048-006 2024/7/12           TCI)M.K【SKU⇒取扱無しの場合、SKUに戻らない】
        console.log('!!!!!!!SKUflg:',uSKUflg);
        if (uSKUflg === 1)
          {
            if ((window.confirm('SKU画面を再表示しますか？'))) { 
              await onClick_FooterButton_SKU();
              return;
            }else{
              setSKUFlg(0);
            }
          }
        //E SK-0048-006

        //S SK-0001-011 2024/7/12           TCI)M.K【早すぎる為２段JANがクリアされない】
        // //S SK-0001-007 2024/6/13           TCI)M.K【取扱無しは入力内容をクリアする】
        // onClick_FooterButton_Clear();
        // //E SK-0001-007
        setTimeout(() => {
          console.log('!0.5ms待ちました!');
          onClick_FooterButton_Clear();
        }, 500);
        //E SK-0001-011
        setChkflg(2);
        return false;
      }else{
        setJAN1clr('#FFFFFF');
      }
    }

    // ②発注区分が2(特売)でかつ商品マスタ（店別）の特売納期＝ｵｰﾙ0もしくは99の場合、エラー
    if (httkbn === '2'){
      if ((row.TOKNOK1 === '00' || row.TOKNOK1 === '99' || row.TOKNOK1 === null) 
        && (row.TOKNOK2 === '00' || row.TOKNOK2 === '99' || row.TOKNOK2 === null) 
        && (row.TOKNOK3 === '00' || row.TOKNOK3 === '99' || row.TOKNOK3 === null) 
        && (row.TOKNOK4 === '00' || row.TOKNOK4 === '99' || row.TOKNOK4 === null) 
        && (row.TOKNOK5 === '00' || row.TOKNOK5 === '99' || row.TOKNOK5 === null) 
        && (row.TOKNOK6 === '00' || row.TOKNOK6 === '99' || row.TOKNOK6 === null) 
        && (row.TOKNOK7 === '00' || row.TOKNOK7 === '99' || row.TOKNOK7 === null) 
      ){
        setJAN1clr('#f59d9d');
        window.alert('この商品は特売発注行えません。'); 
        timeRef.current.reTimer();
        //S SK-0001-008 2024/6/13           TCI)M.K【取扱無しは入力内容をクリアする】
        onClick_FooterButton_Clear();
        //E SK-0001-008
        setChkflg(2);
        return;
      }else{
        setJAN1clr('#FFFFFF');
      }
    }

    // ③発注停止データを検索し、１件以上該当の場合エラー
    console.log('(3)発注停止データ検索開始');
    const getstop = await fetch('/TCSH0101/SH_HTT_STOP_DAT_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ 
        ksacde:state.KSACDE
        //S SK-0072-001 2024/9/6           TCI)M.K【内部検証：衣料　ログイン部門対応】
        // , bmncde:state.BMNCDE // 20240425追加
        , bmncde:row.BMNCDE
        //E SK-0072-001
        , mise:state.MISES
        , astcde:row.ASTCDE
        , sircde:row.SIRCDE
        , httkbn:httkbn
        , httymd:state.HTTYMD
        , bin:bin
        , eoscde:row.EOSCDE
        , jancde:row.JANCDE
        , nhnptn:row.NHNPTN // 納品パターン 2024/06/03追加
      })});
    const j_getstop = await getstop.json();
    console.log('(3)発注停止データ検索結果:%o',j_getstop.record_exists);
    if (j_getstop.record_exists!==0){
      setJAN1clr('#f59d9d');
      inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('バイヤー指示による発注停止商品です。'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return false;
    }
    
    // ④発注日が発注開始日以前に該当の場合、エラー
    if (row.HTTSTD !== '')
    {
      if (row.HTTSTD > state.HTTYMD){
        setJAN1clr('#f59d9d');
        inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
        window.alert('この商品の発注開始日は'+formatDate(row.HTTSTD)+'からです。\n入力された日付は発注対象期間外です。'); 
        timeRef.current.reTimer();
        setChkflg(2);
        return false;
      }
    }

    // ⑤発注日が発注終了日以降に該当の場合、エラー
    if (row.HTTEND !== '')
    {
      if (row.HTTEND < state.HTTYMD){
        setJAN1clr('#f59d9d');
        inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
        window.alert('この商品の発注終了日は'+formatDate(row.HTTEND)+'までです。\n入力された日付は発注対象期間外です。'); 
        timeRef.current.reTimer();
        setChkflg(2);
        return false;
      }
    }

    // ２）リードタイムのチェック処理
    // ①発注区分が1(定番)でかつ引数.発注曜日にあたる商品マスタの定番納期曜日が99
    // ②発注区分が2(特売)でかつ引数.発注曜日にあたる商品マスタの特売納期曜日が99
    console.log('B)	リードタイムのチェック処理 開始')
    if (row.NKIKBN === '1'){        // "1":曜日別納期を使用
      if (httkbn === '1'){
        // switch(week)
        const ret = await get_week();
        switch(ret)
        {
          case 1:
            if (row.TEINOK1 === '00' || row.TEINOK1 === '99' || row.TEINOK1 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TEINOK1));}
            break; 
          case 2:
            if (row.TEINOK2 === '00' || row.TEINOK2 === '99' || row.TEINOK2 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TEINOK2));}
            break;
          case 3:
            if (row.TEINOK3 === '00' || row.TEINOK3 === '99' || row.TEINOK3 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TEINOK3));}
            break;
          case 4:
            if (row.TEINOK4 === '00' || row.TEINOK4 === '99' || row.TEINOK4 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TEINOK4));}
            break;
          case 5:
            if (row.TEINOK5 === '00' || row.TEINOK5 === '99' || row.TEINOK5 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TEINOK5));}
            break; 
          case 6:
            if (row.TEINOK6 === '00' || row.TEINOK6 === '99' || row.TEINOK6 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TEINOK6));}
            break;
          case 7:
            if (row.TEINOK7 === '00' || row.TEINOK7 === '99' || row.TEINOK7 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TEINOK7));}
            break;
          default:
            break;
        };
        // htt.NHNYMD = await calcNhnymd(2);  // testで2を入れる　後で消す
      }else{
        // switch(week)     
        const ret = await get_week();
        switch(ret)
        {
          case 1:
            if (row.TOKNOK1 === '00' || row.TOKNOK1 === '99' || row.TOKNOK1 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TOKNOK1));}
            break; 
          case 2:
            if (row.TOKNOK2 === '00' || row.TOKNOK2 === '99' || row.TOKNOK2 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TOKNOK2));}
            break;
          case 3:
            if (row.TOKNOK3 === '00' || row.TOKNOK3 === '99' || row.TOKNOK3 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TOKNOK3));}
            break;
          case 4:
            if (row.TOKNOK4 === '00' || row.TOKNOK4 === '99' || row.TOKNOK4 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TOKNOK4));}
            break;
          case 5:
            if (row.TOKNOK5 === '00' || row.TOKNOK5 === '99' || row.TOKNOK5 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TOKNOK5));}
            break; 
          case 6:
            if (row.TOKNOK6 === '00' || row.TOKNOK6 === '99' || row.TOKNOK6 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TOKNOK6));}
            break;
          case 7:
            if (row.TOKNOK7 === '00' || row.TOKNOK7 === '99' || row.TOKNOK7 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.TOKNOK7));}
            break;
          default:
            break;
        };
      }
    }else if (row.NKIKBN === '2'){        // "2":便別納期を使用
      switch(bin)
      {
        case 1:
          if (row.BINNKI1 === '00' || row.BINNKI1 === '99' || row.BINNKI1 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.BINNKI1));}
          break; 
        case 2:
          if (row.BINNKI2 === '00' || row.BINNKI2 === '99' || row.BINNKI2 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.BINNKI2));}
          break;
        case 3:
          if (row.BINNKI3 === '00' || row.BINNKI3 === '99' || row.BINNKI3 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.BINNKI3));}
          break;
        case 4:
          if (row.BINNKI4 === '00' || row.BINNKI4 === '99' || row.BINNKI4 === null){errflg = -1;}else{htt.NHNYMD = await calcNhnymd(Number(row.BINNKI4));}
          break;
        default:
          break;
      };
    }

    if (errflg !== 0){
      setJAN1clr('#f59d9d');
      inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('入力された発注日での発注は行えません。'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return false;
    }

    // １）「便」欄の入力チェック
    console.log('bin:%o',bin);
    switch(bin)     // 自動発注状態01:対象　02:対象外 03:店舗停止 04:本部停止 　
    {
      case '1':
        if (row.EOSCDE === ''){errflg = -1;}  // 便指定無い商品はEOSCDE1も無い為、EOSCDEをチェックする
        break; 
      case '2':
        if (row.EOSCDE2 === ''){errflg = -1;}
        break;
      case '3':
        if (row.EOSCDE3 === ''){errflg = -1;}
        break;
      case '4':
        if (row.EOSCDE4 === ''){errflg = -1;}
        break;
      default:
        break;
    };
    if (errflg !== 0){
      setBINclr('#CCCCCC');
      inBIN.current.focus();   // 2024/06/14 focus対応漏れ
      window.alert('入力された便の発注は出来ません。'); 
      timeRef.current.reTimer();
      setChkflg(2);
      return false;
    }

    // ２）締め時間のチェック
    console.log('C)	締め時間のチェック 開始')
    //  ①部門、部門と商品マスタAU、スキャンコード、発注区分で絞め時間マスタ参照し
    //  指定されてる締め時間を過ぎてる場合、エラー
    //  ※発注日がシステム日付当日の場合のみチェック
    const gethttdat3 = await fetch('/TCSH0101/SH_SIM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({htt:htt, SIMKBN:'3' })});
    const simdat_3 = await gethttdat3.json();
    if(simdat_3.CNT !== 0) {
      console.log('simdat_3.CNT:%o',simdat_3.CNT);
      htt.SIMTIM = simdat_3.ret[0].SIMTIM       // 締時間               
      setHttItem(htt);
      console.log('データセットhtt SIMKBN3:%o',htt);
    }else{
      const gethttdat2 = await fetch('/TCSH0101/SH_SIM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({htt:htt, SIMKBN:'2' })});
      const simdat_2 = await gethttdat2.json();
      if(simdat_2.CNT !== 0){
        console.log('simdat_2.CNT:%o',simdat_3.CNT);
        htt.SIMTIM = simdat_2.ret[0].SIMTIM       // 締時間               
        setHttItem(htt);
        console.log('データセットhtt SIMKBN2:%o',htt);
      }else{
        const gethttdat1 = await fetch('/TCSH0101/SH_SIM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({htt:htt, SIMKBN:'1' })});
        const simdat_1 = await gethttdat1.json();
        if(simdat_1.CNT !== 0){
          console.log('simdat_1.CNT:%o',simdat_3.CNT);
          htt.SIMTIM = simdat_1.ret[0].SIMTIM       // 締時間               
          setHttItem(htt);
          console.log('データセットhtt SIMKBN1:%o',htt);
        }        
      }
    }

    // ３）発注区分違い発注済みチェック
    // console.log('D)	発注区分違い発注済みチェック 開始')
    // let l_httkbn = '';
    // let l_httkbnnm = '';
    // if (httkbn === '1'){
    //   l_httkbn = '2';
    //   l_httkbnnm = '特売';
    // } else if (httkbn === '2'){
    //   l_httkbn = '1';
    //   l_httkbnnm = '定番';
    // }

    let sJAN = '';
    if (htt.HTT_CDE === '2')
      {
        sJAN = htt.EOSCDE;
      }else{
        sJAN = htt.JANCDE;
      }
    
    // const gethtjdat = await fetch('/TCSH0101/SH_HTJ_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    // body: JSON.stringify({htt:htt ,NHNYMD2:htt.NHNYMD,JANCDE:sJAN,httkbn:l_httkbn})});
    // const htjdat = await gethtjdat.json();
    // if(htjdat.CNT !== 0) {
    //   console.log('発注予約データget:%o',htjdat);
    //   if(!window.confirm('すでに' + l_httkbnnm + '発注済み。発注を続けますか？')){
    //     onClick_FooterButton_Clear();
    //     return false;
    //   } 
    // }

    // ４）発注区分同一、バイヤー店舗発注不可チェック
    console.log('F)	発注区分同一、バイヤー店舗発注不可チェック 開始')
    const gethtjdat2 = await fetch('/TCSH0101/SH_HTJ_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({htt:htt ,NHNYMD2:htt.NHNYMD,JANCDE:sJAN,httkbn:httkbn})});
    const htjdat2 = await gethtjdat2.json();
    for(const key in htjdat2.ret) {
      if(htjdat2.ret[key].SSIKBN==='2'){
        inJAN1.current.focus();   // 2024/06/14 focus対応漏れ
        window.alert('バイヤー発注済み。店舗発注修正不可商品です。');
        timeRef.current.reTimer();
        return false;
      }
    }

    // ５）チェック問題無しの場合、既存発注の呼び出し

    // 終了処理
    setBINclr('#CCCCCC');
    setChkflg(0);
    clock2Ref.current.timerCheck(htt.SIMTIM); // タイマー処理
    return true;
  };
  
  
  // 発注入力
  const onKeyDown_SUU = async(inputRef, inputNxRef) => {
    // check
    const htt = await JSON.parse(JSON.stringify(httItem));
    console.log('発注入力データ:%o',htt);
    console.log('商品情報データ:%o',rowItem);
    console.log('発注数:%o',suu);
    
    let errflg = 0;

    //  ②締切時間　５分前・１分前の注意喚起画面表示
    const ndate = new Date();
    let today = ndate.getFullYear() + ('0'+(ndate.getMonth()+1)).slice(-2) + ('0'+ndate.getDate()).slice(-2)
    let yyyy = ndate.getFullYear();                   //2022
    let mm = ('0'+(ndate.getMonth()+1)).slice(-2);    //02
    let dd = ('0'+ndate.getDate()).slice(-2);         //05    
    let hh = htt.SIMTIM.slice(0,2);
    let nn = htt.SIMTIM.slice(-2);
    let ss = '00';
    let ymdhms = yyyy+'/'+mm+'/'+dd+' '+hh+':'+nn+':'+ss;
    
    //S SK-0039-002 2024/7/8           TCI)M.K【先日付発注の場合でも締切エラーが発生】
    // let ret = CloseTimeCheck(ymdhms)
    // if (today == state.HTTYMD){
    //   if (ret === false){
    //     setChkflg(2);
    //     return false;
    //   }
    // }
    if (today == state.HTTYMD){
      let ret = CloseTimeCheck(ymdhms)
      if (ret === false){
        setChkflg(2);
        return false;
      }
    }    
    //E SK-0039-002

    //S SK-0060-015 2024/8/6           TCI)M.K【課題No.84：何もしないでEnterはキャンセル扱い】
    // //S SK-0034-003 2024/7/3           TCI)M.K【Enter連打で不正なデータが作成される】
    // // if (htt.SHNID === ''||suu ==='' ) {
    // if (htt.SHNID === ''||suu ===''||htt.NHNYMD === '') {
    // //E SK-0034-003
    if (htt.SHNID === ''||htt.NHNYMD === '') {
    //E SK-0060-015
      window.alert('登録するデータが存在しません。'); 
      timeRef.current.reTimer();
      return;
    }

    // １）連続した数字の発注数チェック
    let maemoji = '';
    for (var i = 0; i < suu.length; ++i) {
      console.log((i + 1) + '文字目: ' + suu.charAt(i));
      if(maemoji === suu.charAt(i)){
        errflg = -1;
      }else{
        maemoji = suu.charAt(i)
      }
    }
    console.log( 'errflg: ' + errflg);
    if (errflg !== 0){
      // window.alert('発注数に連続した値が入力されています。\n発注数を確定しますか？  '); 
      if (window.confirm('発注数に連続した値が入力されています。\n発注数を確定しますか？')) { 
      }else{
        setSUUclr('#f59d9d');
        inSHNMEI.current.focus();
        inputRef.current.focus();
        inputRef.current.select();
        return;
      }
      // await showDialog('発注数に連続した値が入力されています。発注数を確定しますか？');
      // if (dialogBtnFlg !== true) { 
      //   inSHNMEI.current.focus();
      //   inputRef.current.focus();
      //   return; 
      // }
    }

    // ２）発注単位ケースの発注数のチェック(畜産のみ)
    if(state.BMNCDE === '1120' ){
      if(rowItem.HTTTNI===''){// ★テーブル構造見直し依頼中★後で見直す
        if (window.confirm('発注単位はケースです。\n２ケース以上の発注を行いますか？')) { 
        }else{
          inSHNMEI.current.focus();
          inputRef.current.focus();
          inputRef.current.select();
          return;
        }
      }
    }

    // ３）発注数量が多い場合のチェック１（バーコードを間違えて読んだ場合）
    // ★既存の仕組み流用？今井さんに確認する

    // ３）発注数量が多い場合のチェック２
    if(suu.length >= 5){
      setSUUclr('#f59d9d');
      window.alert('発注数の桁数が設定値を超えています。'); 
      timeRef.current.reTimer();
      inputRef.current.select();
      // setChkflg(2);
      return;
    }

    //S SK-0060-016 2024/8/6           TCI)M.K【課題No.84：何もしないでEnterはキャンセル扱い】
    // 下記の場合キャンセル扱い
    // ・画面.発注数=発注データテーブル.発注数の場合、もしくは
    // ・画面.発注数=''の場合
    if (suu === '' || suu === getsuu){
      console.log('★キャンセル入力：%o',suu,getsuu);
      setSUUclr('#ffff00');
      onClick_FooterButton_Clear();
      return;
    }
    //E SK-0060-016

    // ４．データベース更新
    // [データの更新を行います。よろしいですか？]は後で外す
    //S SK-0004-007 2024/6/13           TCI)M.K【更新を行います表示は不要】
    // if ((window.confirm('データの更新を行います。よろしいですか？'))) { 
    //S SK-0004-007 2024/6/13           TCI)M.K【更新を行います表示は不要】
      console.log('トータルチェック 開始');
      if (chkflg !== 0){
        window.alert('全てのエラーが解消してください。');
        timeRef.current.reTimer();
        inputRef.current.select();
        return;
      }
      console.log(htt)
      // データの更新
      const response = await fetch('/TCSH0101/ReplaceHttDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
        header:htt,
        bin:bin,
        httkbn:httkbn,
        suu:suu,
        add_prg_id:'TCSH0101'
      })});

      const data = await response.json();
      if (data.res === '-1') {
        window.alert('発注データ登録エラー'); //TCGE0024：
        timeRef.current.reTimer();
        inputRef.current.select();
        return;
      }else{
        // データの更新２（一旦投げっぱなし。エラー処理無し）
        const response2 = await fetch('/TCSH0101/ReplaceHtjDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
          header:htt,
          bin:bin,
          httkbn:httkbn,
          suu:suu,
        })});
        // 正常終了
        setSUUclr('ffff00');
        if (SKUflg === 1)
        {
          if ((window.confirm('SKU画面を再表示しますか？'))) { 
            onClick_FooterButton_SKU();
            return;
          }else{
            setSKUFlg(0);
          }
        }
        onClick_FooterButton_Clear();
        return;
      }
    //S SK-0004-008 2024/6/13           TCI)M.K【更新を行います表示は不要】
    // } else {
    //   inputRef.current.focus();
    //   inputRef.current.select();
    // }
    //S SK-0004-008
  };

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    resObj['& .MuiInputBase-input'] = {
      fontSize: '1.5rem', // フォントサイズの指定
      height: 'auto',    // 高さを自動調整
      minHeight: '1.5em',  // 最低高さを3行分に設定
      lineHeight: '1.5em', // 行の高さを設定
    };
    return resObj;
  }

  const cssInputOutlineSetwidth2 = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    resObj['& .MuiInputBase-input'] = {
      fontSize: '1.0rem', // フォントサイズの指定
      height: 'auto',    // 高さを自動調整
      minHeight: '4.5em',  // 最低高さを3行分に設定
      lineHeight: '1.5em', // 行の高さを設定
    };
    resObj['& .MuiOutlinedInput-root'] = {
      padding: '3px', // パディングを5に設定
      '& fieldset': {
        borderColor: 'black', // ボーダー線の色を黒に設定
      },
    };    
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

    // imgCellY（予定用）
  const imgCellY = (params) => {
    // console.log(params);　// ここでログ出すとループする
    let clr = '#ffffff';
    let clr2 = '#ffffff';
    let yyyy = String(httItem.NHNYMD).substring(0,4);
    let mm = String(httItem.NHNYMD).substring(4,6)-1;
    let dd = String(httItem.NHNYMD).substring(6,8);
    let dH = new Date(yyyy,mm,dd);
    // let dH = new Date('2024','4','17');
    let day = dH.getMonth() + 1;
    let cflg = 0;
    day = day + '/' + dH.getDate();
    // console.log('予定:'+params.colDef.headerName);

    if (params.field.includes(ydayD_add)) //土曜日
      {
        clr = '#DCE6F1';
        clr2 = '#DCE6F1';
        cflg = 2;
      }
    if (params.field.includes(ydayN_add)) //日曜日
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 3;
      }
    if (params.field.includes(ysday1)) //1日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 1;
      }
    if (params.field.includes(ysday2)) //2日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 2;
      }
    if (params.field.includes(ysday3)) //3日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 3;
      }
    if (params.field.includes(ysday4)) //4日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 4;
      }
    if (params.field.includes(ysday5)) //5日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 5;
      }
    if (params.field.includes(ysday6)) //6日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 6;
      }
    if (params.field.includes(ysday7)) //7日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 7;
      }


      if (params.colDef.headerName.includes(day))
        {
          clr = '#FFFF00';
          cflg = 1;
        }
  
    const style = {
      backgroundColor: `${clr}`,
      width: '100%', // セル全体を覆う
      height: '100%', // セル全体の高さを指定
      display: 'flex', // Flexboxを使用
      alignItems: 'center', // 垂直方向の中心に配置
      justifyContent: 'center', // 水平方向の中心に配置
      fontSize: '1.4em',
      padding:'0px'
    };
    const style2 = {
      backgroundColor: `${clr2}`,
      width: '100%', // セル全体を覆う
      height: '100%', // セル全体の高さを指定
      display: 'flex', // Flexboxを使用
      alignItems: 'center', // 垂直方向の中心に配置
      justifyContent: 'center', // 水平方向の中心に配置
      fontSize: '1.4em',
      padding:'0px'
    };

    if (clr === '')
      {
        if (params.row.id === '1')
        {
          // １行目ならimg表示
          //S SK-0013-009 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
          // return (
          //   <div>
          //     <Box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></Box>
          //   </div>
          // )
          let resArray = params.value.split(",");
          if (resArray[1] === undefined){
            return (
              <div>
                <Box >
                  <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
                </Box>
              </div>
            )
          }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
            return (
              <div>
                <Box >
                  <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                  <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
                  <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                </Box>
              </div>
            )
          }else{
            return (
              <div>
                <Box >
                  <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                  <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
                  <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                </Box>
              </div>
            )
          }          
          //E SK-0013-009
        } else {
          // １行目以外なら
          return (
            <div>
              <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
            </div>
          )
        }
      }else
      {
        if (cflg === 1)   //納品日対応
          {
            if (params.row.id === '1')
              {
                //S SK-0013-010 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
                // return (
                //   <div style={style2}>
                //     <Box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></Box>
                //   </div>
                // )
                let resArray = params.value.split(",");
                if (resArray[1] === undefined){
                  return (
                    <div style={style2}>
                      <Box >
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
                      </Box>
                    </div>
                  )
                }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
                  return (
                    <div style={style2}>
                      <Box >
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                      </Box>
                    </div>
                  )
                }else{
                  return (
                    <div style={style2}>
                      <Box >
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                      </Box>
                    </div>
                  )
                }                
                //E SK-0013-010
              }
            if (params.row.id == parseInt(bin)+3)
              {
                return (
                  <div style={style}>
                    <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
                  </div>
                )
              }else{
                return (
                  <div style={style2}>
                    <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
                  </div>
                )
              }
          }else
          {
            if (params.row.id === '1')
              {
                // １行目ならimg表示
                //S SK-0013-011 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
                // return (
                //   <div style={style} >
                //     <Box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></Box>
                //   </div>
                // )
                let resArray = params.value.split(",");
                if (resArray[1] === undefined){
                  return (
                    <div style={style}>
                      <Box >
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
                      </Box>
                    </div>
                  )
                }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
                  return (
                    <div style={style}>
                      <Box >
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                      </Box>
                    </div>
                  )
                }else{
                  return (
                    <div style={style}>
                      <Box >
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
                        <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                      </Box>
                    </div>
                  )
                }                
                //E SK-0013-011
              } else {
                // １行目以外なら
    
                return (
                  <div  style={style}>
                    <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
                  </div>
                )
              }    
          }
        }
  };

  const imgCellJ = (params) => {
    // console.log(params);　// ここでログ出すとループする
    let clr = '#ffffff';
    let clr2 = '#ffffff';
    let yyyy = String(httItem.NHNYMD).substring(0,4);
    let mm = String(httItem.NHNYMD).substring(4,6)-1;
    let dd = String(httItem.NHNYMD).substring(6,8);
    let dH = new Date(yyyy,mm,dd);
    // let dH = new Date('2024','4','17');
    let day = dH.getMonth() + 1;
    let cflg = 0;
    day = day + '/' + dH.getDate();
    // console.log('予定:'+params.colDef.headerName);

    if (params.field.includes(jdayD_add)) //土曜日
      {
        clr = '#DCE6F1';
        clr2 = '#DCE6F1';
        cflg = 2;
      }
    if (params.field.includes(jdayN_add)) //日曜日
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 3;
      }
    if (params.field.includes(jsday1)) //1日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 1;
      }
    if (params.field.includes(jsday2)) //2日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 2;
      }
    if (params.field.includes(jsday3)) //3日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 3;
      }
    if (params.field.includes(jsday4)) //4日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 4;
      }
    if (params.field.includes(jsday5)) //5日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 5;
      }
    if (params.field.includes(jsday6)) //6日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 6;
      }
    if (params.field.includes(jsday7)) //7日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 7;
      }

    const style = {
      backgroundColor: `${clr}`,
      width: '100%', // セル全体を覆う
      height: '100%', // セル全体の高さを指定
      display: 'flex', // Flexboxを使用
      alignItems: 'center', // 垂直方向の中心に配置
      justifyContent: 'center', // 水平方向の中心に配置
      fontSize: '1.4em',
      padding:'0px'
    };
    const style2 = {
      backgroundColor: `${clr2}`,
      width: '100%', // セル全体を覆う
      height: '100%', // セル全体の高さを指定
      display: 'flex', // Flexboxを使用
      alignItems: 'center', // 垂直方向の中心に配置
      justifyContent: 'center', // 水平方向の中心に配置
      fontSize: '1.4em',
      padding:'0px'
    };

    if (params.row.id === '1')
      {
        // １行目ならimg表示
        //S SK-0013-011 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
        // return (
        //   <div style={style} >
        //     <Box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></Box>
        //   </div>
        // )
        let resArray = params.value.split(",");
        if (resArray[1] === undefined){
          return (
            <div style={style}>
              <Box >
                <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
              </Box>
            </div>
          )
        }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
          return (
            <div style={style}>
              <Box >
                <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
                <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
              </Box>
            </div>
          )
        }else{
          return (
            <div style={style}>
              <Box >
                <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
                <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
              </Box>
            </div>
          )
        }                
        //E SK-0013-011
      } else if(params.row.id === '3') {  // 2024/06/27 H.I
        // １行目以外なら
        return (
          <div  style={style}>
            <Typography style={{fontSize:'1.2em'}}>{(params.value)}</Typography>
          </div>
        )
      } else {
        // １行目以外なら

        return (
          <div  style={style}>
            <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
          </div>
        )
      }    
  };

  // imgCell（実績用）
  const imgCell = (params) => {
    let clr = '';
    let clr2 = '#ffffff';
    let yyyy = String(httItem.NHNYMD).substring(0,4);
    let mm = String(httItem.NHNYMD).substring(4,6)-1;
    let dd = String(httItem.NHNYMD).substring(6,8);
    let dH = new Date(yyyy,mm,dd);
    // let dH = new Date('2024','4','17');
    let day = dH.getMonth() + 1;
    let cflg = 0;
    day = day + '/' + dH.getDate();
    // console.log('予定:'+params.colDef.headerName);

    if (params.field.includes(jsday1)) //月曜日
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 1;
      }
    if (params.field.includes(jsday2)) //火曜日
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 2;
      }
    if (params.field.includes(jsday3)) //水曜日
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 3;
      }
    if (params.field.includes(jsday4)) //木曜日
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 4;
      }
    if (params.field.includes(jsday5)) //金曜日
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 5;
      }

    if (params.colDef.headerName.includes(day))
      {
        clr = '#FFFF00';
        cflg = 1;
      }

    const style2 = {
      backgroundColor: `${clr2}`,
      width: '100%', // セル全体を覆う
      height: '100%', // セル全体の高さを指定
      display: 'flex', // Flexboxを使用
      alignItems: 'center', // 垂直方向の中心に配置
      justifyContent: 'center', // 水平方向の中心に配置
      fontSize:'1.4em'
    };
  
    // console.log(params);　// ここでログ出すとループする
    if (params.row.id === '1')
    {
      // １行目ならimg表示
      //S SK-0013-012 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
      // return (
      //   <div style={style2}>
      //     <Box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></Box>
      //   </div>
      // )
      let resArray = params.value.split(",");
      if (resArray[1] === undefined){
        return (
          <div style={style2}>
            <Box >
              <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
            </Box>
          </div>
        )
      }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
        return (
          <div style={style2}>
            <Box >
              <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
            </Box>
          </div>
        )
      }else{
        return (
          <div style={style2}>
            <Box >
              <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
            </Box>
          </div>
        )
      }      
      //E SK-0013-012
    } else {
      // １行目以外なら
      return (
        <div style={style2} >
          <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
        </div>
      )
    }
  };

  // 実績の土曜日用
  const imgCell2 = (params) => {
    // console.log(params);　// ここでログ出すとループする
    let clr2 = '#DCE6F1';
    if (params.field.includes(jsday6)) //金曜日
      {
        clr2 = '#FFCCFF';
      }

    const style = {
      backgroundColor: `${clr2}`,
      width: '100%', // セル全体を覆う
      height: '100%', // セル全体の高さを指定
      display: 'flex', // Flexboxを使用
      alignItems: 'center', // 垂直方向の中心に配置
      justifyContent: 'center', // 水平方向の中心に配置
      fontSize:'1.4em'
    };

    if (params.row.id === '1')
    {  
      // １行目ならimg表示
      //S SK-0013-013 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
      // return (
      //   <div style={style}>
      //     <box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></box>
      //   </div>
      // )
      let resArray = params.value.split(",");
      if (resArray[1] === undefined){
        return (
          <div style={style}>
            <Box >
              <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
            </Box>
          </div>
        )
      }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
        return (
          <div style={style}>
            <Box >
              <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
            </Box>
          </div>
        )
      }else{
        return (
          <div style={style}>
            <Box >
              <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
            </Box>
          </div>
        )
      }
      //E SK-0013-013
    } else {
        // １行目以外なら
      return (
        <div style={style}>
          <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
        </div>
      )
    }
  };

  // 実績の日曜日用
  const imgCell3 = (params) => {
    // console.log(params);　// ここでログ出すとループする
    const style = {
      backgroundColor: '#FFCCFF',
      width: '100%', // セル全体を覆う
      height: '100%', // セル全体の高さを指定
      display: 'flex', // Flexboxを使用
      alignItems: 'center', // 垂直方向の中心に配置
      justifyContent: 'center', // 水平方向の中心に配置
      fontSize:'1.4em'
    };
    if (params.row.id === '1')
    {  
      // １行目ならimg表示
      //S SK-0013-014 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
      // return (
      //   <div style={style}>
      //     <box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></box>
      //   </div>
      // )
      let resArray = params.value.split(",");
      if (resArray[1] === undefined){
        return (
          <div style={style}>
            <Box >
              <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
            </Box>
          </div>
        )
      }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
        return (
          <div style={style}>
            <Box >
              <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
            </Box>
          </div>
        )
      }else{
        return (
          <div style={style}>
            <Box >
              <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
              <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
            </Box>
          </div>
        )
      }      
      //E SK-0013-014
    } else {
        // １行目以外なら
      return (
        <div style={style}>
          <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
        </div>
      )
    }
  };

  // グリッド列情報
  const columns /*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   // getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,          // 非表示にできないようにする
    //   sortable: false,          // ソートできないようにする
    //   hideSortIcons: false,     // ソートアイコンを表示しない
    //   resizable: false,         // リサイズできないようにする
    //   disableExport: false,     // エクスポートに含めないようにする
    //   disableReorder: false,    // 並べ替えできないようにする
    //   filterable: false,        // フィルタリングしないようにする
    //   disableColumnMenu: true  // カラムメニューを表示しない
    // } ,

    //IDは必ず必要みたい
    // {
    //   field: 'id',
    //   headerName: 'No.',
    //   align:'center',
    //   width: 50 ,
    //   editable: false ,         //編集可能:false
    //   sortable: false,          // ソートできないようにする
    //   disableColumnMenu: true,   // カラムメニューを表示しない
    // },
    {
      field: 'field0',
      headerName: '',
      headerAlign: 'center',
      align:'center',
      width: 72 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'field1',
      headerName: yday1,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   // <div>
      //     {/* <Box ><img src={(params.value)} alt="Logo" width="20" height="20" /></Box> */}
      //     {/* <Typography>{(params.value)}</Typography> */}
      //   // </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field2',
      headerName: yday2,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field3',
      headerName: yday3,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field4',
      headerName: yday4,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field5',
      headerName: yday5,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field6',
      headerName: yday6,
      type: 'number',
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field7',
      headerName: yday7,
      type: 'number',
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
  ];

  // グリッド列情報
  const columns2 /*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   // getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,          // 非表示にできないようにする
    //   sortable: false,          // ソートできないようにする
    //   hideSortIcons: false,     // ソートアイコンを表示しない
    //   resizable: false,         // リサイズできないようにする
    //   disableExport: false,     // エクスポートに含めないようにする
    //   disableReorder: false,    // 並べ替えできないようにする
    //   filterable: false,        // フィルタリングしないようにする
    //   disableColumnMenu: true  // カラムメニューを表示しない
    // } ,

    //IDは必ず必要みたい
    // {
    //   field: 'id',
    //   headerName: 'No.',
    //   align:'center',
    //   width: 50 ,
    //   editable: false ,         //編集可能:false
    //   sortable: false,          // ソートできないようにする
    //   disableColumnMenu: true,   // カラムメニューを表示しない
    // },
    {
      field: 'field0',
      headerName: jbtnname2,
      headerAlign: 'center',
      align:'center',
      width: 72 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'field1',
      headerName: jday1,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellJ(params)
    },
    {
      field: 'field2',
      headerName: jday2,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellJ(params)
    },
    {
      field: 'field3',
      headerName: jday3,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellJ(params)
    },
    {
      field: 'field4',
      headerName: jday4,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellJ(params)
    },
    {
      field: 'field5',
      headerName: jday5,
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellJ(params)
    },
    {
      field: 'field6',
      headerName: jday6,
      type: 'number',
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellJ(params)
    },
    {
      field: 'field7',
      headerName: jday7,
      type: 'number',
      headerAlign: 'center',
      align:'center',
      width: 106 ,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellJ(params)
    },
  ];

   const HighlightCell = styled('div')(({ theme }) => ({
    backgroundColor: '#FFFF00', // 蛍光黄色の背景
  }));

  const getCellClassName = (params) => {
    if (params.field === 'age' && params.id === 2) {
      return 'highlight-cell';
    }
    return '';
  };

  const globalStyles = <GlobalStyles styles={{
    '.highlight-cell': {
      backgroundColor: '#FFFF00',
    }
  }} />;

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.7)',
    // 他のスタイルプロパティをここに追加することもできます
    grid: {
      // x-data-gridの全体的な縮小
      '& .MuiDataGrid-root': {
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-cell':{
        border: 'solid 1px rgba(0, 0, 0, 1) !important',
        padding: '0',
      },
      '.MuiDataGrid-columnHeader':{
        padding: '0',
      },
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(0, 0, 0, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
        padding: '0',
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(0, 0, 0, 1) !important',
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'visible',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
        border: 'solid 1px rgba(0, 0, 0, 1) !important',
        fontSize:'1.2em',
        padding: '0',
        // backgroundColor: '#65b2c6', // 2024/03/28追加
        [`& .MuiDataGrid-columnHeader[aria-label="${ydayD}"]`]: {
          backgroundColor: '#DCE6F1', // field6列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${ydayN}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${ysHday1}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${ysHday2}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${ysHday3}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${ysHday4}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${ysHday5}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${ysHday6}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${ysHday7}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      },
      // 選択状態を黄色にする 2023/12/04 add imai
      '.MuiDataGrid-row.Mui-selected':{
        background: '#ffff00',
        '&:hover': {
          background: '#ffff00',       // ホバー時のバックグランドカラー
        },
      },
      [`.${gridClasses.cell}.cold`]: {
        backgroundColor: '#b9d5ff91',
        // color: '#1a3e72',
      },
      [`.${gridClasses.cell}.hot`]: {
        backgroundColor: '#ff943975',
        // color: '#1a3e72',
      },      
    }
  };
  
  const gridStyle2 = {
    transform: 'scale(0.7)',
    // 他のスタイルプロパティをここに追加することもできます
    grid: {
      // x-data-gridの全体的な縮小
      '& .MuiDataGrid-root': {
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-cell':{
        border: 'solid 1px rgba(0, 0, 0, 1) !important',
        padding: '0px',
      },
      '.MuiDataGrid-columnHeader':{
        padding: '0',
      },
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(0, 0, 0, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(0, 0, 0, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(0, 0, 0, 1) !important',
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
        border: 'solid 1px rgba(0, 0, 0, 1) !important',
        fontSize:'1.2em',
        // // backgroundColor: '#65b2c6', // 2024/03/28追加
        // '& .MuiDataGrid-columnHeader[data-field="field6"]': {
        //   backgroundColor: '#DCE6F1', // field6列のみ緑色
        // },
        // '& .MuiDataGrid-columnHeader[data-field="field7"]': {
        //   backgroundColor: '#FFCCFF', // field7列のみ緑色
        // },
        // backgroundColor: '#65b2c6', // 2024/03/28追加
        [`& .MuiDataGrid-columnHeader[aria-label="${jdayD}"]`]: {
          backgroundColor: '#DCE6F1', // field6列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jdayN}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday1}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday2}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday3}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday4}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday5}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday6}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday7}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      },
      // 選択状態を黄色にする 2023/12/04 add imai
      '.MuiDataGrid-row.Mui-selected':{
        background: '#ffff00',
        '&:hover': {
          background: '#ffff00',       // ホバー時のバックグランドカラー
        },
      },
      [`.${gridClasses.cell}.cold`]: {
        backgroundColor: '#b9d5ff91',
        // color: '#1a3e72',
      },
      [`.${gridClasses.cell}.hot`]: {
        backgroundColor: '#ff943975',
        // color: '#1a3e72',
      },      
    }
  };

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '0.7rem'
      },
      fontSize: '1.0rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (

    <Grid>
      <CustomAlert isVisible={isAlertVisible} message={alertMessage} onClose={closeAlert} />
      {/* <CustomDialog
          isVisible={isDialogOpen}        // 表示/非表示
          message={dialogMessage}         // 出力メッセージ
          onOk={handleOk}                 // OKボタン
          // onCancel={handleCancel}        // キャンセルボタン
        /> */}
      <CustomFormTCSH0111 
        isVisible={isSinopsInfoVisible} 
        message01={rowItem.JANCDE}        // JAN1
        message02={rowItem.JANCDE2}       // JAN2
        message03={rowItem.SHNMEIKN}      // 商品名
        message04={jdhttstcd}               // 自動発注状態
        message05={kjn_zai}               // 最低陳列量
        message06={''}                    // 最低発注数
        message07={cal_zai}               // 店舗在庫
        message08={''}                    // ランク
        message11={rowItem.TRIHBN}        // 取引品番
        message12={rowItem.TAGSIZMEI}     // サイズ
        message13={rowItem.TAGCOLMEI}     // カラー
        message14={rowItem.TAGSZNNM}      // シーズン
        message15={''}                    // 商品ID
        callergamen={'TCSH0101'}          // 呼び出し元画面
        onClose={closeSinopsInfo} 
        // onClear={clearSinopsInfo} 
        onReg={regSinopsInfo} 
        handleValueChange01={handleValueChange01}
        handleValueChange02={handleValueChange02}
        handleValueChangeEos={handleValueChangeEos}
        handleValueChange04={handleValueChange04}
        handleValueChange05={handleValueChange05}
        handleValueChange06={handleValueChange06}
        handleValueChange07={handleValueChange07}
        />      

      <CustomFormTCSH0120 
        isVisible={isShnInfoVisible} 
        message01={rowItem.EOSCDE}        // EOSコード
        message02={trihbn}                // 取引先品番
        message03={colmei}                // カラー名
        message04={sizmei}                // サイズ名
        message05={sznmn}                 // シーズン
        message06={sjhjancde}             // JANコード
        message07={rowItem.SIRCDE}        // 仕入先コード
        message08={rowItem.SIRMEIK.trim()}       // 仕入先名
        message09={rowItem.GNT}           // 原単価
        message10={rowItem.BTN}           // 売単価
        message11={neiri}                 // 値入率
        message12={rowItem.HTTTNIKNJ}     // 発注単位
        message13={rowItem.IRISUU}        // 入数
        message14={symnsu}                // 賞味日数
        message15={rowItem.BMNCDE+'-'+rowItem.ASTCDE+'-'+rowItem.TAGLINCDE+'-'+rowItem.TAGCLSCDE} // 部門-AU-ライン-クラス
        message16={rowItem.HTTSTD}        // 発注開始日
        message17={rowItem.HTTEND}        // 発注終了日
        onClose={closeShnInfo} 
        />
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "600px",
          width: "800px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <Stack direction='row' sx={{mb:'10px',mt:'-20px'}}>
            <Typography variant={'h3'} sx={{ m: '1px',mt:'1px',fontSize:11,width:330,mr:'1px'}}>
              {state.MISES}:{state.BMNCDE} {state.MISEMEI}:{state.BRUMEI} 
            </Typography>
            <Typography variant={'h3'} sx={{ m: '-330px',mt:'15px',fontSize:11,width:330,mr:'1px'}}>
              {state.JGNMEIKNJ}
            </Typography>
            <Typography variant={"h6"} sx={{ m: "10px",width:300 }}>
            スキャン発注（衣料）
            </Typography>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'17px',fontSize:11 ,width:200,mr:'1px'}}>
              { sdate }　
              {/* <Clock ClockTime={ClockTime}/>
              {servertime} |  */}
              <Clock2 ref={clock2Ref} />
            </Typography>
          </Stack>
        </Grid>
        <Stack direction='row' sx={{mb:'10px'}}>
          <Box>
            <NumericFormat  //JANCD1
              id={'JAN1'}
              inputRef={inJAN1}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='ＪＡＮ１'                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '15ch',background:inJAN1clr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 11), {background:inJAN1clr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.JANCDE}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disJan1}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              // onChange={(e)=>{
              //   const row = JSON.parse(JSON.stringify(rowItem));
              //   row.JANCDE = e.target.value;
              //   setRowItem(row);
              //   setChkJAN1('0');
              //   // setDisRow(true);
              // }}
              onChange={(e) => onChangeJAN1(e)}
              onKeyDown={(e) => onKeyDown(e.key,inJAN1,inJAN1,inSUU)}
              onFocus={(e) => e.target.select()}
              // onBlur={() => onblur(inJAN1)}
              />
            { <NumericFormat  //JANCD2
              id={'JAN2'}
              inputRef={inJAN2}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='ＪＡＮ２'                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '15ch',background:inJAN1clr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 11), {background:inJAN2clr},{mt:'10px'})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.JANCDE2}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disJan2}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              // onChange={(e)=>{
              //   const row = JSON.parse(JSON.stringify(rowItem));
              //   row.JANCDE2 = e.target.value;
              //   setRowItem(row);
              //   setChkJAN1('0');
              //   // setDisRow(true);
              // }}
              onChange={(e) => onChangeJAN2(e)}
              onKeyDown={(e) => onKeyDown(e.key,inJAN2,inJAN2,inSUU)}
              // onFocus={(e) => onMyFocus(e)}
              // onBlur={() => onblur(inJAN1)}
              /> }
          </Box>
              　
        <TextField  //商品名
          id={'SHNMEI'}
          inputRef={inSHNMEI}                   //項目名定義？
          color='success'
          label='商品名'                         //textboxの左上に表示するラベル
          multiline
          maxRows={3}
          variant='outlined'                  //枠の表示方法
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          // sx={{
          //   '& .MuiInputBase-input': {
          //     overflow: 'hidden',
          //     textOverflow: 'ellipsis',
          //     whiteSpace: 'nowrap',
          //   },
          //   width: '35ch'
          // }}                 //textboxの幅
          // value={rowItem.SHNMEIKN + '\n' + rowItem.KIKAKUKN.trim() + '　' + rowItem.HTTTNI  + '　' + rowItem.IRISUU + '　' + formatCurrency(Number(rowItem.BTN)) }               //初期値  
          sx={Object.assign({}, cssInputOutlineSetwidth2(styles.cssInputOutline, 25),{ml:'-90px'})}  //2023/11/07 入力項目の表示内容拡大
          value={rowItem.SHNMEIKN === '' ? '' : rowItem.SHNMEIKN + '\n' + rowItem.KIKAKUKN.trim() + '　' + rowItem.HTTTNIKNJ  + '　入数' + rowItem.IRISUU + '　' + formatCurrency(Number(rowItem.BTN)) }               //初期値
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 200,style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
          // onKeyDown={(e) => onKeyDown(e.key,inSHNMEI,inJAN1,inSUU)}
          // onFocus={(e) => onMyFocus(e)}
        />　
          <Box >
            <NumericFormat  //便
              id={'BIN'}
              inputRef={inBIN}                    //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='便'                          //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:inBINclr})}  //2023/11/07 入力項目の表示内容拡大
              value={bin}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              // onChange={(e)=>{
              //   const htt = JSON.parse(JSON.stringify(httItem));
              //   htt.BIN = e.target.value;
              //   setHttItem(htt);
              //   setChkBIN('0');
              //   setBin(e.target.value);//同じものを入れる
              //   // setDisRow(true);
              // }}
              // onKeyDown={(e) => onKeyDown(e.key,inBIN,inJAN1,inSUU)}
              // onFocus={(e) => onMyFocus(e)}
              // onBlur={() => onblur(inJAN1)}
              />　
              </Box >              
              <Box >
              </Box >              
          <Box>
            <NumericFormat  //発注数量
              id={'SUU'}
              inputRef={inSUU}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              //S SK-0079-003 2024/10/4           TCI)M.K【課題No.105：発注数量のマイナスを制限】
              allowNegative={false}               // true を指定すると負数
              //E SK-0079-003
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label={titleHTTKBN}                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 6), {background:inSUUclr})}  //2023/11/07 入力項目の表示内容拡大
              value={suu}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disSUU}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 6,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                setSUUclr('#ffff00');
                const htt = JSON.parse(JSON.stringify(httItem));
                htt.HTTSUU = e.target.value;
                setHttItem(htt);
                setSuu(e.target.value);//同じものを入れる
                // setDisRow(true);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inSUU,inSUU,inJAN1)}
              // onFocus={(e) => onMyFocus(e)}
              // onBlur={() => onblur(inJAN1)}
              />
              {/* <Box >
                <Button
                  variant='contained'
                  color='success'
                  sx={{width:100,mr:'60px'}}
                  onClick={(e) => onClick_FooterButton_KbnChange()}
                  >
                  {'区分変更'}
                </Button>
              </Box > */}
            </Box>
      </Stack>
      <br/>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Table sx={{border:0,maxWidth:200,mt:'-28px'}} aria-label="spanning table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >発注日</TableCell>
                  <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}                   width="60px"                   >
                      {httymd}
                    </TableCell>
                </TableRow>
              </TableBody>
          </Table>
          <Table sx={{border:0,maxWidth:200,mt:'15px'}} aria-label="spanning table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >取引先品番</TableCell>
                <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >
                  {rowItem.TRIHBN}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br/>
        <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >カラー名</TableCell>
                <TableCell align="center"                     sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >
                  {rowItem.TAGCOLMEI}
                  </TableCell>
              </TableRow>
            </TableBody>  
        </Table>
        <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >サイズ名</TableCell>
                <TableCell align="center"                     sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >
                  {rowItem.TAGSIZMEI}
                  </TableCell>
              </TableRow>
            </TableBody>  
        </Table>
        <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >シーズン</TableCell>
                <TableCell align="center"                     sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >
                  {rowItem.TAGSZNNM}
                  </TableCell>
              </TableRow>
            </TableBody>  
        </Table>
         <br/>
         <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >自動発注状態</TableCell>
                <TableCell align="center"                     sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >
                  {jdhttst}
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >基準在庫</TableCell>
                  <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >
                    {kjn_zai}
                  </TableCell>
                </TableRow>
              </TableBody>
          </Table>
          <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >店舗在庫</TableCell>
                  <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >
                    {cal_zai}
                  </TableCell>
                </TableRow>
              </TableBody>
          </Table>
          <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >自動発注数</TableCell>
                  <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px" >
                    {jdhttsuu}
                  </TableCell>
                </TableRow>
              </TableBody>
          </Table>
          {/* 2024/06/27 H.I start */}
          <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{padding:'0px'}}>
                    <Button
                      variant='contained'
                      color='success'
                      sx={{width:95,mr:'95px',mt:'10px'}}
                      onClick={(e) => onClick_FooterButton_NextWeek()}
                      >
                      {jbtnname}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
          </Table>
          {/* 2024/06/27 H.I end */}

          <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
              <TableBody>
                <TableRow  sx={{borderBottom: 0}}>
                  <TableCell sx={{padding:'0px'}}>
                    <Button
                      variant='contained'
                      color='success'
                      sx={{width:120,mr:'5px',mt:'10px'}}
                      onClick={(e) => onClick_FooterButton_sinops()}
                      >
                      {'在庫情報修正'}
                    </Button>
                  </TableCell>
                  <TableCell sx={{padding:'0px'}}>
                    <Button
                      variant='contained'
                      color='success'
                      sx={{width:70,mr:'5px',mt:'10px'}}
                      onClick={(e) => onClick_FooterButton_SKU()}
                      disabled={disBtnSKU}                     //使用可／不可
                      >
                      {'SKU'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
          </Table>

          <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
              <TableBody>
                <TableRow>
                <TableCell sx={{padding:'0px'}}>
                    <Button
                      variant='contained'
                      color='success'
                      sx={{width:95,mr:'5px',mt:'10px'}}
                      onClick={(e) => onClick_FooterButton_shn()}
                      >
                      {'商品情報'}
                    </Button>
                  </TableCell>
                  <TableCell sx={{padding:'0px'}}>
                      <Button
                        variant='contained'
                        color='success'
                        sx={{width:95,mr:'5px',mt:'10px'}}
                        onClick={(e) => onClick_FooterButton_Clear()}
                        >
                        {'再入力'}
                      </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
          </Table>

          <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{padding:'0px'}}>
                    <Button
                      variant='contained'
                      color='success'
                      sx={{width:95,mr:'5px',mt:'10px'}}
                      onClick={(e) => onClick_FooterButton_Back()}
                      disabled={disBtnBack}                    //使用可／不可
                      >
                      {'戻る'}
                    </Button>
                  </TableCell>
                  <TableCell sx={{padding:'0px'}}>
                    <Button
                      variant='contained'
                      color='success'
                      sx={{width:95,mr:'5px',mt:'10px'}}
                      onClick={(e) => onClick_FooterButton_Next()}
                      disabled={disBtnSend}                    //使用可／不可
                      >
                      {'送信'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
          </Table>


        </Grid>

         {/* 2024/06/27 H.I start */}
        <Grid item xs={8}>
          {/* <Box className="dataGridList" style={gridStyle} sx={{ display: 'flex', justifyContent: 'flex-start',height: 257, width: '576px',padding:'0px',ml:'-90px',mt:'-70px' }}> */}
          {/* <Box className="dataGridList" style={gridStyle} sx={{ display: 'flex', justifyContent: 'flex-start',height: 295, width: '699px',padding:'0px',ml:'-171px',mt:'-70px' }}> */}
          <Box className="dataGridList" style={gridStyle} sx={{ display: 'flex', justifyContent: 'flex-start',height: 295, width: '818px',padding:'0px',ml:'-171px',mt:'-70px' }}>
            <DataGrid
              sx={gridStyle.grid }     // --> 不具合対応のためcssで罫線を引く
              rows={rows}
              columns={columns}
              density='compact'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 6,
                  },
                },
              }}
              pageSizeOptions={[6]}
              // checkboxSelection
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引
              getRowClassName={(params) => {
                if (params.row.DELFLG === '1') {
                  // 削除済明細にクラスを設定する
                  return 'row-deactive'
                }
                return '';
              }}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={(RowIds) => {
                const diffRows = rows.filter(x =>RowIds.indexOf(x.id) != -1);
                const delRows = diffRows.filter(x => x.DELFLG == '1');
                if (delRows.length > 0) {
                  // 削除済明細の場合はクリックされた際に選択状態にしない
                  return;
                }
                setSelectionModel(RowIds);
              }}
              paginationModel={pagenationModel}
              onPaginationModelChange={(model) => {
                console.log(model);
                setPagenationModel(model);
              }}
              hideFooter
            />
          </Box>
          {/* <Box className="dataGridList" style={gridStyle2} sx={{ display: 'flex', justifyContent: 'flex-start',height: 329, width: '576px',padding:'0px',ml:'-90px',mt:'-85px' }}> */}
          {/* <Box className="dataGridList" style={gridStyle2} sx={{ display: 'flex', justifyContent: 'flex-start',height: 365, width: '699px',padding:'0px',ml:'-171px',mt:'-85px' }}></Box> */}
          <Box className="dataGridList" style={gridStyle2} sx={{ display: 'flex', justifyContent: 'flex-start',height: 365, width: '818px',padding:'0px',ml:'-171px',mt:'-85px' }}>
            <DataGrid
              sx={gridStyle2.grid }     // --> 不具合対応のためcssで罫線を引く
              rows={rows2}
              columns={columns2}
              density='compact'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 8,
                  },
                },
              }}
              pageSizeOptions={[8]}
              // checkboxSelection
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引く
              getRowClassName={(params) => {
                if (params.row.DELFLG === '1') {
                  // 削除済明細にクラスを設定する
                  return 'row-deactive'
                }
                return '';
              }}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={(RowIds) => {
                const diffRows = rows2.filter(x =>RowIds.indexOf(x.id) != -1);
                const delRows = diffRows.filter(x => x.DELFLG == '1');
                if (delRows.length > 0) {
                  // 削除済明細の場合はクリックされた際に選択状態にしない
                  return;
                }
                setSelectionModel(RowIds);
              }}
              paginationModel={pagenationModel}
              onPaginationModelChange={(model) => {
                console.log(model);
                setPagenationModel(model);
              }}
              hideFooter
              />
          </Box>
        </Grid>
         {/* 2024/06/27 H.I end */}
        {/* <Grid item xs={2}>  2024/06/27 H.I
          <Box >
                <Button
                  variant='contained'
                  color='success'
                  sx={{width:100,mt:'195px',ml:'20px'}}
                  onClick={(e) => onClick_FooterButton_NextWeek()}
                  >
                  {jbtnname}
                </Button>
              </Box >
        </Grid> */}
      </Grid>
        {/* <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'-40px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'35px'}}
              onClick={(e) => onClick_FooterButton_Back()}
              disabled={disBtnBack}                    //使用可／不可
              >
              {'戻る'}
            </Button>
          </Box >
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:120,mr:'35px'}}
              onClick={(e) => onClick_FooterButton_sinops()}
              >
              {'在庫情報修正'}
            </Button>&nbsp;
          </Box >
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'35px'}}
              onClick={(e) => onClick_FooterButton_shn()}
              >
              {'商品情報'}
            </Button>&nbsp;
          </Box >
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'35px'}}
              onClick={(e) => onClick_FooterButton_Clear()}
              >
              {'再入力'}
            </Button>&nbsp;
          </Box >
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'35px'}}
              onClick={(e) => onClick_FooterButton_SKU()}
              disabled={disBtnSKU}                     //使用可／不可
              >
              {'ＳＫＵ'}
            </Button>&nbsp;
          </Box>
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'20px'}}
              onClick={(e) => onClick_FooterButton_Next()}
              disabled={disBtnSend}                    //使用可／不可
              >
              {'送信'}
            </Button>&nbsp;
          </Box>
        </Box> */}
        {/* <p>{ state.usrnm }</p> */}
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSH0101;
