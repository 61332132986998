import { useRef,useState,useContext,useEffect } from 'react';
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { NumericFormat } from 'react-number-format';  //数値入力
import './common.css';
//S SK-0050-002 2024/7/23           TCI)M.K【要望：ログイン制御】
import { LOCAL_CONSTANT } from './constants.ts';
//E SK-0050-002

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

export const Login = () => {

  const navigate = useNavigate();
  const keyDisabled = useRef(false);

  const {state, dispatch} = useContext(UserContext);
  
  //従業員マスタ検索用
  const [itID,setItid] = useState('');
  const [itPWD,setItpwd] = useState('');
  const [itBMNCDE,setBMNCDE] = useState('');

  const inputID = useRef(null);
  const inputPWD = useRef(null);
  const inBMNCDE = useRef(null);

  //従業員マスタ店検索用
  const [dbdataB, setDataB] = useState({KSACDE:'',JGNCDE:'',    //DBからデータ取得用（まとめてデータ取得）（非同期なので注意）
  MISE:'',RCNT:0});  //JSON形式
  
  const [alert, setAlert] = useState('');
  // 2024/09/02 ここから テスト期間9/3～10/31までコメント表示
  // 11/1より外す
  const [alert2, setAlert2] = useState('テスト中です。まだ使用出来ません');
  // 2024/09/02 ここまで

  const [rows, setRows] = useState([]);

  //S SK-0076-001 2024/9/30           TCI)M.K【課題No.101：パスワード入力全角不可対応】
  const REGEX_NUMBER = /^[0-9]*$/g;
  const onChangeFilterNumber = (event, setterFunction) => {
      let input = event.target.value;
      if (input.match(REGEX_NUMBER)) {
          setterFunction(input);
          setAlert('ユーザーID、パスワードを入力して下さい。');
          return true;
      } else {
          setAlert('パスワードは半角数字で入力してください。');
          return false;
      }
  };
  //E SK-0076-001
    
  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  //即更新用
  useEffect(() => {
    // console.log('useEffect:%o',dbdataB);
  }, [dbdataB]);
  
  useEffect (()=>{
    // イベントリスナーを登録
    // window.addEventListener('keydown', (e) => {
    //   if (e.key==='F1')
    //   {
    //     e.preventDefault();
    //     navigate('/');
    //   }
    //   if (e.key==='F5')
    //   {
    //     e.preventDefault();
    //     navigate('/');
    //   }
    // });
    // console.log('TCSA0000 モード:%o',document.fullscreenElement);
    // if (!document.fullscreenElement){
    //   console.log('TCSA0000 モード:on');
    //   document.documentElement.requestFullscreen();
    // }
    // console.log('NODE_ENV:'+process.env.NODE_ENV);
    console.log('画面：%o',window.innerWidth,window.innerHeight);
    if((window.innerWidth===800 && window.innerHeight===829)
      ||(window.innerWidth===800 && window.innerHeight===1112)
      ||(window.innerWidth===800 && window.innerHeight===1077)
      ){        //Lenovo縦
      document.body.style.zoom='190%';
    }else if(window.innerWidth===1280 && window.innerHeight===349){ //Lenovo横
      document.body.style.zoom='110%';
    }else if(window.innerWidth===360 && window.innerHeight===592){  //Casio
      // document.body.style.zoom='105%';
      document.body.style.zoom='102%';
    }else if (window.innerHeight<=800){
      document.body.style.zoom='80%';
    }else{
      document.body.style.zoom='100%';
    }
    setAlert('ユーザーID、パスワードを入力して下さい。');
    // console.log('host名:'+window.location.hostname);
    // const userAgent = navigator.userAgent;
    // console.log("ユーザーエージェント: " + userAgent);
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      document.exitFullscreen();
    }
    
    // DBからデータ取得
    const data = getSL_AUT_MST();
    data.then(async result => {
      if (result != null) {
        // console.log('画面表示権限マスタ取得');
        // console.log(result);
        // rows作成
        let id = 0;
        let newRows = [];
        result.forEach(element => {
          newRows.push({ 
            id: id,
            REC_NO: element.REC_NO,
            GAMENNM: element.GAMENNM,
            AUT01: (element.AUT01),
            AUT02: (element.AUT02),
            AUT03: (element.AUT03),
            AUT04: (element.AUT04),
            AUT05: (element.AUT05),
            AUT06: (element.AUT06),
            AUT07: (element.AUT07),
            AUT08: (element.AUT08),
            AUT09: (element.AUT09),
            AUT99: (element.AUT99),
          });
          id++;
        });
        // 権限マスタリストを設定
        setRows(newRows);
      }
    })
  }, []);

  const customStyles = {
    content: {
      top: "20%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
    },
  };

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  
  const openModal = () => {
    setEditModalIsOpen(true);
  }
  const closeModal = () => {
    setEditModalIsOpen(false);
  }

  const getSL_AUT_MST = async () => {
    // バックエンドから取得
    const response = await fetch('/TCSH0000/getSL_AUT_MST',{method: 'POST',headers: {'Content-Type': 'application/json'},
    });
    return response.json();
  };

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };
  
  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    // console.log(key);
    //setAlert(key);
    if (inputRef.current.id==='PWD'){
      setTimeout(() => {
        console.log(inputRef.current.value);
        // 親要素を取得
        var parentElement = document.querySelector(".clsPassword");
        // 親要素内でクラスが "target-element" の要素を検索
        var targetElements = parentElement.querySelectorAll(".MuiInputBase-root");
        // targetElements にはマッチする要素が NodeList として格納されます
        // 例: 最初の要素にアクセスする場合
        var firstTargetElement = targetElements[0];
        // データ属性を設定
        firstTargetElement.setAttribute('data-val', '●'.repeat(inputRef.current.value.length));
      }, 10);
    }
    switch (key) {
      case "Enter":
        if (keyDisabled.current)
        {
          console.log('Enter2回押されました')
          return;
        }
        keyDisabled.current = true;

        if (inputRef.current.id==='BMNCDE')
        {
          const access_db = async () => {
            //Windows or Linux(PDA)
            var userAgent = window.navigator.userAgent;
            var linuxorwin = 0;
            if (userAgent.indexOf('Linux',1) !== -1)
            {
              linuxorwin = 1;
            }
            // console.log(userAgent);
            //console.dir(dbdataA, { depth: null });
            //従業員マスタ読み込み
            const responseA = await fetch('/TCSH0000/MR_WRK_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({JGNCDE: itID,PSW:itPWD,RCNT:0})});
            const dataA = await responseA.json();
            //console.log('検索結果A:'+dataA.KSACDE+'/'+dataA.JGNCDE);
            var SMISEFLG = 0;
            // console.log('検索結果A:%o',dataA);

            if (dataA.KSACDE!=='')
            {
              //setDataB({...dbdataB,KSACDE:dataA.KSACDE,JGNCDE:dataA.JGNCDE})
              //従業員マスタ店読み込み(設計書のB,C兼用)
              const responseB = await fetch('/TCSH0000/MR_WRKM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({KSACDE: dataA.KSACDE,JGNCDE:itID,PSW:itPWD})});
              const dataB = await responseB.json();
              if (dataB.RCNT > 1)
              {
                SMISEFLG = 1;
              }

              //要らないから後で削除する
              var K_flg = '';
              // console.log('検索結果B:%o',dataB);
              //センター管理マスタ読み込み
              const responseE = await fetch('/TCSH0000/SL_CTR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({KSACDE: dataA.KSACDE,MISE: dataB.res[0].MISE,MNU_KSACDE: dataA.KSACDE,MNU_MISE: dataB.res[0].MISE})});
              const dataE = await responseE.json();
              if (dataE.RCNT > 0)
              {
                K_flg = 1;
              }

              // console.log('検索結果E:%o',dataE);
              //店舗マスタ読み込み
              let f_misemei ='';
              let l_SMISEJH ='';
              for(const key in dataB.res){
                const responseF = await fetch('/TCSH0000/MR_STR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({KSACDE: dataA.KSACDE,MISE: dataB.res[key].MISE})});
                const dataF = await responseF.json();
                // console.log('検索結果F:%o',dataF);
                if(key==0)
                {
                  f_misemei = dataF.MISEMEI;
                  l_SMISEJH += dataB.res[key].MISE + ',' +dataF.MISEMEI;
                }else
                {
                  if (l_SMISEJH!=''){l_SMISEJH+=',';}
                  l_SMISEJH += dataB.res[key].MISE + ',' +dataF.MISEMEI;
                }
              }
              
              //部門名取得処理
              let Wk_brumei ='';//分類名ワーク
              let Wk_grpcde ='';//グループコードワーク
              let Wk_iryoflg ='';//衣料フラグワーク
              if(itBMNCDE === '3699'){
                Wk_brumei = '衣料';
                Wk_grpcde ='36';
                Wk_iryoflg ='1';
              }else if(itBMNCDE === '3599'){ 
                Wk_brumei = '住居';
                Wk_grpcde ='35';
                Wk_iryoflg ='0';
              //S SK-0033-001 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
              // }else if(itBMNCDE === '1289'){  // 1999⇒1289に変更（2024/04/15）
              //   Wk_brumei = '食品';
              //   Wk_grpcde ='1';//11と12が有る為、ここでは1のみ保存する
              //   Wk_iryoflg ='0';
              }else if(itBMNCDE === '1289'){
                Wk_brumei = '食品';
                Wk_grpcde ='12';  // 12 グロッサリー
                Wk_iryoflg ='0';
              }else if(itBMNCDE === '1999'){
                Wk_brumei = '食品事業部';
                Wk_grpcde ='1';//11と12が有る為、ここでは1のみ保存する
                Wk_iryoflg ='0';
              //E SK-0033-001
              }else{
                //それ以外はbackend検索
                const responseB2 = await fetch('/TCSH0000/getBrumei',{method: 'POST',headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({KSACDE: dataA.KSACDE, BMNCDE: itBMNCDE, RCNT:0})});
                const dataB2 = await responseB2.json();
                // console.log('検索結果B2:%o',dataB2);
                if(dataB2.RCNT===0)
                {
                  inBMNCDE.current.focus();
                  setAlert('部門コードが誤っているため、サインインできません。');
                  return
                }else
                {
                  Wk_brumei = dataB2.BRUMEI;
                  Wk_iryoflg =dataB2.IRYOFLG;
                  Wk_grpcde =dataB2.GRPCDE;
                }
              }
              // console.log('検索結果F 分類名:%o',Wk_brumei);
              // console.log('検索結果F 衣料Ｆ:%o',Wk_iryoflg);

              // region
              //ボタン権限作成
              // let lbutton = '';
              // const data = getSL_AUT_MST();
              // data.then(async result => {
              //   if (result != null) {
              //     console.log('画面表示権限マスタ取得');
              //     console.log(result);
              //     // rows作成
              //     let id = 0;
              //     let newRows = [];
              //     result.forEach(element => {
              //       newRows.push({ 
              //         id: id,
              //         REC_NO: element.REC_NO,
              //         GAMENNM: element.GAMENNM,
              //         AUT01: (element.AUT01),
              //         AUT02: (element.AUT02),
              //         AUT03: (element.AUT03),
              //         AUT04: (element.AUT04),
              //         AUT05: (element.AUT05),
              //         AUT06: (element.AUT06),
              //         AUT07: (element.AUT07),
              //         AUT08: (element.AUT08),
              //         AUT09: (element.AUT09),
              //         AUT99: (element.AUT99),
              //       });
              //       id++;
              //     });
              //     console.log('画面表示権限マスタ取得完了');
              //     console.log('newRows:%o',newRows);
              //     let strKENGEN = dataA.KENGEN.padStart(2,'0')
              //     let strAUT = 'AUT' + strKENGEN
              //     newRows.forEach(element => {
              //       if (lbutton === ''){
              //         // lbutton = element.REC_NO + ',' +  element[strAUT];
              //         lbutton = element[strAUT];
              //       }else{
              //         lbutton +=',' + element[strAUT];
              //       }
              //     });
              //     console.log('1_lbutton:%o',lbutton);
              //   }  
              // });
              //#endregion

              let lbutton = '';
              let strKENGEN = dataA.KENGEN.padStart(2,'0')
              let strAUT = 'AUT' + strKENGEN
              rows.forEach(element => {
                if (lbutton === ''){
                  lbutton = element.REC_NO + ',' +  element[strAUT];
                }else{
                  lbutton +=',' + element.REC_NO + ',' +  element[strAUT];
                }
              });

              // console.log('2_lbutton:%o',lbutton);
              // console.log('globe:'+dataA.JGNMEIKNJ);
              await dispatch({ type: 'Update' , GAMEN: linuxorwin,KSACDE: dataA.KSACDE, MISE: dataB.res[0].MISE,
                          KSACDES : dataA.KSACDE,MISES: dataB.res[0].MISE,K_KSACDES : dataE.ENT_K_KSACDE,K_MISES: dataE.ENT_K_MISE,
                          MISEMEI: f_misemei,JGNCDE: itID,JGNMEIKNJ: dataA.JGNMEIKNJ,KENGEN: dataA.KENGEN,
                          SMISEFLG: SMISEFLG,SMISEJH:l_SMISEJH,MVEMGAMEN:'',K_FLG:K_flg,SDAY:dataA.SDAY,BUTTON:lbutton,
                          BMNCDE:itBMNCDE,BRUMEI:Wk_brumei,GRPCDE:Wk_grpcde,IRYOFLG:Wk_iryoflg
                        });
              
              //S SK-0050-003 2024/7/24           TCI)M.K【要望：ログイン制御】
              console.log('定数読込み:%o',LOCAL_CONSTANT);
              //S SK-0058-001 2024/8/5           TCI)M.K【要望：ログイン制御 本社所属は対象外】
              // if (LOCAL_CONSTANT.EXCLUSION_CONTROL === 1){
              if (LOCAL_CONSTANT.EXCLUSION_CONTROL === 1 && state.MISE !== '200'){
              //E SK-0058-001
                // 排他制御ONの場合
                // (0)同時接続マスタチェック（同期処理）
                // (1)接続数チェック（同期処理）
                //  接続数がマスタ以上場合、エラー
                //  接続数がマスタより少ない場合、正常
                // (2)正常時、排他制御テーブルに情報を書き込む（レスポンスアップの為、非同期）
                let connection_cnt = LOCAL_CONSTANT.CONNECTION_CNT;
                const getHitMst = await fetch('/TCSH0000/get_HIT_MST',{method: 'POST',headers: {'Content-Type': 'application/json'},
                  body: JSON.stringify({
                    KSACDE:dataA.KSACDE, 
                    MISE:dataB.res[0].MISE, 
                    BMNCDE:itBMNCDE, 
                })});
                const HitMst = await getHitMst.json();
                console.log('HitMst:%o',HitMst);
        
                if(HitMst.ret.length !== 0){
                  connection_cnt = Number(HitMst.ret[0].CON_CNT)
                }

                let HitDat = undefined;
                let HitDat2 = undefined;
                if (dataB.res[0].MISE == '200' || dataB.res[0].MISE == '700' || K_flg == '1'){
                  // ここでは何もしない
                }else{
                  // FLG=1:ﾛｸﾞｲﾝ者以外でﾛｸﾞｲﾝしている数を検索
                  const getHitDat = await fetch('/TCSH0000/get_HIT_DAT',{method: 'POST',headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                      KSACDE:dataA.KSACDE, 
                      MISE:dataB.res[0].MISE, 
                      BMNCDE:itBMNCDE, 
                      JGNCDE:itID,
                      FLG:1,
                    })});
                  HitDat = await getHitDat.json();
                  console.log('HitDat:%o',HitDat);
                
                  if(HitDat.ret.length !== 0) {
                    if (connection_cnt <= HitDat.ret.length){
                      setAlert(itBMNCDE+'部門での同時ログインは'+connection_cnt+'名までです');
                      // setAlert(HitDat.ret[0].JGNMEIKNJ+' さんが '+itBMNCDE+' 部門でログイン中です');
                      return;
                    }
                  }

                  // FLG=2:ﾛｸﾞｲﾝ者が異なる部門でﾛｸﾞｲﾝしていないか検索
                  const getHitDat2 = await fetch('/TCSH0000/get_HIT_DAT',{method: 'POST',headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                      KSACDE:dataA.KSACDE, 
                      MISE:dataB.res[0].MISE, 
                      BMNCDE:itBMNCDE, 
                      JGNCDE:itID,
                      FLG:2,
                    })});
                    HitDat2 = await getHitDat2.json();
                  console.log('HitDat2:%o',HitDat2);

                  if(HitDat2.ret.length !== 0) {
                    setAlert(HitDat2.ret[0].JGNMEIKNJ+' さんが 別の端末でログイン中です ログイン部門：'+HitDat2.ret[0].BMNCDE);
                    return;
                  }

                  const repHitDat = fetch('/TCSH0000/Replace_HIT_DAT',{method: 'POST',headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                      KSACDE:dataA.KSACDE, 
                      MISE:dataB.res[0].MISE, 
                      BMNCDE:itBMNCDE, 
                      JGNCDE:itID, 
                      JGNMEIKNJ:dataA.JGNMEIKNJ, 
                      PRG_ID:'TCSH0000',
                      DELFLG:'0',
                    })});
                  console.log('repHitDat:%o',repHitDat);
                }
              }
              //E SK-0050-003

              // 従業員ログインデータ 新規登録
              console.log('従業員ログイン新規:%o',itID);
              const responseUA1 = await fetch('/TCSH0000/SH_LOG_DAT_INS',{method: 'POST',headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({
                ksacde : dataA.KSACDE       //会社コード
                ,mise :  dataB.res[0].MISE  //店コード
                // ,biko : itBMNCDE + ',ログインしました,' + window.innerWidth + '×' + window.innerHeight   //備考
                ,biko : window.innerWidth + '×' + window.innerHeight   //備考
                ,add_jgncde : itID          //ユーザーID
                ,add_prg_id : 'TCSH0000'    //プログラムID
                ,delflg :'0'                //削除フラグ
              })});
              const dataUA1 = await responseUA1.json();
              console.log('ログインデータ:%o',dataUA1.res);

              // 伝票ヘッダ 登録エラー
              if (dataUA1.res == -1) {
                setAlert('ログインデータ登録エラー');
                return
              }

              // if (dataB.res[0].MISE == '200' || dataE.ENT_KSACDE != '')
              // if (dataB.res[0].MISE == '200' || dataB.res[0].MISE == '700' || K_flg == '1')
              if (dataB.res[0].MISE == '200' || dataB.res[0].MISE == '700' || K_flg == '1')
              {
                  navigate('/TCSH0010');                
                
              }else
              {
                if (SMISEFLG == 1)
                {
                  // navigate('/TCSA0002');
                  navigate('/TCSH0010');
                }else{
                  if(Wk_iryoflg == 0)
                  {
                    navigate('/TCSH0020');
                  }else{
                    navigate('/TCSH0021');  
                  }
                }
              }
            }else
            {
              inputID.current.focus();
              setAlert('ユーザーIDもしくはパスワードが誤っているため、サインインできません。');
            }
          }
          await access_db();  
        }else{
          inputNxRef.current.focus();   //次の項目に遷移
        }

        setTimeout(() => {
          // 処理中フラグを下げる
          keyDisabled.current = false;
        }, 1000);
      
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      case "End":
        window.close();
        break;
      default:
        break;
    }
  };  


  return (
    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "470px",
          width: "280px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          {/* 画像の角丸対応 */}
          <Box sx={{  ...commonStyles, borderRadius: '5px',overflow: 'hidden' }}><img src={`${process.env.PUBLIC_URL}/OKUWA_Logo.svg`} alt="Logo" width="40" height="40" /></Box>
          {/* <Avatar sx={{ bgcolor: teal[400] }}>
          </Avatar> */}
          <Typography variant={"h5"} sx={{ m: "30px" }}>
            スキャン発注
          </Typography>
        </Grid>
        <p><font color="red">{ alert2 }</font></p>
        {/* <TextField 
          id={'UserID'}
          inputRef={inputID}              //項目名定義？
          color='success'
          autoFocus
          type='tel'
          inputMode='tel'
          label="User Id" 
          variant="standard" 
          fullWidth required
          value={itID}
          inputProps={{ pattern:"[0-9\\-]*" ,autoComplete: 'off',maxLength: 8,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onKeyDown={(e) => onKeyDown(e.key,inputID,inputID,inputPWD)}
          onChange={(event) => setItid(event.target.value)}
          //onChange={handleItem}
          // onChange={(event) => setDataA({...dbdataA,JGNCDE:event.target.value})} */}
          <NumericFormat  //user id
            id={'UserID'}
            inputRef={inputID}                   //項目名定義？
            color='success'
            autoFocus                           //初期カーソル位置設定
            thousandSeparator={false}           //1000単位の区切り           
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='User Id'                     //textboxの左上に表示するラベル
            variant='standard'                  //枠の表示方法
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            sx={{width: '100%'}}                 //textboxの幅
            value={itID}               //初期値
            allowLeadingZeros                   //前ゼロ表示 on
            type='tel'                          //[PDA、Tablet対応]入力モード：tel
            inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
            fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
            decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
            allowNegative={false}               //マイナス入力off
            disabled={false}                    //使用可／不可
            inputProps={{ pattern: "[0-9\\-]*",autoComplete: 'off',maxLength: 7,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inputID,inputID,inputPWD)}
            onChange={(event) => setItid(event.target.value)}
          />
          
        <br/>
        <TextField
          id={'PWD'}
          inputRef={inputPWD}              //項目名定義？
          color='success'
          type="text"
          label="Password"
          variant="standard"
          className='clsPassword'
          fullWidth
          value={itPWD}
          inputProps={{ autoComplete: 'off',maxLength: 6,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onKeyDown={(e) => onKeyDown(e.key,inputPWD,inputID,inBMNCDE)}
          //onChange={handleItem}
          //S SK-0076-002 2024/9/30           TCI)M.K【課題No.101：パスワード入力全角不可対応】
          // onChange={(event) => setItpwd(event.target.value)}
          onChange={(event) => onChangeFilterNumber(event, setItpwd)}
          //E SK-0076-002
          required
          />
          <br/>
          <NumericFormat  //部門
            id={'BMNCDE'}
            inputRef={inBMNCDE}                   //項目名定義？
            color='success'
            // autoFocus                           //初期カーソル位置設定
            fullWidth
            thousandSeparator={false}           //1000単位の区切り           
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='部門'                     //textboxの左上に表示するラベル
            variant='standard'                  //枠の表示方法
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            sx={{width: '100%'}}                 //textboxの幅
            value={itBMNCDE}               //初期値
            allowLeadingZeros                   //前ゼロ表示 on
            type='tel'                          //[PDA、Tablet対応]入力モード：tel
            inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
            fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
            decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
            allowNegative={false}               //マイナス入力off
            disabled={false}                    //使用可／不可
            inputProps={{ pattern: "[0-9\\-]*",autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inBMNCDE,inputPWD,inputID)}
            onChange={(event) => setBMNCDE(event.target.value)}
          />
        {/* ラベルとチェックボックス */}
        <Box mt={3}>
          <Button
            type="submit" 
            color="success" 
            variant="contained" 
            fullWidth
            onClick={(e) => onKeyDown('Enter',inBMNCDE,'','') }>
            サインイン
          </Button>
          
          {/* <Button
            type="submit" 
            color="success" 
            variant="contained" 
            fullWidth
            sx={{width:'5px',mt:'30px'}}
            onClick={(e) => onKeyDown('End',inputPWD,'','') }>
            
          </Button> */}

        </Box>
        <br />

        {/* <p>{ state.usrnm }</p> */}
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>  
  );
};

export default Login;